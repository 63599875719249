<template>
    <div id="popover-id-ef" tabindex="0" class="app-local-applicant-type" v-bind:class="{ 'app-cursor-pointer': !loading}" style="margin-top:2px; font-size:14px; font-weight:600;">
            <span v-bind:class="{ 
                                    'dot-silver' : this.profile.client_data.easyflex_status != null && this.profile.client_data.easyflex_status.status == 0,
                                    'dot-orange' : this.profile.client_data.easyflex_status != null && this.profile.client_data.easyflex_status.status == 1,
                                    'dot-green' : this.profile.client_data.easyflex_status != null && this.profile.client_data.easyflex_status.status == 2, 
                                    'dot-red' : this.profile.client_data.easyflex_status != null && this.profile.client_data.easyflex_status.status == 3 
                                }"> </span> EF
            <b-spinner v-if="loading" style="width:12px; height:12px; margin-bottom:3px; margin-left:3px;" small></b-spinner>       
            <b-popover v-if="!loading" target="popover-id-ef" triggers="focus" no-fade custom-class="app-local-popover">
                <template #title>
                    <!-- {{trans('rejected-by-client',180)}} -->
                    EasyFlex
                </template>
                
                <div style="min-width:280px;" class="mt-2" :class="{'mb-2': user_role > 1}">
                 
                    <b-row v-if="this.profile.client_data.easyflex_status.basic != null">
                        <b-col cols="7" class="mr-0 pr-0 d-flex"> <div :class="getDotClass(this.profile.client_data.easyflex_status.basic.status) + ' mt-1'"/> <div class="text-nowrap text-left"> Organisatiegegevens </div> </b-col>
                        <b-col cols="5" class="ml-0 ml-0 text-right font-weight-600"> {{getStatusName(this.profile.client_data.easyflex_status.basic.status)}} </b-col>
                    </b-row>

                    <div class="text-muted mt-2"  v-if="this.profile.client_data.easyflex_status.basic.status == 3"> {{ this.profile.client_data.easyflex_status.basic.error }} </div>
                                        
                    <div class="text-center" v-if="user_role == 1">
                        <hr class="app-search-modal-separator mt-3 mb-2" >
                        <app-button type="secondary-grey" @click="syncNow" :loading="loading"> Sync now </app-button>
                    </div>
                
                </div>
            
            </b-popover>
    </div>
</template>
<script>

import axios from "@axios";
export default {
    components: {
        
    },

    props:["profile"],

    data(){
        return{
            loading: false,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }, 
        }
    },

    methods:{
        getDotClass(status) {
            if (status == 0) return "dot-silver";
            if (status == 1) return "dot-orange";   
            if (status == 2) return "dot-green";
            if (status == 3) return "dot-red";
            return "";
        },

        getStatusName(status) {
            if (status == 0) return "Sync is off";
            if (status == 1) return "Updating";   
            if (status == 2) return "Updated";
            if (status == 3) return "Error";
            return "";
        },

         syncNow() {
            this.loading = true;
            axios
                .post("clients/profile/status/syncEasyFlex", {
                    id_client_data_main: this.profile.id
                })
                .then((res) => {
                    this.cProfile.client_data = res.data;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    }
};
</script>
