<template> 
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('cp-add-contact',192)">
        
        <ValidationObserver ref="form" >            

            <app-row-left-label :label="trans('first-name',182)">
                <app-input v-model="contact_data.first_name" validatorName="Voornaam" validatorRules="required|max:20" mask="lettersE1-20" />
            </app-row-left-label>

            <app-row-left-label :label="trans('last-name',182)" :slots="[4,8]">
                    <template v-slot:col-1>
                        <app-input v-model="contact_data.inbetween_name" :placeholder="trans('inbetween-name',182)" mask="lettersE1-10"/>
                    </template>
                     <template v-slot:col-2>
                        <app-input v-model="contact_data.last_name" :placeholder="trans('last-name',182)" validatorName="Achternaam" validatorRules="required" mask="lettersE1-30" />
                    </template>
            </app-row-left-label>
            
            <app-row-left-label :label="trans('function',3)">
                <app-input v-model="contact_data.function" validatorName="Functie" validatorRules="required|max:30" mask="lettersE1-30" />
            </app-row-left-label>

            <app-row-left-label :label="trans('phone-number',182)">
                    <app-phone :prefix.sync="contact_data.phone_prefix" :phone.sync="contact_data.phone_number" />
            </app-row-left-label>

            <app-row-left-label :label="trans('email',182)">
                <app-email :email.sync="contact_data.email" validatorName="Email" validatorRules="required|email" />
            </app-row-left-label>

            <app-row-left-label :label="trans('settings-roles',207)">
               <div class="d-flex justify-content-between">
                    <app-check-box v-model="contact_data.planning"  >{{trans('cp-planning',192)}} </app-check-box> 
                    <app-check-box v-model="contact_data.placement" > {{trans('sh-hours',215)}} </app-check-box>  
                    <app-check-box v-model="contact_data.hours" > {{trans('placment',5)}} </app-check-box>
                </div>
            </app-row-left-label>

        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="contactAdd" :loading="loading" type="primary"> 
                {{trans('add',175)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AppPhone from '@core/components/AppPhone.vue';
import AppEmail from '@core/components/AppEmail.vue';

export default {
    components: {
        ValidationObserver,
        AppPhone,
        AppEmail
    },

    props: ["show", "result", "args", "return"],

    data() {
        return {
            loading: false,
            contact_data:{
                id: null,
                function:"",
                first_name: "",
                inbetween_name:"",
                last_name: "",
                phone_prefix: '+31',
                phone_number: null,
                email: "",
                planning: 0,
                placement: 0,
                hours: 0
            },

            opt:[
                    { value: 1, text: this.trans('cp-planning',192) },
                    { value: 2, text: this.trans('sh-hours',215) },
                    { value: 3, text: this.trans('placment',5) },
                ]
                 ,
        };
    },

    created(){

    },

     computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        }

    },

    methods: {

        contactAdd() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{

                    this.loading = true;

                    axios
                        .post("clients/profile/contact/add", {
                            id_client_data_main: this.args.id_client_data_main,
                            contact_data: JSON.stringify(this.contact_data),
                            filters: JSON.stringify(this.args.filters),
                            return_type: this.args.return_type,
                            register_status: this.args.register_status,
                        })
                        .then((res) => {
                            if(this.args.return_type == 'manager'){
                                this.$emit('update:return', res.data.items);
                            }else if(this.args.return_type == 'structure') {
                                this.$emit('update:return', res.data.items);
                                this.cArgs.id_contact_data_main = res.data.id_contact_data_main;
                            }
                            this.$emit('update:result', 1);
                            this.$emit("update:show", false);
                           
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        });
                }
            });
                
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

