<template>
    <div>
        <b-modal ref="modal-change" size="xl" v-model="computedShow" no-close-on-backdrop :title="trans('cp-standard-deviating-functions', 192)">
            <b-card>
                <!--<b-overlay :show="loading_list" :opacity="0.0">-->

                    <app-view-table :isTitle="false" :loading="loading_list" :isTitleTotal="false" :filters.sync="filters" :items="items" :fields="fields" >
                        
                        <template v-slot:menu-items="cell">
                                <b-dropdown-item @click="editFunctions(cell.data.index)">{{trans('change',175)}}</b-dropdown-item>
                                <b-dropdown-item @click="deleteFunctions(cell.data.index)" >{{trans('remove',175)}}</b-dropdown-item>
                        </template>

                        <template v-slot:buttons>
                            <app-button type="primary" @click="addFunctionsStep1()">{{trans('add-function',182)}}</app-button>
                        </template>

                    </app-view-table>
                    
                    <!--<template #overlay>
                            <div></div>
                    </template>
                </b-overlay>-->
            </b-card>

            <clients-profile-organization-planning-functions-add-step1 v-if="mFunctionsAddStep1.show" :show.sync="mFunctionsAddStep1.show" :result.sync="mFunctionsAddStep1.result" :args.sync="mFunctionsAddStep1.args" :return.sync="mFunctionsAddStep1.return" />
            <clients-profile-organization-planning-functions-add-step2 v-if="mFunctionsAddStep2.show" :show.sync="mFunctionsAddStep2.show" :result.sync="mFunctionsAddStep2.result" :args.sync="mFunctionsAddStep2.args" :return.sync="items" />
            <clients-profile-organization-planning-functions-change v-if="mFunctionsChange.show" :show.sync="mFunctionsChange.show" :result.sync="mFunctionsChange.result" :args.sync="mFunctionsChange.args" :return.sync="items" />

            <template #modal-footer>
                <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import ClientsProfileOrganizationPlanningFunctionsAddStep1 from './ClientsProfileOrganizationPlanningFunctionsAddStep1.vue';
import ClientsProfileOrganizationPlanningFunctionsAddStep2 from './ClientsProfileOrganizationPlanningFunctionsAddStep2.vue';
import ClientsProfileOrganizationPlanningFunctionsChange from './ClientsProfileOrganizationPlanningFunctionsChange.vue';

export default {

    components: {
        ClientsProfileOrganizationPlanningFunctionsAddStep1,
        ClientsProfileOrganizationPlanningFunctionsAddStep2,
        ClientsProfileOrganizationPlanningFunctionsChange
    },
    props: ["show", "result", "args", "return"],

    mounted(){
        this.onCreateThis();
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
    },

    data(){
        return {
            alertClass: new AppAlerts(),
            loading_list: false,
            items: [],
            filters: {
                sorting: {
                        field_name: 'fa.name',
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {},
                page: 1,
                per_page: 10,
                search: "",
                changed: 0
            },

            mFunctionsAddStep1: {
                args: {
                    ids_function_data_main: null,
                }, result: 0, return: null, show: false
            },

            mFunctionsAddStep2: {
                args: {
                    id_client_data_main: null,
                    json_functions:null,
                    data: {id_function_data_main: null, ids_function_data_additional: []},
                    filters: null,
                }, result: 0, return: null, show: false
            },

            mFunctionsChange: {
                args: {
                    id_client_data_main: null,
                    index: null,
                    json_functions:null,
                    data: {id_function_data_main: null, ids_function_data_additional: []},
                    index: null,
                    filters: null,
                }, result: 0, return: null, show: false
            },

            fields: [
                { key: "main_function_name", label: this.trans('function',3), thStyle: { "width": "30%" },visible: true },
                { key: "additional_function_names", label: this.trans('deviant-funtions',182), thStyle: {  "width": "70%" },visible: true, filtering: false, sortable: false },
            ],
        }
    },

    methods: {
        
        getList(){
            this.loading_list = true;

            axios
                .post('clients/profile/functions/getList', {
                    id_client_data_main: this.args.profile.id,
                    filters: JSON.stringify(this.filters)
                })
                .then((res) => {
                    this.items = res.data.items;
                })
                .catch((error) => { 
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                })

        },

        addFunctionsStep1(){
            this.mFunctionsAddStep1.args.id_client_data_main = this.args.profile.id;
            this.mFunctionsAddStep1.return = 0;
            this.mFunctionsAddStep1.show = true;
        },

        addFunctionsStep2(val){
            this.mFunctionsAddStep2.args.data.id_function_data_main = val;
            this.mFunctionsAddStep2.args.data.ids_function_data_additional = [];
            this.mFunctionsAddStep2.args.id_client_data_main = this.args.profile.id
            this.mFunctionsAddStep2.args.json_functions = this.items.list.map(a => JSON.parse(a.json_functions))
            this.mFunctionsAddStep2.args.filters = this.filters;
            this.mFunctionsAddStep2.show = true;
        },

        editFunctions(index){
            var functions = this.items.list.map(a => JSON.parse(a.json_functions));

            this.mFunctionsChange.args.data.id_function_data_main = this.items.list[index].id_function_data_main;
            this.mFunctionsChange.args.data.ids_function_data_additional = functions[index].ids_function_data_additional;
            this.mFunctionsChange.args.id_client_data_main = this.args.profile.id;
            this.mFunctionsChange.args.index = index;
            this.mFunctionsChange.args.json_functions = this.items.list.map(a => JSON.parse(a.json_functions));
            this.mFunctionsChange.args.filters = this.filters;
            this.mFunctionsChange.show = true;
        },

        deleteFunctions(index){
            var additional_functions = this.items.list.map(a => JSON.parse(a.json_functions));
            var name = this.items.list[index].main_function_name;
            
            this.alertClass.openAlertConfirmation(this.trans('cp-remove-function-confirmation',191,{name:name})).then(res => {
                if(res == true){

                    additional_functions.splice(index,1);

                    this.loading_list = true;
                    axios
                        .post("clients/profile/functions/delete", {
                            id_client_data_main: this.args.profile.id,
                            filters: JSON.stringify(this.filters),
                            additional_functions: JSON.stringify(additional_functions),
                        })
                        .then((res) => {
                            this.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading_list = false;
                        });;
                }     
            }); 
        
        },

        closeModal() {
            this.$emit('onClose', 1);
            this.$emit("update:show", false);
        },

        onCreateThis(){

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            
            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('mFunctionsAddStep1.return', function(newVal, oldVal) {
                if (newVal > 0) {
                    this.addFunctionsStep2(newVal);
                }  
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

        }
    }
    
}
</script>
<style>
</style>