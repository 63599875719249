<template> 
    <b-modal ref="modal-add" size="lg" v-model="computedShow" no-close-on-backdrop :title="trans('cp-arbo-checklist-change',192)">
            <ValidationObserver ref="form" >
                <!-- check 1 -->
                <app-row-left-label :slots="[9, 3]" :isMarginBetween="false">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{trans('possession-of-valid-big-registration',195)}}</label>
                    </template>
                    <template v-slot:col-2>
                        <app-radio-group class="float-right" v-model="cArgs.question_1" :options="question_options" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                    </template>
                </app-row-left-label>

                <hr class="mt-1 mb-2" />
                  <!-- check 2 -->
                <app-row-left-label :isMarginBetween="true" class="mt-2">
                        <label class="app-input-top-label">{{trans('waadi-point-two-additional',195)}}</label>
                        <div class="ml-5 mt-2">
                            <app-check-box class="mb-2" v-model="cArgs.question_2.q1.value">{{trans('waadi-point-two-additional-a1',195)}} </app-check-box> 
                            <app-check-box class="mb-2" v-model="cArgs.question_2.q2.value">{{trans('waadi-point-two-additional-a2',195)}} </app-check-box> 
                            <app-check-box class="mb-2" v-model="cArgs.question_2.q3.value">{{trans('waadi-point-two-additional-a3',195)}} </app-check-box>
                            <app-check-box class="mb-2" v-model="cArgs.question_2.q4.value">{{trans('waadi-point-two-additional-a4',195)}} </app-check-box>
                            <app-row-left-label :slots="[3, 9]">
                                <template v-slot:col-1>
                                    <app-check-box  class="mb-2" v-model="cArgs.question_2.q5.value">{{trans('waadi-point-two-additional-a5',195)}}  </app-check-box> 
                                </template>
                                <template v-slot:col-2>
                                    <app-memo rows="3" class="mt-1 mb-2" v-if="cArgs.question_2.q5.value == 1" v-model="cArgs.question_2.q5.text"/>
                                </template>
                            </app-row-left-label> 
                        </div>
                        <small class="ml-5">{{trans('waadi-point-two-additional-a6',195)}} </small> 
                        <app-memo rows="3" class="ml-5" v-model="cArgs.question_2.q6.text"/>
                </app-row-left-label>

                <hr class="mt-1 mb-2" />
                
                 <!-- check 3 -->
                <app-row-left-label :isMarginBetween="true" class="mt-2">
                        <label class="app-input-top-label">{{trans('waadi-point-3',195)}}</label>
                        <div class="ml-5 mt-2">
                            <app-row-left-label :slots="[5, 7]" :isMarginBetween="true">
                                <template v-slot:col-1>
                                    <app-check-box v-model="cArgs.question_3.q1.value">{{trans('waadi-point-3-gloves',195)}} </app-check-box> 
                                </template>
                                <template v-slot:col-2 >
                                    <div class="d-flex flex-row" v-if="cArgs.question_3.q1.value == 1">
                                        <span class="app-input-top-label">{{trans('waadi-point-3-client',195)}}</span>
                                        <app-radio-group v-model="cArgs.question_3.q1.option" class="ml-4" :options="question_options" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                                    </div>
                                </template>
                            </app-row-left-label>

                            <app-row-left-label :slots="[5, 7]"  :isMarginBetween="true">
                                <template v-slot:col-1>
                                    <app-check-box v-model="cArgs.question_3.q2.value">{{trans('waadi-point-3-mask',195)}} </app-check-box>
                                </template>
                                <template v-slot:col-2>
                                    <div class="d-flex flex-row" v-if="cArgs.question_3.q2.value == 1">
                                        <span class="app-input-top-label">{{trans('waadi-point-3-client',195)}}</span>
                                        <app-radio-group v-model="cArgs.question_3.q2.option" class="ml-4" :options="question_options" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                                    </div>
                                </template>
                            </app-row-left-label>

                            <app-row-left-label :slots="[5, 7]" :isMarginBetween="true">
                                <template v-slot:col-1>
                                    <app-check-box v-model="cArgs.question_3.q3.value">{{trans('waadi-point-3-shoes',195)}} </app-check-box> 
                                </template>
                                <template v-slot:col-2>
                                     <div class="d-flex flex-row" v-if="cArgs.question_3.q3.value == 1">
                                        <span class="app-input-top-label">{{trans('waadi-point-3-client',195)}}</span>
                                        <app-radio-group v-model="cArgs.question_3.q3.option" class="ml-4" :options="question_options" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                                    </div>
                                </template>
                            </app-row-left-label>

                            <app-row-left-label :slots="[5, 7]" :isMarginBetween="true">
                                <template v-slot:col-1>
                                    <app-check-box v-model="cArgs.question_3.q4.value">{{trans('waadi-point-3-protect',195)}} </app-check-box> 
                                </template>
                                <template v-slot:col-2>
                                     <div class="d-flex flex-row" v-if="cArgs.question_3.q4.value == 1">
                                        <span class="app-input-top-label">{{trans('waadi-point-3-client',195)}}</span>
                                        <app-radio-group v-model="cArgs.question_3.q4.option" class="ml-4" :options="question_options" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                                    </div>
                                </template>
                            </app-row-left-label>

                            <app-row-left-label :slots="[5, 7]" :isMarginBetween="true">
                                <template v-slot:col-1>
                                    <app-check-box v-model="cArgs.question_3.q5.value">{{trans('waadi-point-3-other',195)}} </app-check-box> 
                                </template>
                                <template v-slot:col-2>
                                    <div class="d-flex flex-row" v-if="cArgs.question_3.q5.value == 1">
                                        <span class="app-input-top-label">{{trans('waadi-point-3-client',195)}}</span>
                                        <app-radio-group v-model="cArgs.question_3.q5.option" class="ml-4" :options="question_options" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                                    </div>
                                </template>
                            </app-row-left-label>

                            <app-memo rows="3"  v-if="cArgs.question_3.q5.value == 1" v-model="cArgs.question_3.q5.text" />

                            <small class="text-muted"> {{trans('waadi-point-3-latex',195)}} </small>
                        </div>
                </app-row-left-label>
                <!-- check 4 -->
                <app-row-left-label :slots="[9, 3]" :isMarginBetween="false" class="mt-2">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{trans('exposed-to-biological-agents-info',191)}}</label>
                    </template>
                    <template v-slot:col-2>
                        <app-radio-group class="float-right" v-model="cArgs.question_4" :options="question_options" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                    </template>
                </app-row-left-label>

                <hr class="mt-1 mb-2" />

                <!--check 5 -->
                <app-row-left-label :slots="[9, 3]" :isMarginBetween="false" class="mt-2">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{trans('vaccination-against-hepatitis-b-info',191)}}</label>
                    </template>
                    <template v-slot:col-2>
                        <app-radio-group class="float-right" v-model="cArgs.question_5" :options="question_options" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                    </template>
                </app-row-left-label>

                <hr class="mt-1 mb-2" />

                <!-- check 6 -->
                <app-row-left-label :slots="[9, 3]" :isMarginBetween="false" class="mt-2">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{trans('required-vaccination-from-client',191)}}</label>
                    </template>
                     <template v-slot:col-2>
                        <app-radio-group class="float-right" v-model="cArgs.question_6" :options="question_options" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                    </template>
                </app-row-left-label>

                <hr class="mt-1 mb-2" />

                <!-- check 7 -->
                <app-row-left-label :slots="[9, 3]" :isMarginBetween="false" class="mt-2">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{trans('exposed-to-cytostatics-or-anesthetic-gases',191)}}</label>
                    </template>
                    <template v-slot:col-2>
                        <app-radio-group class="float-right" v-model="cArgs.question_7" :options="question_options"  validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                    </template>
                </app-row-left-label>

                <hr class="mt-1 mb-2" />

                 <!-- check 8 -->
                <app-row-left-label :slots="[9, 3]" :isMarginBetween="true" class="mt-2">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{trans('waadi-point-5-additional',195)}}</label>
                    </template>
                    <template v-slot:col-2>
                        <app-radio-group class="float-right" v-model="cArgs.question_8" :options="question_options"  validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                    </template>
                </app-row-left-label>

                <hr class="mt-1 mb-2" />

                <!-- check 9 -->
                <app-row-left-label :slots="[9, 3]" :isMarginBetween="false" class="mt-2">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{trans('deal-with-moments',191)}}</label>
                    </template>
                    <template v-slot:col-2>
                        <app-radio-group class="float-right" v-model="cArgs.question_9" :options="question_options" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                    </template>
                </app-row-left-label>

                <hr class="mt-1 mb-2" />

                <!-- check 10 -->
                <app-row-left-label :slots="[9, 3]" :isMarginBetween="false" class="mt-2">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{trans('other-risks-that-havent-mentioned',191)}}</label>
                    </template>
                    <template v-slot:col-2>
                        <app-radio-group class="float-right" v-model="cArgs.question_10" :options="question_options" validatorRules="required" :validatorCustomMessage="{ required: trans('field-required-error',180) }" />
                    </template>
                </app-row-left-label>

                <!-- TextArea for check 10 -->
                <app-row-left-label :slots="[3, 9]" :isMarginBetween="false" class="mt-2" v-if="cArgs.question_10 == 1">
                    <template v-slot:col-1>
                        <label class="app-input-top-label">{{trans('description-of-risks',190)}}</label>
                    </template>
                    <template v-slot:col-2>
                        <app-memo id="textarea-default" rows="3" v-model="cArgs.question_11"/>  
                    </template>
                </app-row-left-label>

                <app-divider-modal :text="trans('cp-filler-data',192)" :isLine="true"/>

                <app-row-left-label :label="trans('cp-completed-by-and-function',190)" :slots="[6,6]">
                    <template v-slot:col-1>
                        <app-input v-model="cArgs.completed" mask="lettersE4-30" validatorRules="required" validatorName="Formulier ingevuld" :placeholder="trans('cp-filled-out-form',190)" />
                    </template>
                    <template v-slot:col-2>
                        <app-input v-model="cArgs.functions" mask="lettersE4-30" validatorRules="required" validatorName="Functie" :placeholder="trans('function',3)" />
                    </template>
                </app-row-left-label>
            </ValidationObserver>

            <template #modal-footer>
                <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
                <app-button type="primary" @click="checkListsChange" :loading="loading">
                    {{trans('save',175)}}
                </app-button>
            </template>
        
    </b-modal>
    
    
</template>

<script>
import axios from "@axios";
import {  ValidationObserver } from "vee-validate";

export default {

    components:{
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }, 
        },
        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }, 
        }
    },

    data() {
        return {
            loading: false,
            question_options: [
                { value: 1, text: this.trans('yes-option',3) },
                { value: 0, text: this.trans('no-option',3) },
            ],

        };
    },

    methods: {

        checkListsChange() {
            this.$refs.form.validate().then((result) => {

                if(!result){
                    return false;
                } else {

            this.loading = true;
                axios
                    .post("clients/profile/checklist/change", {
                        id_client_data_main: this.args.idClientDataMain,
                        client_person_data: JSON.stringify(this.args),
                    })
                    .then((res) => {
                        this.$emit("update:show", false);
                        this.$emit("update:result", 1);
                        this.cReturn.client_data = res.data;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    });
                }
            });         
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    }
}
</script>
