<template>
    <div>
        <validation-provider v-slot="{ errors}" :rules="validatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
            <label v-if="label" class="app-input-top-label">{{label}}</label>
            <br v-if="additionalInfo" /><small v-if="additionalInfo" class="text-muted">{{additionalInfo}}</small>

            <div>
                <div v-for="item, i  in selected_items" :key="i" class="mb-1">
                    <b-input-group  class="custom-input form-control  form-control-sm p-0 app_value_per_year" :class="{'app_value_per_year-filled': item.value != null && item.value != '', 'app_value_per_year-error': errors.length > 0 && errors[0] != '' && i == 0, 'app_value_per_year-disabled-group': disabled}">
                                
                                <b-input-group-prepend class="app-custom-input-group input-group-merge pl-0 form-control-sm custom-border test" :class="{'app_value_per_year-disabled' : disabled}"  is-text style="height: 29.5px">
                                    <app-year :key="refresh" v-model="item.year" class="app-yer-custom-control b-0 app-datepicker-per-year" :customClass="'app_value_per_year-calendar app_value_per_year-calendar-icon' + (errors.length > 0 && errors[0] != '' && i == 0 ? ' app_value_per_year-error-background' : '') + (disabled ? ' app_value_per_year-disabled' : '')"/>
                                </b-input-group-prepend >


                                <app-input :key="refresh" :disabled="disabled" v-model="item.value" type="decimal_07_places_2" :customInputClass="'form-control form-control-sm border-0 app_value_per_year-input-height' + (errors.length > 0 && errors[0] != '' && i == 0 ? ' app_value_per_year-error-background' : '') + (disabled ? ' app_value_per_year-disabled' : '')" class="custom-app-input-box p-0 m-0"/>

                                <b-input-group-append class="d-flex custom-append-group justify-content-end" >
                                    <div class="input-group-text custom-group-text d-flex justify-content-end" :class="{'app_value_per_year-error-background': errors.length > 0 && errors[0] != '' && i == 0, 'app_value_per_year-disabled': disabled}">
                                        <div v-if="errors.length > 0 && errors[0] != '' && i == 0" class="custom-icon-error mr-2">
                                            <b-icon icon="exclamation-circle-fill"></b-icon>
                                        </div>
                                        <div v-if="i != 0" class="custom-icon">
                                        <b-icon icon="arrow-up-short" class="text-muted custom-icon" lg  @click="moveUp(item)"   font-scale="1" :style="errors.length > 0 && errors[0] != '' && i == 0 ? 'color: #d92d20 !important' : 'color: #066791 !important'"></b-icon>
                                        </div>
                                        <div v-if="i != selected_items.length -1" class="custom-icon">
                                         <b-icon icon="arrow-down-short" class="text-muted custom-icon" lg   @click="moveDown(item)" font-scale="1" :style="errors.length > 0 && errors[0] != '' && i == 0 ? 'color: #d92d20 !important' : 'color: #066791 !important'"></b-icon>
                                        </div>
                                        <div v-if="selected_items.length != 1" class="custom-icon">
                                          <b-icon icon="x" class="text-muted custom-icon" lg @click="remove(item)"   font-scale="1" :style="errors.length > 0 && errors[0] != '' && i == 0 ? 'color: #d92d20 !important' : 'color: #066791 !important'"></b-icon>
                                        </div>
                                    </div>
                                </b-input-group-append>
                    </b-input-group>
                </div>
            </div>

            <div class='d-flex justify-content-end' >
                <app-button :disabled="disabled" type="cancel" @click="add" class="p-0 app-local-text" :class="{'app-local-text-disabled': disabled}"><b-icon icon="plus-circle" class="mr-1" /> {{trans('add-record',175)}}</app-button>
            </div>
            <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1">{{ errors[0] }}</small> 
        </validation-provider>
    </div>
</template>
<script>

import AppYear from "./AppYear.vue"

export default {

    name: "AppValuePerYear",

    props: {
        value: { type: Array },
        type: { type: String },
        args: { type: Object },
        refreshIndex: { type: Number },
        multiple: { type: Boolean },
        disabled: { type: Boolean },
        clearable: { type: Boolean },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        label: {type: String},
        additionalInfo: {type: String},
    },

    created(){
        
        if(this.value != null && this.value.length > 0){
            this.selected_items = this.value;
        }
        this.cValue = this.selected_items;
    },

    components: {
        AppYear
    },

    computed: {
        cValue:{
            get() { return this.modelValue },
            set(val) { 
                    val = val.filter(x=>x.year != null && x.year != '' && x.value != null && x.value != '')
                    this.$emit("input", val) 
                }
        },
    },

    watch: {
      "selected_items": {
            handler: function(val) {
               this.cValue = this.selected_items ;        
            },
            deep: true,
        }
    },


    data(){
        return {
            loading: false,
            items: [],
            options: [],
            selected_items: [{year: null, value: null}],
            refresh:0,
        }
    },

    methods: {
        add(){
            this.selected_items.push({year: null, value: ''});
        },

        remove(item){
            var index = this.selected_items.indexOf(item);
            this.selected_items.splice(index, 1);
            this.refresh++;
        },

        moveUp(item){
            var index = this.selected_items.indexOf(item);
            [this.selected_items[index], this.selected_items[index -1]] = [this.selected_items[index-1], this.selected_items[index]];
            this.refresh++;
            this.cValue = this.selected_items ; 
             
        },

        moveDown(item){
            var index = this.selected_items.indexOf(item);
            [this.selected_items[index], this.selected_items[index +1]] = [this.selected_items[index+1], this.selected_items[index]];

             this.refresh++;
             this.cValue = this.selected_items ; 
        }
    }
    
}
</script>
<style scoped>
    .input-group-prepend .input-group-text {
        padding: 0px !important;
        border: 0px;
        border-radius: 0px;
    }

    .app-yer-custom-control{
        width: 100px;
        
    }

    .custom-input{
        flex-wrap: nowrap !important;
        min-width: 230px
    }

    .custom-append-group{
        margin-left: auto;
        padding-right: 6px;
    }
    .custom-icon{
        height: 18px;
        width: 18px;
        cursor: pointer;
    }

    .custom-icon-error{
        height: 18px;
        width: 18px;
        color: #066791;
    }

    .custom-icon :hover{
        color: #066791 !important;
    }

    @media (max-width: 560px){

        .app-yer-custom-control{
            width: 70px;
        
        }
    }

    .custom-group-text{
        padding: 2px !important;
        border: 0px;
        height: 26px;
    }

    .custom-border{
       border-right: 1px solid #dee2e6 !important;
       margin-right: 1px;
       border-radius: 0px;
       
    }
    .custom-app-input-box{
        height: 29px;
        width: 100%;
    }

    .app-local-text{
        color: #7f7f7f !important;
    }

    .app-local-text:hover{
        color: black !important;
        opacity: 0.8;
    }


    .app-local-text-disabled{
        pointer-events: none !important;
    }

    .app-local-text-disabled:hover{
        cursor: default !important;
    }
</style>