<template>
    <div>
        <b-row v-if="profile.client_data.id_status_const_type > 3 && (user_role < 3 || profile.can_user_edit)" class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right>
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>          
                <b-dropdown-item @click="changePlanning()">{{trans('cp-change-of-schedule',189)}}</b-dropdown-item>
                <b-dropdown-item @click="changeFunctions()">{{trans('cp-change-additional-functions', 189)}} </b-dropdown-item>
                <b-dropdown-item @click="changeFunctionsAliases()">{{trans('cp-change-functions-aliases', 189)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header mb-4">
            <b-col>
                <p class="col-list-label">{{trans('offer-via',182)}}</p>
                <p class="col-list-label">{{trans('registraion',5)}}</p>
                <p class="col-list-label">{{trans('client-confirmed-fw',182)}}</p>
                <p class="col-list-label">{{trans('send-offer-email',182)}}</p>
                <p class="col-list-label">{{trans('cp-entry-procedure',190)}}</p>
            </b-col>
           
            <b-col>
                <p class="col-form-value">{{planning_parameters.registration_tool == null ? '-' : planning_parameters.registration_tool == 1 ? trans('cp-customer-planning-system',192) : trans('cp-mail-hn-portal',192)}}</p>
                <p class="col-form-value">{{planning_parameters.registration_process == null ? '-' : planning_parameters.registration_process == 1? trans('collect-data',3) : trans('putting-it-through-directly',190)}}</p>
                <p class="col-form-value">{{planning_parameters.client_confirmation == null ? '-' : planning_parameters.client_confirmation == 1 ? trans('yes-option',3) : trans('no-option',3)}}</p>
                <p class="col-form-value">{{planning_parameters.send_notification == null ? '-' : planning_parameters.send_notification == 0 ? trans('directly',3) : planning_parameters.send_notification == 1 ? trans('put-in-queue',180) : trans('no-option',3)}}</p>
                <p class="col-form-value">{{planning_parameters.import_procedure == null ? '-' : planning_parameters.import_procedure == 0 ? trans('cp-normal',192) : trans('cp-individual',192)}}</p>
            </b-col>
        </b-row>

        <hr class="app-table-hr-separator">


        <b-row class="app-card-header mb-4 mt-4">
            <b-col cols="4">
                <p class="col-form-label-ellipsis"><b>{{trans('function',3)}}</b></p>
                <p class="col-form-label-ellipsis" v-for="item in JSON.parse(profile.client_data.planning_functions)" :key="item.function_name">
                    {{item.function_name}}
                 </p>   
            </b-col >
           
            <b-col cols="8">
                <p class="col-form-value-ellipsis"><b>{{trans('cp-standard-deviating-functions',192)}}</b></p>
                <p class="col-form-value-ellipsis" v-for="item in JSON.parse(profile.client_data.planning_functions)" :key="item.function_name">
                    {{item.function_additional_name == null ? '-' : item.function_additional_name}}
                 </p> 
            </b-col>
        </b-row>

        <hr class="app-table-hr-separator">

        
        <b-row class="app-card-header mb-4 mt-4">
            <b-col cols="4">
                <p class="col-form-label-ellipsis"><b>{{trans('function',3)}}</b></p>
                <p class="col-form-label-ellipsis" v-for="item in JSON.parse(profile.client_data.functions_aliases)" :key="item.name">
                    {{item.name}}
                 </p>   
            </b-col >
           
            <b-col cols="8">
                <p class="col-form-value-ellipsis"><b>{{trans('sh-function-aliases',219)}}</b></p>
                <p class="col-form-value-ellipsis" v-for="item in JSON.parse(profile.client_data.functions_aliases)" :key="item.name_alias">
                    {{item.name_alias == null ? '-' : item.name_alias}}
                 </p> 
            </b-col>
        </b-row>

        <clients-profile-organization-planning-functions-aliases-component @onClose="changeFunctionClose" v-if="mChangeFunctionsAliases.show" :show.sync="mChangeFunctionsAliases.show" :args.sync="mChangeFunctionsAliases.args" :result.sync="mChangeFunctionsAliases.result" :return.sync="profile" />
        <clients-profile-organization-planning-change v-if="mChange.show" :show.sync="mChange.show" :args.sync="mChange.args" :result.sync="mChange.result" :return.sync="profile" />
        <clients-profile-organization-planning-functions-component @onClose="changeFunctionClose" v-if="mChangeFunctions.show" :show.sync="mChangeFunctions.show" :args.sync="mChangeFunctions.args" :result.sync="mChangeFunctions.result" :return.sync="profile" />
    </div>
</template>
<script>

import ClientsProfileOrganizationPlanningChange from './ClientsProfileOrganizationPlanningChange.vue';
import ClientsProfileOrganizationPlanningFunctionsComponent from './Functions/ClientsProfileOrganizationPlanningFunctionsComponent.vue';
import ClientsProfileOrganizationPlanningFunctionsAliasesComponent from './FunctionsAliases/ClientsProfileOrganizationPlanningFunctionsAliasesComponent.vue';

export default {
    components: {
        ClientsProfileOrganizationPlanningChange,
        ClientsProfileOrganizationPlanningFunctionsComponent,
        ClientsProfileOrganizationPlanningFunctionsAliasesComponent
    },

    props:["profile"],

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) {this.$emit('update:profile', value) }
        }

    },

    created(){
        this.user_role = JSON.parse(localStorage.getItem('user')).id_user_const_role;
        this.parseData();
    },
    
    watch: {
        "mChange.result": {
            handler: function(val){
                if(val > 0){
                    this.parseData();
                    this.mChange.result = 0;
                }
            }
        },

        "mChangeFunctions.show": {
            handler: function(val){
                if(this.mChangeFunctions.show == false){
                    this.changeFunctionClose(1);
                }
            },
            deep:true
        },

        "mChangeFunctionsAliases.show": {
            handler: function(val){
                if(this.mChangeFunctionsAliases.show == false){
                    this.changeFunctionClose(1);
                }
            },
            deep:true
        }
    },

    data(){
        return{
                user_role:null,
                planning_parameters: null,
                mChange: { show: false, args: {
                                                id_client_data_main: null,
                                                registration_process: null,
                                                registration_tool: null,
                                                client_confirmation: null,
                                                send_notification: null,
                                                import_procedure: null,
                                               }, 
                            result: 0, return: null },

                mChangeFunctions: {show: false, args: {}, result: 0, return: null},
                mChangeFunctionsAliases: {show: false, args: {
                    profile: null,
                }, result: 0, return: null}
        }
    },


    methods:{
        parseData(){
            this.planning_parameters = JSON.parse(this.profile.client_data.planning_parameters);
        },
        
        changePlanning(){
            this.mChange.args.id_client_data_main = this.profile.id;
            this.mChange.args.registration_process = this.planning_parameters.registration_process;
            this.mChange.args.registration_tool = this.planning_parameters.registration_tool;
            this.mChange.args.client_confirmation = this.planning_parameters.client_confirmation;
            this.mChange.args.send_notification = this.planning_parameters.send_notification;
            this.mChange.args.import_procedure = this.planning_parameters.import_procedure;
            this.mChange.show = true;

        },

        changeFunctions(){
            this.mChangeFunctions.args.profile = this.profile;
            this.mChangeFunctions.show = true;
        },

        changeFunctionsAliases(){
            this.mChangeFunctionsAliases.args.profile = this.profile;
            this.mChangeFunctionsAliases.show = true;
        },

        changeFunctionClose(event){
            if(event == 1){
                this.cProfile.client_data_refresh ++;
            }
        }
            
    }
    
 
};
</script>
