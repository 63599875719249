<template>
    <b-modal ref="modal-chaneg" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('cp-change-sales-data',192)">

        <app-row-left-label :label="trans('cp-pnil-budget',192)">
            <app-value-per-year v-model="cArgs.budget_pnil" />
        </app-row-left-label>

        <app-row-left-label :label="trans('cp-winst-kans',192)">
            <app-input v-model="cArgs.chance_winning" type="integer_max_100"/>
        </app-row-left-label>

        <app-row-left-label :label="trans('cp-label',192)" :slots="[4,4,4]" :customError="true">
            <template v-slot:col-1>
                <app-check-box v-model="cArgs.label_zzp" :rightLabel="trans('cp-zzp',190)"/>
            </template>

            <template v-slot:col-2>
                    <app-check-box v-model="cArgs.label_uzk" :rightLabel="trans('cp-uzk',190)" />
            </template>

            <template v-slot:col-3>
                    <app-check-box v-model="cArgs.label_hms" rightLabel="HMS" />
            </template>

        </app-row-left-label>

        <app-row-left-label :label="trans('cp-contract-expiration-date',192)">
            <app-date v-model="cArgs.end_contract_date" :clearable="true"/>
        </app-row-left-label>

        <app-row-left-label :label="trans('cp-collaboration-form',192)">
            <app-select v-model="cArgs.form_cooperation" reduceValue :options="form_cooperation_options" :clearable="true" />
        </app-row-left-label>

        <app-row-left-label :label="trans('cp-number-of-suppliers',192)">
            <app-input v-model="cArgs.suppliers_number" type="integer_2"/>
        </app-row-left-label>

        <app-row-left-label :label="trans('cp-competitors',192)">
            <app-memo v-model="cArgs.competitors" :maxLength="500" :lettersCounter="true" />
        </app-row-left-label>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>
<script>
import axios from "@axios";
import AppValuePerYear  from '@core/components/AppValuePerYear.vue'

export default {

    props: ["show", "args", "result", "return"],

    components: {
        AppValuePerYear
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }, 
        },
    },
    
    created(){
        
        if(this.cArgs.budget_pnil == null || this.cArgs.budget_pnil == ''){
            this.cArgs.budget_pnil = [];
        }
    },

    data(){
        return {
            loading: false,
            form_cooperation_options: [
                { value: 0, name: this.trans('cp-master-vendor',192) },
                { value: 1, name: this.trans('cp-first-supplier',192) },
                { value: 2, name: this.trans('cp-preferred-supplier',192) },
                { value: 3, name: this.trans('cp-suppiers',192) }
            ],
            label_options: [
                { value: 0, text: this.trans('cp-zzp',190) },
                { value: 1, text: this.trans('cp-uzk',190) },
                { value: 2, text: this.trans('cp-hms',190) }
            ]
        }
    },

    methods: {

        change(){
            this.loading = true;
            if(this.cArgs.budget_pnil == null){
                this.cArgs.budget_pnil = '';
            }

            var label = {
                ZZP: this.cArgs.label_zzp,
                UZK: this.cArgs.label_uzk,
                HMS: this.cArgs.label_hms
            };
            var budget_pnil = JSON.parse(JSON.stringify(this.cArgs.budget_pnil));
            if(budget_pnil != null && budget_pnil != ''){
                budget_pnil = budget_pnil.map((val) => {
                                                    var decimal = val.value.split(',');
                                                    if(decimal.length == 1){
                                                        val.value += ",00";
                                                    }else if(decimal[1].length == 0){
                                                        val.value += "00";
                                                    }else if(decimal[1].length == 1){
                                                        val.value += "0";
                                                    }
                                                    return val; 
                                                })
            }
            axios
                .post('clients/profile/sales/change', {
                    id_client_data_main: this.args.id_client_data_main,
                    budget_pnil: JSON.stringify(budget_pnil),
                    chance_winning: this.cArgs.chance_winning,
                    label: JSON.stringify(label),
                    end_contract_date: this.cArgs.end_contract_date,
                    form_cooperation: this.cArgs.form_cooperation,
                    suppliers_number: this.cArgs.suppliers_number,
                    competitors: this.cArgs.competitors
                })
                .then((res) =>{
                    this.cReturn.client_data = res.data;
                    this.$emit('update:result', 1);
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                    this.computedShow = false;
                })
        },

        closeModal() {
            this.computedShow = false;
        },
    }
    
}
</script>
<style>

</style>