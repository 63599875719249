<template>
    <div>
        <b-row v-if="profile.client_data.id_status_const_type > 3 && (userRole < 3 || profile.can_user_edit)" class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right>
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>                                             
                <b-dropdown-item @click="change()">{{trans('cp-change-financial-data',192)}}</b-dropdown-item>   
                <b-dropdown-item @click="changeCAO()" v-if="this.userRole == 1 || this.userRole == 2">{{trans('settings-change-cao',167)}}</b-dropdown-item>
                <b-dropdown-item @click="changeIndexation()" v-if="this.userRole == 1 || this.userRole == 2">{{trans('cp-change-indexation-information',192)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header">
                <b-col>
                     <div class="col-list-label mt-2"><b>{{trans('cp-finance',192)}}</b></div>
                </b-col>
        </b-row>

        <b-row class="app-card-header">
            <b-col start>
                <p class="col-list-label">{{trans('debtor-number',192)}}</p>
                <p class="col-list-label">{{trans('cp-payment-term',192)}}</p>
                <p class="col-list-label">{{trans('cp-invoice-email-address',192)}}</p>
                <p class="col-list-label">
                    {{trans('invoice-frequency',182)}} 
                    <b-icon icon="info-circle-fill" id="popover-frequency-component" class="ml-2 app-cursor-pointer" style="width: 15px; height: 15px;"/>
                </p>
                <p class="col-list-label">{{trans('cp-cost-center',192)}}</p>
                <p class="col-list-label">{{trans('cp-invoince-per',192)}}</p>
                <p class="col-list-label mb-3">{{trans('hours-on-invoice',168)}}</p>
                <p class="col-list-label">{{trans('client-g-account',168)}}</p>
                <p class="col-list-label mb-3">{{trans('cp-about-invoice-amount',192)}}</p>
                <p class="col-list-label">{{trans('cp-invoice-attention',192)}}</p>
                <p class="col-list-label">{{trans('cp-invoice-shipping-method',192)}}</p>
                <p class="col-list-label mb-3">{{trans('cp-additional-invoice-listing',192)}}</p>

                <p class="col-list-label">{{trans('cp-invoice-organization-address',192)}}</p>

                <p class="col-list-label" v-if="profile.client_data.invoice_organization_address == 1">{{trans('street',182)}}</p>
                <p class="col-list-label" v-if="profile.client_data.invoice_organization_address == 1">{{trans('house-number',182)}}</p>
                <p class="col-list-label" v-if="profile.client_data.invoice_organization_address == 1">{{trans('cw-house-number-addition',194)}}</p>
                <p class="col-list-label" v-if="profile.client_data.invoice_organization_address == 1">{{trans('postcode',182)}}</p>
                <p class="col-list-label " v-if="profile.client_data.invoice_organization_address == 1">{{trans('town',192)}}</p>
                <p class="col-list-label" v-if="profile.client_data.invoice_organization_address == 2">{{trans('cp-post-office-box', 192)}}</p>
                <p class="col-list-label" v-if="profile.client_data.invoice_organization_address == 2">{{trans('postbus-postcode', 192)}}</p>
                <p class="col-list-label mb-3" v-if="profile.client_data.invoice_organization_address == 2">{{ trans('postbus-city', 192) }}</p>
            </b-col>
            <b-col>
                <p class="col-form-value">{{profile.client_data.debtor_number ? profile.client_data.debtor_number : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.settlement_period ? profile.client_data.settlement_period + " " + trans('cp-days',192) : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.invoice_email ? profile.client_data.invoice_email : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.frequency_name ? profile.client_data.frequency_name : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.costplace_number ? profile.client_data.costplace_number : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.cost_place_name || profile.client_data.flexworker_name || profile.client_data.week_name 
                     ? profile.client_data.cost_place_name + ' ' + profile.client_data.flexworker_name + ' '+ profile.client_data.week_name : '-' }}</p>
                <p class="col-form-value mb-3">{{profile.client_data.hours_on_invoice != null ? invoice_hours_options[profile.client_data.hours_on_invoice] : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.g_account ? profile.client_data.g_account + '%' : '-'}}</p>
                <p class="col-form-value mb-3">{{profile.client_data.over_btw != null ? over_btw_options[profile.client_data.over_btw] : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.invoice_attention ? profile.client_data.invoice_attention : '-'}}</p>
                <p class="col-form-value">
                    <span v-if="profile.client_data.invoice_shipping_pdf == '1' || profile.client_data.invoice_shipping_xml == '1'">
                        <span v-if="profile.client_data.invoice_shipping_pdf == '1'">PDF</span>
                        <span v-if="profile.client_data.invoice_shipping_pdf == '1' && profile.client_data.invoice_shipping_xml == '1' ">, </span>
                        <span v-if="profile.client_data.invoice_shipping_xml == '1'">XML</span>
                    </span>
                    <span v-else>-</span>
                </p>
                <p class="col-form-value mb-3">{{profile.client_data.invoice_mention ? profile.client_data.invoice_mention : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.invoice_organization_address == 0 ? trans('yes-option', 3) : trans('no-option', 3)}}</p>
                <p class="col-form-value" v-if="profile.client_data.invoice_organization_address == 1">{{profile.client_data.invoice_street  ? profile.client_data.invoice_street : '-'}}</p>
                <p class="col-form-value" v-if="profile.client_data.invoice_organization_address == 1">{{profile.client_data.invoice_house_number ? profile.client_data.invoice_house_number : '-'}}</p>
                <p class="col-form-value" v-if="profile.client_data.invoice_organization_address == 1">{{profile.client_data.invoice_number_additional ? profile.client_data.invoice_number_additional : '-'}}</p>
                <p class="col-form-value" v-if="profile.client_data.invoice_organization_address == 1">{{profile.client_data.invoice_post_code ? profile.client_data.invoice_post_code : '-'}}</p>
                <p class="col-form-value" v-if="profile.client_data.invoice_organization_address == 1">{{profile.client_data.invoice_city ? profile.client_data.invoice_city : '-'}}</p>
                <p class="col-form-value" v-if="profile.client_data.invoice_organization_address == 2">{{profile.client_data.post_office_box ? profile.client_data.post_office_box : '-'}}</p>
                <p class="col-form-value" v-if="profile.client_data.invoice_organization_address == 2">{{profile.client_data.post_office_box_postcode ? profile.client_data.post_office_box_postcode : '-'}}</p>
                <p class="col-form-value mb-3" v-if="profile.client_data.invoice_organization_address == 2">{{profile.client_data.post_office_box_city ? profile.client_data.post_office_box_city : '-'}}</p>
            </b-col>
        </b-row>

        <b-row class="app-card-header mt-2">
                <b-col>
                     <div class="col-list-label"><b>{{trans('cao',182)}}</b></div>
                </b-col>
        </b-row>

        <b-row class="app-card-header">
            <b-col start>
                <p class="col-list-label mb-3" >{{trans('cao',182)}}</p>
             </b-col>
              <b-col>
                <p class="col-form-value mb-3">{{profile.client_data.section_name  ? profile.client_data.section_name : '-'}}</p>
              </b-col>
        </b-row>

        <b-row class="app-card-header mt-2">
                <b-col>
                     <div class="col-list-label"><b>{{trans('cp-indexing-information',192)}}</b></div>
                </b-col>
        </b-row>

        <b-row class="app-card-header">
            <b-col start>
                <p class="col-list-label" >{{trans('cp-indexing',192)}}</p>
                <p class="col-list-label mb-3" >{{trans('cp-first-indexation-per',192)}}</p>
             </b-col>
              <b-col>
                <p class="col-form-value">{{profile.client_data.indexation  ? profile.client_data.indexation_name : '-'}}</p>
                <p class="col-form-value mb-3">{{profile.client_data.indexation_date  ? profile.client_data.indexation_date : '-'}}</p>
              </b-col>
        </b-row>
          
        <b-popover target="popover-frequency-component" triggers="hover focus" no-fade>               
            <template #title>
                
            </template>
            <p class="app-popover-text">{{ trans('invoice-frequency-alert',191) }}</p>
        </b-popover>

        <clients-profile-organization-financials-change v-if="mFinancialsChange.show" :show.sync="mFinancialsChange.show" :result.sync="mFinancialsChange.result" :args.sync="mFinancialsChange.args" :return.sync="profile"/> 
        <clients-profile-cao-change :show.sync="mCaoChange.show" :args="mCaoChange.args" :result="mCaoChange.result" :return.sync="profile.client_data" />
        <clients-profile-organization-financials-indexation-change v-if="mIndexationChange.show" :show.sync="mIndexationChange.show" :result.sync="mIndexationChange.result" :args.sync="mIndexationChange.args" :return.sync="profile"/> 
    </div>
</template>
<script>

import ClientsProfileOrganizationFinancialsChange from "./ClientsProfileOrganizationFinancialsChange.vue";
import ClientsProfileCaoChange from '@app/Clients/Profile/CAO/ClientsProfileCaoChange.vue';
import ClientsProfileOrganizationFinancialsIndexationChange from "./ClientsProfileOrganizationFinancialsIndexationChange.vue";

export default {
    components: {
        ClientsProfileOrganizationFinancialsChange,
        ClientsProfileCaoChange,
        ClientsProfileOrganizationFinancialsIndexationChange
    },

    props:["profile"],

    created(){
        this.userRole = JSON.parse(localStorage.getItem('user')).id_user_const_role;
    },

    data(){
        return{
                userRole:null,
                invoice_hours_options: [this.trans('day-part-day',182), this.trans('declaration',3), this.trans('week',3)],
                over_btw_options: ['Incl BTW', 'Excl BTW'],
                mFinancialsChange: { 
                                        show: false, args: {
                                                            settlement_period: null,
                                                            invoice_email: null,
                                                            frequency: null,
                                                            cost_centre: null,
                                                            costplace: null,
                                                            flexworker: null,
                                                            week: null,
                                                            expected_flex_workers: null,
                                                            id_client_data_main:null,
                                                            costplace_number:null,
                                                            id_status_const_type: null,
                                                            hours_on_invoice: null,
                                                            g_account: null,
                                                            over_btw: null,
                                                            invoice_attention: null,
                                                            invoice_shipping_pdf: null,
                                                            invoice_shipping_xml: null,
                                                            invoice_mention: null,
                                                            id_client_type: null,
                                                            invoice_organization_address: null,
                                                            invoice_address: {
                                                                city: null,
                                                                street: null,
                                                                house_number: null,
                                                                house_number_additional: null,
                                                                post_code: null,
                                                            },
                                                            client_address: {
                                                                city: null,
                                                                street: null,
                                                                house_number: null,
                                                                house_number_additional: null,
                                                                post_code: null,
                                                                country: {value: 149}
                                                            },
                                                            post_office_box: null,
                                                            post_office_box_postcode: null,
                                                            post_office_box_city: null,
                                                        }, 
                                        result: 0, return: null },                         
            mCaoChange: { show: false, 
                            args: { 
                                profile_id: null,
                                list_type:null,
                                section: null,
            }, result: 0, return: null },
            mIndexationChange: { show: false, 
                            args: { 
                                id_client_data_main: null,
                                indexation: null,
                                indexation_date: null,
            }, result: 0, return: null },
        }
    },


    methods:{       
        change(){
            this.mFinancialsChange.args.id_client_data_main = this.profile.id;
            this.mFinancialsChange.args.settlement_period = this.profile.client_data.settlement_period;
            this.mFinancialsChange.args.invoice_email = this.profile.client_data.invoice_email;
            this.mFinancialsChange.args.frequency = this.profile.client_data.frequency_name == null ? null : {name: this.profile.client_data.frequency_name, value: this.profile.client_data.id_frequency_const_type}
            this.mFinancialsChange.args.costplace_number = this.profile.client_data.costplace_number;
            this.mFinancialsChange.args.costplace = this.profile.client_data.cost_place ? this.profile.client_data.cost_place.toString(): '0';
            this.mFinancialsChange.args.flexworker = this.profile.client_data.flexworker;
            this.mFinancialsChange.args.week =this.profile.client_data.week?this.profile.client_data.week.toString():'0';
            this.mFinancialsChange.args.expected_flex_workers = this.profile.client_data.expected_flex_workers? this.profile.client_data.expected_flex_workers.toString(): '';
            this.mFinancialsChange.args.id_status_const_type = this.profile.client_data.id_status_const_type;
            this.mFinancialsChange.args.hours_on_invoice = this.profile.client_data.hours_on_invoice;
            this.mFinancialsChange.args.g_account = this.profile.client_data.g_account;
            this.mFinancialsChange.args.over_btw = this.profile.client_data.over_btw;
            this.mFinancialsChange.args.invoice_attention = this.profile.client_data.invoice_attention;
            this.mFinancialsChange.args.invoice_mention = this.profile.client_data.invoice_mention;
            this.mFinancialsChange.args.invoice_shipping_pdf = this.profile.client_data.invoice_shipping_pdf;
            this.mFinancialsChange.args.id_client_type = this.profile.client_data.id_client_type;
            this.mFinancialsChange.args.invoice_shipping_xml = this.profile.client_data.invoice_shipping_xml;
            this.mFinancialsChange.args.invoice_organization_address = this.profile.client_data.invoice_organization_address;
            this.mFinancialsChange.args.invoice_address.city = this.profile.client_data.invoice_city ? this.profile.client_data.invoice_city : '';
            this.mFinancialsChange.args.invoice_address.street = this.profile.client_data.invoice_street ? this.profile.client_data.invoice_street : '';
            this.mFinancialsChange.args.invoice_address.house_number = this.profile.client_data.invoice_house_number ? this.profile.client_data.invoice_house_number : '';
            this.mFinancialsChange.args.invoice_address.house_number_additional = this.profile.client_data.invoice_number_additional ? this.profile.client_data.invoice_number_additional : '';
            this.mFinancialsChange.args.invoice_address.post_code = this.profile.client_data.invoice_post_code ? this.profile.client_data.invoice_post_code : '';
            this.mFinancialsChange.args.post_office_box = this.profile.client_data.post_office_box;
            this.mFinancialsChange.args.post_office_box_postcode = this.profile.client_data.post_office_box_postcode;
            this.mFinancialsChange.args.post_office_box_city = this.profile.client_data.post_office_box_city;

            this.mFinancialsChange.args.client_address.street = this.profile.client_data.street;
            this.mFinancialsChange.args.client_address.house_number = this.profile.client_data.house_number;
            this.mFinancialsChange.args.client_address.house_number_additional = this.profile.client_data.house_number_additional ? this.profile.client_data.house_number_additional : '';
            this.mFinancialsChange.args.client_address.city = this.profile.client_data.city;
            this.mFinancialsChange.args.client_address.post_code = this.profile.client_data.post_code;

            this.mFinancialsChange.show = true;
        },
        
        changeCAO(){
            this.mCaoChange.args.profile_id = this.profile.id;
            this.mCaoChange.args.section = this.profile.client_data.section_name != null ? {name: this.profile.client_data.section_name, value: this.profile.client_data.id_cao_data_main} : null;
            this.mCaoChange.args.list_type= this.profile.type;
            this.mCaoChange.show = true;
        },

        changeIndexation(){
            this.mIndexationChange.args.id_client_data_main = this.profile.id;
            this.mIndexationChange.args.indexation = this.profile.client_data.indexation ? {value: this.profile.client_data.indexation ,name: this.profile.client_data.indexation_name} : null;
            this.mIndexationChange.args.indexation_date = this.profile.client_data.indexation_date;
            this.mIndexationChange.show = true;
        },
       
    }
    
 
};
</script>
