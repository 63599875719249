<template>
    <div>
        <b-row class="align-items-center ml-1 mr-1">
            <b-col>
                <div style="font-size: 24px;">{{this.profile.client_data.client_name}}</div>
                <div style="font-size: 16px; ">{{this.trans('id-number',3)}} {{this.profile.id}}</div>
                
            </b-col>

            <b-col style="max-width:200px">
                <div v-if="profile.client_data.register_status == 0 || profile.client_data.register_status == 1" style="font-size: 14px;  font-weight: 600; text-align:right">
                    <span v-bind:class="{ dotActive: this.profile.client_data.deleted == 0, dotInactive: this.profile.client_data.deleted == 1 }"> </span> {{this.profile.client_data.deleted == 0 ? this.trans('active-status',3) : this.trans('inactive',3)}} <br>
                    <span class="font-weight-300" >{{this.profile.client_data.status_name}}</span>
                    <b-icon @click="user_role == 1 ? changeStatus(): null" v-if="user_role == 1" icon="pencil-fill" class="text-body list-button-icon mt-1 ml-2 align-top app-local-pointer"></b-icon>
                </div>
                <div v-if="profile.client_data.register_status == 2" style="font-size: 14px;  font-weight: 600; text-align:right">
                    <span v-bind:class="{ dotActive: this.profile.client_data.deleted == 0, dotInactive: this.profile.client_data.deleted == 1 }"> </span> {{this.profile.client_data.deleted == 0 ? this.trans('active-status',3) : this.trans('inactive',3)}}
                    <b-icon @click="user_role == 1 ? changeStatus(): null" icon="pencil-fill" class="text-body list-button-icon mt-1 ml-2 align-top app-local-pointer" v-if="user_role == 1"></b-icon> 
                </div>
            </b-col>

            <b-col cols="4">
                <clients-profile-organization-status-bar-easy-flex v-if="this.profile.client_data.register_status == 2" :profile.sync="profile"/>
            </b-col>
                    
            
        </b-row>
        <clients-profile-organization-status-bar-status-change v-if="mStatusChange.show" :show.sync="mStatusChange.show" :args="mStatusChange.args" :result="mStatusChange.result" :return.sync="profile" />
    </div>
</template>
<script>

import ClientsProfileOrganizationStatusBarStatusChange from './ClientsProfileOrganizationStatusBarStatusChange.vue';
import ClientsProfileOrganizationStatusBarEasyFlex from './ClientsProfileOrganizationStatusBarEasyFlex.vue';

export default {

    props:["profile"],

    components: {
        ClientsProfileOrganizationStatusBarStatusChange,
        ClientsProfileOrganizationStatusBarEasyFlex
    },

    data(){
        return{
            loadingSkeleton: false,
            mStatusChange: {
                args: {
                    id_status_const_type: null,
                    id_client_data_main: null,
                }, result: 0, return: null, show: false
            },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods: {  
        changeStatus(){
            this.mStatusChange.args.id_status_const_type = this.profile.client_data.id_status_const_type;
            this.mStatusChange.args.id_client_data_main = this.profile.client_data.id;
            this.mStatusChange.show = true;
        }
    }

}
</script>

<style scoped>

.app-local-pointer{
    cursor:pointer;
}

.dotActive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #20dc37;
  border-radius: 50%;
  display: inline-block;
}
.dotInactive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
</style>
