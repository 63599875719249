<template>
    <div class="mx-4 my-4">
        <b-row class="px-0" >
            <b-col cols="8">
                <h4 class="mt-2">{{trans('cp-indexing', 192)}}</h4>
            </b-col>
            <b-col cols="4" end>
                <app-select v-model="cItemData.name" :options="itemData.parameters" :disable="itemData.parameters.length == 1" class="mt-1  app-width-min-220 app-width-max-220 float-right" end/>
            </b-col>
        </b-row>

        <b-row class="px-0 mt-3" v-if="visible_data.placement_recalculation == 0">
            <b-col cols="12">
                <b-alert variant="warning" show class="mb-0 ">
                    <div class="d-flex w-100 justify-content-between">
                        <div class="d-flex align-items-center">
                            <div>
                                <b-icon icon="file-earmark-check-fill" font-scale="3"></b-icon>                      
                            </div>

                            <div class=" ml-3 w-100 align-items-center">           
                                <span class="align-middle font-weight-600 text-base">{{trans('action',3)}} </span>  <br/>  
                                <span class="text-small">{{trans('cp-recalculate-placement',194)}}</span>                                   
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <app-button type="primary" @click="recalculatePlacement()" >{{trans('cp-recalculate',194)}}</app-button>      
                            <app-button type="primary" @click="deleteIndexation()" >{{trans('remove',175)}}</app-button>                              
                        </div>
                    </div>
                </b-alert>
            </b-col>
        </b-row>

       <div v-if="itemData.contract_type == 2"> 
            <hr class="app-table-hr-separator" >

            <h4 class="mb-3">{{trans('cp-prices',190)}}</h4>
            <b-row>
                <b-col start cols="6" md="8">
                    <p class="col-list-label">{{trans('cp-broadcast-agency-rate',190)}}</p>
                    <p class="col-list-label">{{trans('cp-mediation-rate-for-posting',190)}}</p>
                </b-col>
                <b-col start cols="6" md="4">
                    <p class="col-form-value">{{ visible_data.zzp_parameters.send_rate == null ? '-' : visible_data.zzp_parameters.send_rate.replace('.',',') + "€"}}</p>
                    <p class="col-form-value">{{ visible_data.zzp_parameters.detachment_rate == null ? '-' : visible_data.zzp_parameters.detachment_rate.replace('.',',') + "€"}}</p>
                </b-col>
            </b-row>

            <hr class="app-table-hr-separator">

            <b-row v-for="(el, index) in cRowCount" :key="el">

                <b-col cols="12">
                    <b-row>
                        <b-col cols="3">
                        </b-col>
                        <b-col cols="4" align="center" v-if="cKeys[(index * 2)] != undefined">
                            <label class="flabel">{{getFunctionName(cKeys[(index * 2)])}}</label>
                        </b-col>
                        <b-col cols="4" align="center" v-if="cKeys[(index * 2) + 1] != undefined">
                            <label class="flabel">{{getFunctionName(cKeys[(index * 2) + 1])}}</label>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col cols="3">
                    <p class="col-list-label" v-for="item in cShifts" :key="item.type">
                        {{item.display_name}}
                    </p>
                </b-col>

                <b-col cols="4" align="center">
                    <p class="col-form-value" v-for="item_rate in visible_data.zzp_parameters.rates[cKeys[(index * 2)]]" :key="item_rate.id_type">
                        {{item_rate.from != null && item_rate.to && item_rate.from.length > 0 && item_rate.to.length > 0 != null  ? item_rate.from + "€ - " + item_rate.to + "€" : item_rate.from != null && item_rate.from.length > 0 ? item_rate.from + "€" : item_rate.to != null && item_rate.length > 0 ? item_rate.to + "€" : "-" }}
                    </p>
                </b-col>

                <b-col cols="4" align="center" v-if="cKeys[(index * 2) + 1] != undefined">
                    <p class="col-form-value" v-for="item_rate in visible_data.zzp_parameters.rates[cKeys[(index * 2) + 1]]" :key="item_rate.id_type">
                        {{item_rate.from != null && item_rate.to && item_rate.from.length > 0 && item_rate.to.length > 0 != null  ? item_rate.from + "€ - " + item_rate.to + "€" : item_rate.from != null && item_rate.from.length > 0 ? item_rate.from + "€" : item_rate.to != null && item_rate.length > 0 ? item_rate.to + "€" : "-" }}
                    </p>
                </b-col>
                
                <b-col cols="12" v-if="cKeys[(index * 2) + 2] != undefined">
                    <hr class="app-table-hr-separator" v-if="cKeys[(index * 2) + 2] != undefined">
                </b-col>
                
            </b-row>

            <hr class="app-table-hr-separator">
            <h4 class="mb-3">{{trans('travel-costs',192)}}</h4>
            <b-row v-if="visible_data.km_allow == 0">
                <b-col start cols="6" md="8">
                    <p class="col-list-label">{{trans('cp-no-mileage-allowances-allowed',192)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value"></p>
                </b-col>
            </b-row>
            <b-row v-if="visible_data.km_allow == 1">
                <b-col start cols="6" md="8">
                    <p class="col-list-label">{{trans('cw-home-work',194)}}</p>
                    <p class="col-list-label">{{trans('cw-work-work',194)}}</p>
                    <p class="col-list-label">{{trans('max-number-of-km-per-shift',192)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value">{{visible_data.cost_house_work == null ? '-' : (visible_data.cost_house_work + "€").replace('.',',')}}</p>
                    <p class="col-form-value">{{visible_data.cost_work_work == null ? '-' : (visible_data.cost_work_work + "€").replace('.',',')}}</p>
                    <p class="col-form-value">{{visible_data.max_km_each_way == null ? '-' : visible_data.max_km_each_way + "km"}}</p>
                </b-col>
            </b-row>
        </div> 

        <div v-if="itemData.contract_type == 1">

            <hr class="app-table-hr-separator">

            <h4 class="mb-3">{{trans('cp-rates-and-contract-duration',192)}}</h4>
            <b-row>
                <b-col start cols="6" md="8">
                    <p class="col-list-label">{{trans('loan-volume',192)}}</p>
                    <p class="col-list-label">{{trans('cao',182)}}</p>
                    <p class="col-list-label">{{trans('duration-year',192)}}</p>
                    <p class="col-list-label">{{visible_data.uzk_parameters.calculation_type == 1 ? trans('cp-rate',192) : trans('cp-conversion-factor',192)}} {{trans('cw-per-function',193)}}</p>
                    <p class="col-list-label">{{visible_data.uzk_parameters.calculation_type == 1 ? trans('cp-rate',192) : trans('cp-conversion-factor',192)}} {{trans('cw-per-phase',193)}}</p>
                    <p class="col-list-label">{{visible_data.uzk_parameters.calculation_type == 1 ? trans('cp-diffrent-rate-for-deta',192) : trans('cp-diffrent-conversion-factor-for-deta',192)}}</p>
                    <p class="col-list-label" v-if="visible_data.uzk_parameters.diff_overhour != null">{{trans('cp-separate-ort-conversion-factor',192)}}</p>
                    <p class="col-list-label" v-if="visible_data.uzk_parameters.diff_overhour != null && visible_data.uzk_parameters.diff_overhour == 1">{{trans('prospect-rw-apply-ort',193)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value">{{visible_data.uzk_parameters.loan_value == null ? '-' : visible_data.uzk_parameters.loan_value}}</p>
                    <p class="col-form-value">{{itemData.function_name == null ? '-' : itemData.function_name}}</p>
                    <p class="col-form-value">{{visible_data.uzk_parameters.duration_year == null ? '-' : visible_data.uzk_parameters.duration_year}}</p>
                    <p class="col-form-value">{{visible_data.uzk_parameters.diff_function == 1 ? this.trans('yes-option',3) : this.trans('no-option',3)}}</p>
                     <p class="col-form-value">{{visible_data.uzk_parameters.diff_phase == 1 ? this.trans('yes-option',3) : this.trans('no-option',3)}}</p>
                    <p class="col-form-value">{{visible_data.uzk_parameters.diff_assigment == 1 ? this.trans('yes-option',3) : this.trans('no-option',3)}}</p>
                    <p class="col-form-value"  v-if="visible_data.uzk_parameters.diff_overhour != null">{{visible_data.uzk_parameters.diff_overhour == 1 ? this.trans('yes-option',3) : this.trans('no-option',3)}}</p>
                    <p class="col-form-value"  v-if="visible_data.uzk_parameters.diff_overhour != null && visible_data.uzk_parameters.diff_overhour == 1">{{visible_data.uzk_parameters.conversion_factor == 1 ? this.trans('cp-ort-part',192) : this.trans('cp-full-hourly-wage',192)}}</p>
                </b-col>
            </b-row>

            <hr class="app-table-hr-separator" v-if="visible_data.uzk_parameters.functions != null">

            <b-row v-if="visible_data.uzk_parameters.functions != null">
                <b-col cols="3">
                </b-col>

                <b-col cols="4" align="center">
                    <label class="flabel">{{trans('broadcast',192)}}</label>
                </b-col>
                <b-col cols="4" align="center">
                    <label class="flabel" v-if="visible_data.uzk_parameters.diff_assigment == 1">{{trans('detachment',182)}}</label>
                </b-col>
                 
            </b-row>

            <b-row v-if="visible_data.uzk_parameters.functions != null">
                <b-col cols="3">
                    <label class="flabel ">{{trans('function',3)}}</label>
                </b-col>
                <b-col  cols="4" align="center">
                    <div  v-if="visible_data.uzk_parameters.diff_phase == 1">
                        <app-row-left-label v-if="visible_data.uzk_parameters.diff_overhour == 1" :slots="[3, 3, 3, 3]">
                            <template v-slot:col-1>
                                <label class="flabel ">{{trans('cw-phase-a',193)}}</label>
                            </template>
                            <template v-slot:col-2>
                               <label class="flabel ">{{trans('cw-phase-b',193)}}</label>
                            </template>

                            <template v-slot:col-3>
                                <label class="flabel ">{{trans('cw-phase-c',193)}}</label>
                            </template>

                            <template v-slot:col-4>
                                <label class="flabel ">{{trans('cw-ort',194)}}</label>
                            </template>
                        </app-row-left-label>

                        <app-row-left-label v-else :slots="[4, 4, 4]">
                            <template v-slot:col-1>
                               <label class="flabel ">{{trans('cw-phase-a',193)}}</label>
                            </template>
                            <template v-slot:col-2>
                                <label class="flabel ">{{trans('cw-phase-b',193)}}</label>
                            </template>

                            <template v-slot:col-3>
                               <label class="flabel ">{{trans('cw-phase-c',193)}}</label>
                            </template>
                        </app-row-left-label>
                    </div>

                    <div v-else>
                        <app-row-left-label v-if="visible_data.uzk_parameters.diff_overhour == 1" :slots="[6,6]">
                        
                        <template v-slot:col-1>
                               <label class="flabel ">{{trans('cw-all-phases',193)}}</label>
                            </template>
                            <template v-slot:col-2>
                                <label class="flabel ">{{trans('cw-ort',194)}}</label>
                            </template>

                        </app-row-left-label>

                        <app-row-left-label v-else :slots="[12]">
                                <label class="flabel ">{{trans('cw-all-phases',193)}}</label>
                        </app-row-left-label>
                    </div>
                </b-col>


                <b-col cols="4" align="center" v-if="visible_data.uzk_parameters.diff_assigment == 1">
                    <div  v-if="visible_data.uzk_parameters.diff_phase == 1">
                        <app-row-left-label v-if="visible_data.uzk_parameters.diff_overhour == 1" :slots="[3, 3, 3, 3]">
                            <template v-slot:col-1>
                                <label class="flabel ">{{trans('cw-phase-a',193)}}</label>
                            </template>
                            <template v-slot:col-2>
                                <label class="flabel ">{{trans('cw-phase-b',193)}}</label>
                            </template>

                            <template v-slot:col-3>
                               <label class="flabel ">{{trans('cw-phase-c',193)}}</label>
                            </template>

                            <template v-slot:col-4>
                                <label class="flabel ">{{trans('cw-ort',194)}}</label>
                            </template>
                        </app-row-left-label>

                        <app-row-left-label v-else :slots="[4, 4, 4]">
                            <template v-slot:col-1>
                                <label class="flabel ">{{trans('cw-phase-a',193)}}</label>
                            </template>
                            <template v-slot:col-2>
                                <label class="flabel ">{{trans('cw-phase-b',193)}}</label>
                            </template>

                            <template v-slot:col-3>
                               <label class="flabel ">{{trans('cw-phase-c',193)}}</label>
                            </template>
                        </app-row-left-label>
                    </div>

                    <div v-else>
                        
                         <app-row-left-label v-if="visible_data.uzk_parameters.diff_overhour == 1" :slots="[6,6]">
                        
                        <template v-slot:col-1>
                                <label class="flabel ">{{trans('cw-all-phases',193)}}</label>
                            </template>
                            <template v-slot:col-2>
                                <label class="flabel ">{{trans('cw-ort',194)}}</label>
                            </template>

                        </app-row-left-label>

                        <app-row-left-label v-else :slots="[12]">
                                <label class="flabel ">{{trans('cw-all-phases',193)}}</label>
                        </app-row-left-label>
                    </div>
                </b-col>
            </b-row>

            <b-row v-for="item in visible_data.uzk_parameters.functions" :key="item.value">
                <b-col cols="3">
                    <label class="col-form-value ">{{item.function_name}}</label>
                </b-col>
                
                <b-col  cols="4" align="center">
                    <div  v-if="visible_data.uzk_parameters.diff_phase == 1">
                        <app-row-left-label v-if="visible_data.uzk_parameters.diff_overhour == 1" :slots="[3, 3, 3, 3]">
                        
                        <template v-slot:col-1>
                                <p class="col-form-value">{{item.p_a == null || item.p_a.length == 0 ? '-' : item.p_a}}</p>
                            </template>
                            <template v-slot:col-2>
                                <p class="col-form-value">{{item.p_b == null || item.p_b.length == 0 ? '-' : item.p_b}}</p>
                            </template>

                            <template v-slot:col-3>
                                <p class="col-form-value">{{item.p_c== null || item.p_c.length == 0 ? '-' : item.p_c}}</p>
                            </template>

                            <template v-slot:col-4>
                                <p class="col-form-value">{{item.ort== null || item.ort.length == 0 ? '-' : item.ort}}</p>
                            </template>
                        </app-row-left-label>

                        <app-row-left-label v-else :slots="[4, 4, 4]">
                            <template v-slot:col-1>
                                <p class="col-form-value">{{item.p_a == null || item.p_a.length == 0 ? '-' : item.p_a}}</p>
                            </template>
                            <template v-slot:col-2>
                                <p class="col-form-value">{{item.p_b == null || item.p_b.length == 0 ? '-' : item.p_b}}</p>
                            </template>

                            <template v-slot:col-3>
                                <p class="col-form-value">{{item.p_c== null || item.p_c.length == 0 ? '-' : item.p_c}}</p>
                            </template>
                        </app-row-left-label>
                    </div>

                    <div v-else>
                         <app-row-left-label v-if="visible_data.uzk_parameters.diff_overhour == 1" :slots="[6,6]">
                        
                        <template v-slot:col-1>
                                <p class="col-form-value">{{item.p_all == null || item.p_all.length == 0 ? '-' : item.p_all}}</p>
                            </template>
                            <template v-slot:col-2>
                                <p class="col-form-value">{{item.ort == null || item.ort.length == 0 ? '-' : item.ort}}</p>
                            </template>

                        </app-row-left-label>

                        <app-row-left-label v-else :slots="[12]">
                                <p class="col-form-value">{{item.p_all == null || item.p_all.length == 0 ? '-' : item.p_all}}</p>
                        </app-row-left-label>
                    </div>
                </b-col>


                <b-col cols="4" align="center" v-if="visible_data.uzk_parameters.diff_assigment == 1">
                    <div  v-if="visible_data.uzk_parameters.diff_phase == 1">
                        <app-row-left-label v-if="visible_data.uzk_parameters.diff_overhour == 1" :slots="[3, 3, 3, 3]">
                            <template v-slot:col-1>
                                <p class="col-form-value">{{item.ap_a == null || item.ap_a.length == 0 ? '-' : item.ap_a}}</p>
                            </template>
                            <template v-slot:col-2>
                                <p class="col-form-value">{{item.ap_b == null || item.ap_b.length == 0 ? '-' : item.ap_b}}</p>
                            </template>

                            <template v-slot:col-3>
                                <p class="col-form-value">{{item.ap_c== null || item.ap_c.length == 0 ? '-' : item.ap_c}}</p>
                            </template>

                            <template v-slot:col-4>
                                <p class="col-form-value">{{item.a_ort== null || item.a_ort.length == 0 ? '-' : item.a_ort}}</p>
                            </template>
                        </app-row-left-label>

                        <app-row-left-label v-else :slots="[4, 4, 4]">
                            <template v-slot:col-1>
                                <p class="col-form-value">{{item.ap_a == null || item.ap_a.length == 0 ? '-' : item.ap_a}}</p>
                            </template>
                            <template v-slot:col-2>
                                <p class="col-form-value">{{item.ap_b == null || item.ap_b.length == 0 ? '-' : item.ap_b}}</p>
                            </template>

                            <template v-slot:col-3>
                                <p class="col-form-value">{{item.ap_c== null || item.ap_c.length == 0 ? '-' : item.ap_c}}</p>
                            </template>
                        </app-row-left-label>
                    </div>

                    <div v-else>
                         <app-row-left-label v-if="visible_data.uzk_parameters.diff_overhour == 1" :slots="[6,6]">
                        
                        <template v-slot:col-1>
                                <p class="col-form-value">{{item.ap_all == null || item.ap_all.length == 0 ? '-' : item.ap_all}}</p>
                            </template>
                            <template v-slot:col-2>
                                <p class="col-form-value">{{item.a_ort == null || item.a_ort.length == 0 ? '-' : item.a_ort}}</p>
                            </template>

                        </app-row-left-label>

                        <app-row-left-label v-else :slots="[12]">
                                <p class="col-form-value">{{item.ap_all == null || item.ap_all.length == 0 ? '-' : item.ap_all}}</p>
                        </app-row-left-label>
                    </div>
                </b-col>
            </b-row>

            <hr class="app-table-hr-separator">

            <h4 class="mb-3">{{trans('cw-taking-a-temporary-worker',194)}}</h4>
                <b-row>
                    <b-col start cols="6" md="8">
                        <p class="col-list-label">{{trans('number-of-hours',192)}}</p>
                    </b-col>
                    <b-col cols="6" md="4">
                        <p class="col-form-value">{{visible_data.uzk_parameters.hour_value == null ? '-' : visible_data.uzk_parameters.hour_value}}</p>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col start cols="6" md="8">
                        <p class="col-list-label">{{trans('cw-uzk-content',194)}}</p>
                    </b-col>
                    <b-col cols="6" md="4" >
                         <p class="col-form-value" style="white-space: normal">{{'hour_value_content' in visible_data.uzk_parameters ? (visible_data.uzk_parameters.hour_value_content != null && visible_data.uzk_parameters.hour_value_content != '' ? visible_data.uzk_parameters.hour_value_content : '-') : '-' }}</p>
                    </b-col>
                </b-row>

            <hr class="app-table-hr-separator">

            <h4 class="mb-3">{{trans('travel-costs',192)}}</h4>
            
            <b-row v-if="visible_data.km_allow == 0">
                <b-col start cols="6" md="8">
                    <p class="col-list-label">{{trans('cp-no-mileage-allowances-allowed',192)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value"></p>
                </b-col>
            </b-row>
            <b-row v-if="visible_data != null && visible_data.km_allow == 1">
                <b-col start cols="6" md="8">
                    <p class="col-list-label">{{trans('cw-home-work',194)}}</p>
                    <p class="col-list-label">{{trans('cw-work-work',194)}}</p>
                    <p class="col-list-label">{{trans('max-number-of-km-per-shift',192)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value">{{visible_data.cost_house_work == null ? '-' : (visible_data.cost_house_work + "€").replace('.',',')}}</p>
                    <p class="col-form-value">{{visible_data.cost_work_work == null ? '-' : (visible_data.cost_work_work + "€").replace('.',',')}}</p>
                    <p class="col-form-value">{{visible_data.max_km_each_way == null ? '-' : visible_data.max_km_each_way + "km"}}</p>
                </b-col>
            </b-row>

        </div>
    </div>
</template>

<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: { 
       
    },

    props:["itemData", "functions", "return", "filters", "loading"],

    created(){
        this.cItemData.name = this.itemData.parameters[this.itemData.parameters.length-1]
    },

    computed: {
        cRowCount:{
            get() {   

                    if(this.visible_data.zzp_parameters.rates != null){
                        var count = Object.keys(this.visible_data.zzp_parameters.rates).length;
                        return parseInt(count / 2) + count%2;
                    }else{
                        return 0;
                    }
                
                }
        },

        cShifts: {
            get() {
                    return JSON.parse(this.visible_data.zzp_parameters.periods).shifts;
                        
                }
        },

        cKeys: {
            get() {
                    return Object.keys(this.visible_data.zzp_parameters.rates);
                        
                }
        },

        cItemData: {
            get() { return this.itemData },
            set(value) { this.$emit('update:itemData', value) }
        },

        cReturn: {
            get() { return this.return },
            set(value) {this.$emit('update:return', value) }, 
        },

        cLoading: {
            get() { return this.return },
            set(value) {this.$emit('update:loading', value) }, 
        },
    },

    watch: {
      "cItemData.name": {
            handler: function(val) {
                this.visible_data = this.cItemData.name;              
            },
        },
    },

    data() {
        return {
            loading_confirm: false,
            alert_class: new AppAlerts(),
            visible_data: null,
        };
    },

    created(){
        if(this.cItemData.name){
            this.visible_data = this.cItemData.name;   
        }else{
            this.visible_data = this.itemData.parameters[0];   
        }
    },

    methods: {
        getFunctionName(id_function_data_main) {
            
            var name = "";
            this.functions.forEach(item => {
                if(item.id_function_data_main == id_function_data_main){
                    name = item.short_name;
                }
            });

            return name;
        },

      

        deleteIndexation() {
            this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {
                if(res == true){
                    this.cLoading = true;
                    axios
                        .post("clients/profile/contract/removeIndexation", {
                            id_contract_data_main: this.itemData.id_contract_data_main,
                            id_contract_data_parameters: this.visible_data.id,
                            id_client_data_main: this.itemData.id_client_data_main,
                            filters: JSON.stringify(this.filters),

                        })
                        .then((res) => {
                            this.cReturn = res.data;
                            this.cLoading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            
                        });
                };
            });  

        },

        recalculatePlacement(){
            this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {
                if(res == true){
                    this.cLoading = true;
                    axios
                        .post("clients/profile/contract/recalculatePlacement", {
                            id_contract_data_parameters: this.visible_data.id,
                            id_client_data_main: this.itemData.id_client_data_main,
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                            this.cReturn = res.data;
                            this.cLoading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            
                        });
                };
            }); 
        }

    },
};
</script>

<style scoped>
</style>
