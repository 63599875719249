<template> 
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('edit-contact',192)">
        
        <ValidationObserver ref="form" >

            <app-row-left-label :label="trans('first-name',182)">
                <app-input v-model="cArgs.first_name" validatorName="Voornaam" validatorRules="required|max:20" mask="lettersE1-20" />
            </app-row-left-label>

            <app-row-left-label :label="trans('last-name',182)" :slots="[4,8]">
                <template v-slot:col-1>
                    <app-input v-model="cArgs.inbetween_name" :placeholder="trans('inbetween-name',182)" mask="lettersE1-10"/>
                </template>
                    <template v-slot:col-2>
                    <app-input v-model="cArgs.last_name" :placeholder="trans('last-name',182)" validatorName="Achternaam" validatorRules="required" mask="lettersE1-30" />
                </template>
            </app-row-left-label>

            <app-row-left-label :label="trans('function',3)">
                <app-input v-model="cArgs.function" validatorName="Functie" validatorRules="required|max:30" mask="lettersE1-30" />
            </app-row-left-label>
            
            <app-row-left-label :label="trans('phone-number',182)">
                    <app-phone :prefix.sync="cArgs.phone_prefix" :phone.sync="cArgs.phone_number" />
            </app-row-left-label>

            <app-row-left-label :label="trans('email',182)">
                <app-email :email.sync="cArgs.email" validatorName="Email" validatorRules="required|email" type="contact"/>
            </app-row-left-label>

            <app-row-left-label  :label="trans('settings-roles',207)">
                <div class="d-flex justify-content-between">
                    <app-check-box v-model="cArgs.planning"  >{{trans('cp-planning',192)}} </app-check-box> 
                    <app-check-box v-model="cArgs.hours" > {{trans('sh-hours',215)}} </app-check-box>  
                    <app-check-box v-model="cArgs.placement" > {{trans('placment',5)}} </app-check-box>
                </div>
            </app-row-left-label>

        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="contactChange" :loading="loading" type="primary">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AppPhone from '@core/components/AppPhone.vue';
import AppEmail from '@core/components/AppEmail.vue';

export default {
    components: {
        ValidationObserver,
        AppPhone,
        AppEmail
    },

    data() {
        return {
            loading: false,
            opt:[
                    { value: 1, text: this.trans('cp-planning',192) },
                    { value: 2, text: this.trans('sh-hours',215) },
                    { value: 3, text: this.trans('placment',5) },
                ]
        };
    },

    created(){
    },

    
     computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
    },

    props: ["show", "result", "args", "return"],

    methods: {

        contactChange() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    this.loading = true;

                    axios
                        .post("clients/profile/contact/change", {
                            id_client_data_main: this.args.id_client_data_main,
                            contact_data: JSON.stringify(this.cArgs),
                            filters: JSON.stringify(this.args.filters),
                            return_type: this.args.return_type,
                            register_status: this.args.register_status,
                        })
                        .then((res) => {
                            if(this.args.return_type == 'manager'){
                                this.$emit('update:return', res.data.items);
                                this.cArgs.profile.contact.refresh++;
                                
                            }else if(this.args.return_type == 'structure') {
                                this.$emit('update:return', res.data);
                            }

                            this.$emit('update:result', 1);
                            this.$emit("update:show", false);

                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        });

                }
            });
                
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

