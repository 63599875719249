<template>

       <b-overlay :show="loading" :opacity="0.0">
            <app-view-table  customClass="app-local-table-min-height" :isTitle="false"  :loading="loading" :customColumns="customColumns" :isTitleTotal="false"  :filters.sync="filters" :items="cProfile.contact_persons.items" :fields="fields" :loadingInAccordion="true">
                
                <template v-slot:person_name="cell">
                    {{cell.data.item.last_name + ', ' + cell.data.item.first_name + (cell.data.item.inbetween_name !== null ? ' ' + cell.data.item.inbetween_name : '')}}
                </template>

                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="changeContact(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                    <b-dropdown-item @click="removeContact(cell.data.item)">{{trans('remove',175)}}</b-dropdown-item>
                    <b-dropdown-divider v-if="cell.data.item.id_user_portal != null || (profile.client_data.id_status_const_type == 7 || profile.client_data.id_status_const_type == 100)"></b-dropdown-divider>   
                    <b-dropdown-item @click="registerAccount(cell.data.item)" v-if="cell.data.item.id_user_portal == null && (profile.client_data.id_status_const_type == 7 || profile.client_data.id_status_const_type == 100)">{{trans('register',175)}}</b-dropdown-item>
                    <b-dropdown-item @click="changeRole(cell.data.item)" v-if="cell.data.item.id_user_portal != null" >{{trans('change-role',175)}}</b-dropdown-item>
                    <b-dropdown-item @click="block(cell.data.item)" v-if="cell.data.item.id_user_portal != null && cell.data.item.blocked == 0" >{{trans('block',175)}}</b-dropdown-item>
                    <b-dropdown-item @click="unblock(cell.data.item)" v-if="cell.data.item.id_user_portal != null && cell.data.item.blocked == 1" >{{trans('unblock',175)}}</b-dropdown-item>
                    <b-dropdown-item @click="sendResetLink(cell.data.item)" v-if="cell.data.item.id_user_portal != null && cell.data.item.blocked == 0" >{{trans('registration-mail-resend',175)}}</b-dropdown-item>
                </template>

                <template #buttons>
                    <app-button type="primary" @click="addContact">{{trans('cp-add-contact',192)}}</app-button>
                </template>

            </app-view-table>
            <clients-profile-contacts-register-portal :args="mRegisterPortal.args" type :return.sync="cProfile.contact_persons.items" :show.sync="mRegisterPortal.show" :result.sync="mRegisterPortal.result" />
            <clients-profile-contacts-change-portal-role :args="mChangeRolePortal.args" :return.sync="cProfile.contact_persons.items" :show.sync="mChangeRolePortal.show" :result.sync="mChangeRolePortal.result" />
            <clients-profile-contacts-add v-if="mContactAdd.show" :show.sync="mContactAdd.show" :result.sync="mContactAdd.result" :args.sync="mContactAdd.args" :return.sync="cProfile.contact_persons.items" />
            <clients-profile-contacts-change v-if="mContactChange.show" :show.sync="mContactChange.show" :result.sync="mContactChange.result" :args.sync="mContactChange.args" :return.sync="cProfile.contact_persons.items" />
            <template #overlay>
                    <div></div>
            </template>
     </b-overlay>
        
</template>

<script>
import ClientsProfileContactsRegisterPortal from './ClientsProfileContactsRegisterPortal.vue'
import ClientsProfileContactsChangePortalRole from './ClientsProfileContactsChangePortalRole.vue'
import ClientsProfileContactsAdd from './ClientsProfileContactsAdd.vue';
import ClientsProfileContactsChange from './ClientsProfileContactsChange.vue'
import AppAlerts from '@core/scripts/AppAlerts';
import axios from "@axios";

export default {

    components: {
        ClientsProfileContactsRegisterPortal,
        ClientsProfileContactsChangePortalRole,
        ClientsProfileContactsAdd,
        ClientsProfileContactsChange,
    },

    props: {
        profile: { },     
        listHeight: {type: String, default: "240px"},
    },

    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        },
    },
    
    watch: {
      "mContactAdd.result": {
            handler: function(val) {
                if (this.mContactAdd.result == 1) {
                    this.mContactAdd.result = 0;
                    this.cProfile.contact.refresh++;
                }                
            },
        },
    },

    data(){
        return{

            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0,
                },
                filtering: {                        
                        portal_role: { type: 'list', changed: 0, values: [],customList: [{ value: 5, name: this.trans('admin',3)}, { value: 6, name: this.trans('fp-user',172)},], parameters: null, widthClass:'app-width-min-200'},
                        blocked_status: {type: 'list', changed: 0, values: [], customList: [{value: 0, name: this.trans('active-status',3)},{value: 1, name: this.trans('blocked',3)},] ,parameters: null, widthClass:'app-width-min-200'},
                },
                page: 1,
                per_page: 5,
                search: "",
                list_type: 1,
                changed: 0
            },
            customColumns: ["person_name"],

            mContactAdd: { show: false, args: {id_client_data_main: null}, result: 0, return: null },
            mContactChange: {show: false, 
                             args: {}, 
                            result: 0, 
                            return: null},
            items:[],

            fields: [
                { key: "person_name", label: this.trans('full-name',3), thStyle: {  "padding-right":"25px",  width: "100%" } , visible: true },
                { key: "email", label: this.trans('email',182), thStyle: { "padding-right":"25px", "min-width": "250px", "max-width": "250px" }, visible: true},
                { key: "blocked_status", label: this.trans('status',3), thStyle: { "padding-right":"25px", "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "blocked_status"},
                { key: "portal_role", label: this.trans('portal-role',182), thStyle: {  "padding-right":"25px", "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "portal_role" },
                { key: "last_login", label: this.trans('last-login-date',190), thStyle: {  "padding-right":"25px", "min-width": "180px", "max-width": "180px" }, visible: true },
            ],

            loading: false,

            mRegisterPortal: { show: false, args: {id_client_data_main: null, id_contact_data_main: null, filters: null}, result: 0, return: null },
            mChangeRolePortal: { show: false, args: {id_client_data_main: null, id_user_portal: null, filters: null}, result: 0, return: null },

            alertClass: new AppAlerts(),
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods: {

        getList(){
            this.loading = true;
            axios
                .post("clients/profile/contact/getListAll", {
                    id_client_data_main: this.cProfile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.cProfile.contact_persons.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading = false;
                });

        },


        removeContact(item){
            var qustion = "";
            if(item.total_contact_client == 0 && item.total_contact_department == 0 && item.total_contact_location == 0){
                qustion = this.trans('remove-person-confirmation',191,{first_name:item.first_name, last_name:item.last_name});
            }else{
                qustion = this.trans('remove-contact-person-with-locations',191,
                {total_contact_client:  item.total_contact_client > 0 ? '<b>' + item.total_contact_client + '</b> ' + this.trans('cp_client', 191) + ((item.total_contact_location > 0 || item.total_contact_department > 0) ? ', ':'') : '',
                total_contact_location: item.total_contact_location > 0 ? '<b>' + item.total_contact_location + '</b> ' +  this.trans('cp_location', 191) + ((item.total_contact_department > 0) ? ', ':'') : '', 
                total_contact_department: item.total_contact_department > 0 ? '<b>'+ item.total_contact_department + '</b> ' + this.trans('cp_department', 191) + '' : ''}); 
            }



            this.alertClass.openAlertConfirmation(qustion).then(res => {
                if(res == true){
                        
                        this.loading = true;

                        axios
                            .post("clients/profile/contact/remove", {
                                id_contact_data_main: item.id_contact_data_main,
                                filters: JSON.stringify(this.filters),
                                id_client_data_main: this.cProfile.id,
                                register_status: this.profile.client_data.register_status,
                                return_type: 'manager'
                            })
                            .then((res) => {
                                this.filters.page = res.data.page;
                                this.cProfile.contact_persons.items = res.data.items;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            })
                            .finally(() => {
                                this.cProfile.contact.refresh++;
                                this.loading = false;
                            });
                    }

                });

        },

         block(item){
                        
                this.loading = true;

                axios
                    .post("settings/users/blockClientPortal", {
                        id_user_portal: item.id_user_portal,
                        filters: JSON.stringify(this.filters),
                        id_client_data_main: this.cProfile.id,
                        register_status: this.profile.client_data.register_status,
                    })
                    .then((res) => {
                        this.filters.page = res.data.page;
                        this.cProfile.contact_persons.items = res.data.items;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    });

        },

        unblock(item){
                        
                this.loading = true;

                axios
                    .post("settings/users/unblockClientPortal", {
                        id_user_portal: item.id_user_portal,
                        filters: JSON.stringify(this.filters),
                        id_client_data_main: this.cProfile.id,
                        register_status: this.profile.client_data.register_status,
                    })
                    .then((res) => {
                        this.filters.page = res.data.page;
                        this.cProfile.contact_persons.items = res.data.items;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    });

        },

        sendResetLink(item){
                        
                this.loading = true;

                axios
                    .post("settings/users/sendResetLink", {
                        id_user_portal: item.id_user_portal,
                        type: 'contact',
                        register_status: this.profile.client_data.register_status,
                    })
                    .then((res) => {
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    });

        },

        

        registerAccount(item){
            
            this.mRegisterPortal.args.id_contact_data_main = item.id_contact_data_main;
            this.mRegisterPortal.args.id_client_data_main = this.cProfile.id;
            this.mRegisterPortal.args.register_status = this.profile.client_data.register_status,

            this.mRegisterPortal.args.filters = this.filters;
            this.mRegisterPortal.show = true;
        },

         changeRole(item){
            this.mChangeRolePortal.args.id_client_data_main = this.cProfile.id;
            this.mChangeRolePortal.args.id_user_portal = item.id_user_portal;
            this.mChangeRolePortal.args.filters = this.filters;
            this.mChangeRolePortal.args.id_user_const_role = item.id_user_const_role;
            this.mChangeRolePortal.args.register_status = this.profile.client_data.register_status,
            this.mChangeRolePortal.show = true;
        },

        addContact(){
            this.mContactAdd.args.filters = this.filters;
            this.mContactAdd.args.id_client_data_main = this.cProfile.id;
            this.mContactAdd.args.return_type  = 'manager';
            this.mContactAdd.args.register_status = this.profile.client_data.register_status,
            this.mContactAdd.show = true;
        },

        changeContact(contact){

            this.mContactChange.args = JSON.parse(JSON.stringify(contact));
            this.mContactChange.args.id_client_data_main = this.cProfile.id;
            this.mContactChange.args.register_status = this.profile.client_data.register_status;
            this.mContactChange.args.filters = this.filters;
            this.mContactChange.args.return_type  = 'manager';
            this.mContactChange.args.profile = this.cProfile;
            this.mContactChange.show = true;
        },


        onCreateThis() { 

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.portal_role.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.blocked_status.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                } 
            });
           
            this.$watch('cProfile.contact_persons.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                }   
            });
        },

        
    },

}
</script>

<style scoped>
</style>