<template> 
    <b-modal ref="modal-client-profile-structure-location-change" size="l" v-model="computedShow" no-close-on-backdrop :title="trans('change-location',3)">
        
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col md="12" lg="12">
                        <app-row-left-label :label="trans('full-name',3)">
                            <app-input v-model="cArgs.location_data.name" validatorName="Naam" validatorRules="required|max:40" mask="lettersE5-40"/>
                        </app-row-left-label>

                        <app-row-left-label :label="trans('short-name',182)">
                            <app-input v-model="cArgs.location_data.location_short_name" validatorName="Korte naam" validatorRules="max:15" mask="lettersE6-15"/>
                        </app-row-left-label>

                        <app-row-left-label :label="trans('cp-cost-center',192)">
                            <app-input v-model="cArgs.location_data.costplace_number" validatorName="Kostenplaats" mask="lettersE6-20"/>
                        </app-row-left-label>    
                        
                        <app-divider-modal :text="trans('contact-details',182)" :isLine="true"/>
                        
                        <app-row-left-label :label="trans('phone-number',182)">
                            <app-phone :prefix.sync="cArgs.location_data.phone_prefix" :phone.sync="cArgs.location_data.phone_number" :isRequired="false"/>
                        </app-row-left-label>

                        <app-row-left-label :label="trans('email',182)">
                            <app-email :email.sync="cArgs.location_data.email" validatorName="Email" type="person" />
                        </app-row-left-label>

                        <app-divider-modal :text="trans('address-details',3)" :isLine="true"/>

                        <app-row-left-label label="Gebruik organisatie adres" >
                            <app-check-box id="ch_organization_address" v-model="cArgs.location_data.organization_address" class="custom-control-primary" value="1" unchecked-value="0" size="lg" v-on:change="getAddress()"/>
                            <b-spinner v-if="loadingAddressCheck == 1" small class="align-middle app-local-spinner"></b-spinner>                         
                        </app-row-left-label>

                        <app-address-nl ref="address_control" :label="cArgs.location_data.organization_address == 1 ? 'Organisatie adres' : 'Adres'" :address.sync="cArgs.location_data.address" :required="cArgs.location_data.organization_address == 1 ? false : true" :disabled="cArgs.location_data.organization_address == 1 ? true : false" :key="refreshAddress"/>
                        <hr class="app-local-line d-md-block d-lg-none" /> 
                    </b-col>

                </b-row>
            </b-form>
        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="locationChange" type="primary" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppAddressNl from '@core/components/Address/AppAddressNl.vue';
import AppPhone from '@core/components/AppPhone.vue';
import AppEmail from '@core/components/AppEmail.vue';

export default {
    components: {
        ValidationObserver,
        AppAddressNl,
        AppPhone,
        AppEmail,
    },

    props: ["show", "result", "args", "return"],
    
    created() {
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cReturn: {
            get() { return this.return },
            set(value) {this.$emit('update:return', value) }, 
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
    },

    data() {
        return {
            loading: false,
            refreshAddress:0,
            loadingAddressCheck:0,
        };
    },

    methods: {

        async locationChange() {
            this.$refs.form.validate().then((result) => {
                if (!result) {            
                    return false;
                }else{
                    if(!this.$refs.address_control.address.street && this.cArgs.location_data.organization_address != 1){
                        return false;
                    }else{
                    
                        this.loading = true;

                        axios
                            .post("clients/profile/location/change", {
                                id_client_data_main: this.cArgs.id_client_data_main,
                                location_data: JSON.stringify(this.cArgs.location_data),
                                return_type: this.args.return_type,
                                filters: JSON.stringify(this.args.filters),
                            })
                            .then((res) => {
                                this.$emit("update:return", res.data);
                                this.$emit("update:result", 1);
                                this.$emit("update:show", false);                        
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            })
                            .finally(() => {
                                this.loading = false;
                            }); 
                    }
                }
    
            })
                
        },

        getAddress(){
            if(this.cArgs.location_data.organization_address == 1){
                this.loadingAddressCheck = 1;
                axios
                    .post("clients/profile/location/getOrganizationAddress", {
                        id_client_data_main: this.cArgs.id_client_data_main,
                    })
                    .then((res) => {
                        this.cArgs.location_data.address.post_code = res.data.post_code;
                        this.cArgs.location_data.address.house_number = res.data.house_number;
                        this.cArgs.location_data.address.number_additional = res.data.number_additional;
                        this.cArgs.location_data.address.city = res.data.city;
                        this.cArgs.location_data.address.street = res.data.street;
                        this.cArgs.location_data.address.latitude = res.data.latitude;
                        this.cArgs.location_data.address.longitude = res.data.longitude;

                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loadingAddressCheck = 0;
                        this.refreshAddress++;
                    }); 
            }
        },

        closeModal() {
            this.$emit("update:result", -1);
            this.$emit("update:show", false);
        },

    },
};
</script>
<style scoped>
.app-local-height{
    min-height: 30px;
    max-height: 30px;
}

.app-local-line {
    width:100%; 
    border-top: 1px solid #e6e6e6 !important;  
    margin-top:5px !important ;
    margin-bottom: 10px !important;   
}

.app-local-spinner {
    position: absolute;
    top: 4px;
    right: 10px;
}
</style>

