<template>
    <b-modal size="xl" ref="modal-edit" v-model="computedShow" no-close-on-backdrop :title="trans('cp-change-function-aliases',192)">
        <b-card>
            <app-view-table title="" :loading="loading" :isTitleTotal="false" :isSearch="false" :filters.sync="filters" :items="items" :fields="fields" :customColumns="customColumns" :isMenuColumn="canUserEdit">     
                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="changeName(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                </template>   

                    <template v-slot:description="cell">
                    <span v-if="cell.data.item.description != null && cell.data.item.description.length > 60" data-toggle="tooltip" :title="cell.data.item.description">{{cell.data.item.description.substring(0, 60)}} ...</span>
                    <span v-else >{{cell.data.item.description}}</span>
                </template>

                <template  v-slot:name_alias="cell">
                    <b-form-tag  class="app-tags-hide-close app-tags-list" v-for="tag in returnNotEmptyAlias(cell.data.item.name_alias)" :key="tag" >{{tag}}</b-form-tag>
                </template>

                <template v-slot:buttons>
                    <app-button @click="addStep1">{{trans('cp-add-aliases',192)}}</app-button>
                </template>

            </app-view-table>

            <clients-profile-organization-planning-functions-aliases-add-step1 v-if="mAddStep1.show" :show.sync="mAddStep1.show" :args.sync="mAddStep1.args" :result.sync="mAddStep1.result" :return.sync="mAddStep1.return" />
            <clients-profile-organization-planning-functions-aliases-add-step2 v-if="mAddStep2.show" :show.sync="mAddStep2.show" :args.sync="mAddStep2.args" :result.sync="mAddStep2.result" :return.sync="items" />
            <clients-profile-organization-planning-functions-aliases-change v-if="mFunctionEdit.show" :show.sync="mFunctionEdit.show" :result.sync="mFunctionEdit.result" :args="mFunctionEdit.args" :return.sync="items" />
        </b-card>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
        </template>

    </b-modal>
</template>
<script>
import ClientsProfileOrganizationPlanningFunctionsAliasesChange from './ClientsProfileOrganizationPlanningFunctionsAliasesChange.vue';
import ClientsProfileOrganizationPlanningFunctionsAliasesAddStep1 from './ClientsProfileOrganizationPlanningFunctionsAliasesAddStep1.vue';
import ClientsProfileOrganizationPlanningFunctionsAliasesAddStep2 from './ClientsProfileOrganizationPlanningFunctionsAliasesAddStep2.vue';

import axios from "@axios";

export default {
    components:{
        ClientsProfileOrganizationPlanningFunctionsAliasesChange,
        ClientsProfileOrganizationPlanningFunctionsAliasesAddStep1,
        ClientsProfileOrganizationPlanningFunctionsAliasesAddStep2
    },
    mounted() {
        this.onCreateThis();
        this.canUserEdit = this.user_role < 3; 
    },
    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    props: ["show", "result", "args", "return"],

    data(){
        return{          
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            loading: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 5,
                search: "",
                list_type: 1,
                changed: 0,
            },
            fields: [
                { key: "name", label: this.trans('full-name',3), thStyle: { "min-width": "80px", width: "100%" }, visible: true},
                { key: "name_alias", label: this.trans('aliases',182), thStyle: { "min-width": "400px", "max-width": "400px" }, tdClass: "table-column-wrap", visible: true}
            ],
            items: [],
            customColumns: ["name_alias", "description"],

            mFunctionEdit: { show: false, args: {
                id_client_data_main: null,
                id_function_data_main: null,
                name_alias:null,
                filters: null,
            }, result: 0, return: null },
            canUserEdit: false,
            mAddStep1: {
                show: false, args: { id_client_data_main: null}, result: 0, return: null,
            },
            mAddStep2: {
                show: false, args: { 
                    id_client_data_main: null,
                    id_function_data_main: null,
                    filters: null,
                }, result: 0, return: null,
            }
        }
    },  

    methods:{       
        getList: function() {
            this.loading = true;
            axios
                .post("clients/profile/functions/getFunctionsList", {
                    id_client_data_main: this.cArgs.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;

                    for(var i=0; i<this.items.list.length; i++){
                        if(this.items.list[i].name_alias){
                            this.items.list[i].name_alias = this.items.list[i].name_alias.split(',');
                        }
                    }
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading = false;
                });
                
        },

        returnNotEmptyAlias(data) {
            if(data){
                return data.filter(field => field[0] && field.length > 0)
            }
        },

        changeName(item) {
            this.mFunctionEdit.args.id_client_data_main = this.cArgs.profile.id;
            this.mFunctionEdit.args.id_function_data_main = item.id;
            this.mFunctionEdit.args.name_alias = JSON.parse(JSON.stringify(item.name_alias));
            this.mFunctionEdit.args.filters = this.filters;
            this.mFunctionEdit.show = true;
        },

        addStep1(){
            this.mAddStep1.args.id_client_data_main = this.args.profile.id;
            this.mAddStep1.show = true;
        },

        addStep2(id_function_data_main){
            this.mAddStep2.args.id_client_data_main = this.args.profile.id;
            this.mAddStep2.args.id_function_data_main = id_function_data_main;
            this.mAddStep2.args.filters = this.filters;
            this.mAddStep2.show = true;
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                } 
            });


            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading) {
                    this.getList();
                }   
            });

            this.$watch('mAddStep1.return', function(newVal, oldVal) {
                if (newVal != null) {
                    this.addStep2(newVal);
                }   
            });
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    }
 
};
</script>
