<template>
    <ValidationObserver ref="form">
        <b-modal id="modal" ref="modal-add" size="xl" v-model="computedShow" no-close-on-backdrop :title="trans('cp-hirer-reward',192)">
            <b-card class="mb-3">
                <b-row >
                    <b-col class="mb-3">
                        <div class="flabel">{{trans('select-cao',168)}}</div>
                        <div class="filters">
                            <b-button v-for="item in cArgs.organization_scale.cao" :key="item.id_cao_data_main" class="app-input-min-height app-list-button app-local-btn" v-bind:class="{ 'btn-secondary-grey': !item.selected, 'app-list-button-selected':  item.selected }" v-on:click="selectOne(item)">
                                {{item.cao_name}} - {{item.cao_short_name}}
                            </b-button>
                        </div>
                        <small v-if="cArgs.id_cao_data_main == null && cao_error == 1" class="text-danger" :key="refresh">{{trans('select-cao-error',191)}}</small>
                    </b-col>
                </b-row>

                <b-row v-if="cCaoSelectedCount > 1">
                    <b-col cols="12" class="mb-3">
                        <app-row-left-label>
                            <app-radio-group  v-model="cArgs.id_cao_data_main" :options="cCaoSelectedItems" textField="cao_short_name" valueField="id_cao_data_main" :label="trans('main-cao',190)" validatorName="main_role" validatorRules="required" :validatorCustomMessage="{ required: trans('required-message',180) }"/>
                        </app-row-left-label>
                    </b-col>
                </b-row>
            </b-card>
            
             <b-tabs class="mb-3" justified v-if="cCaoSelectedCount > 0">
                <b-tab v-for="item in cCaoSelectedItems" :key="item.id_cao_data_main" :title="item.cao_short_name">
                    <b-card no-body class="app-card-profile">
                        <small class="text-muted my-1 app-local-description">{{trans('cao-scale-description', 193)}}</small>
                        <app-view-table :fields="fields" :items="item.functions" :filters="filters" :customColumns="custom_columns" :isRowHover="false" :isTopBar="false" :isBottomBar="false" :isSettings="false" :isTitle="false" :isDropdownMenu="false" :isSelectable="false" :isMenuColumn="false">
                            <template v-slot:scale_value="item">
                                <app-select ref="vselect" v-model="item.data.item.scale_value" :options="scale_options" reduceValue :clearable="false" appendToBody :calculatePosition="withPopper" />
                            </template>
                        </app-view-table>
                    </b-card>
                </b-tab>
            </b-tabs>

            <b-card class="mb-3">
                <app-row-left-label :slots="[6,6]" customLabel>
                    <template v-slot:custom-label>
                        <span class="col-form-label app-input-label">{{trans('cp-hourly-wage-base-on',192)}}</span>
                        <b-icon icon="info-circle-fill" id="popover-target-hourly-wage-base-on" class="ml-2 app-cursor-pointer" style="width: 17px; height: 17px;" />
                    </template>
                    <template v-slot:col-2>
                        <div class="d-flex">     
                            <app-radio-group v-model="scale_type" :options="opt" />
                        </div>
                    </template>
                </app-row-left-label>
                <app-row-left-label :label="trans('cp-fixed-organizational-increase-percentage',192)" :slots="[6,6]">
                    <template v-slot:col-2>
                        <app-input v-model="cArgs.organization_scale.organizational_increase_percentage" type="integer_max_100" />
                    </template>
                </app-row-left-label>

                <app-row-left-label :label="trans('cp-ort-caps',190)" :slots="[6,6]">
                    <template v-slot:col-2>
                        <app-radio-group v-model="cArgs.organization_scale.ort_capping" :options="ort_capping_options"  />
                    </template>
                </app-row-left-label>

                <app-divider-modal :text="trans('cp-filler-data',192)" :isLine="true"/>

                <app-row-left-label :label="trans('cp-completed-by-and-function',190)" :slots="[6,6]">
                    <template v-slot:col-1>
                        <app-input v-model="cArgs.organization_scale.completed_name" mask="lettersE4-30" validatorRules="required" validatorName="Formulier ingevuld" :placeholder="trans('cp-filled-out-form',190)" />
                    </template>
                    <template v-slot:col-2>
                        <app-input v-model="cArgs.organization_scale.completed_function" mask="lettersE4-30" validatorRules="required" validatorName="Functie" :placeholder="trans('function',3)" />
                    </template>
                </app-row-left-label>
            </b-card>

            <b-popover target="popover-target-hourly-wage-base-on" triggers="focus" no-fade>
                <template #title>
                    {{trans('information',182)}}
                </template>
                <p class="app-popover-text" v-html="trans('cp-hourly-wage-source-tooltip', 192)"></p>
            </b-popover>

            <template #modal-footer>
                <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
                <app-button type="primary" @click="change" :loading="loading"> {{trans('save',175)}} </app-button>
            </template>
        </b-modal>
    </ValidationObserver>
</template>
<script>
export default {
    
}
</script>
<script>

import { ValidationObserver } from "vee-validate";
import axios from "@axios";
import moment from "moment";
import { createPopper } from '@popperjs/core';

export default {

    components: {
        ValidationObserver,
    },

    props: ["show", "result", "return","args"],

    computed:{
        computedShow:{
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }, 
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        },
        cCaoSelectedCount : {
            get() { 
                    
                    if(this.cArgs.organization_scale.cao != null){
                        return this.cArgs.organization_scale.cao.filter(({selected}) => selected == 1).length;
                    }else{
                        return 0;
                    }
                },
        },

        cCaoSelectedItems: {
            get() { 
                    
                    if(this.cArgs.organization_scale.cao != null){
                        return this.cArgs.organization_scale.cao.filter(({selected}) => selected == 1).sort(function(a, b) {return a.sort_index > b.sort_index});
                    }else{
                        return null;
                    }
                },
        },
    },

    created(){
        this.scale_type = this.cArgs.organization_scale.scale_type;
    },

    data(){
        return{
            loading: false,
            loading_cao: false,
            scale_type: null,
            custom_columns: ["scale_value"],
            fields: [
                { key: "function_name", label: this.trans('function',3), sortable: false, thStyle: { "min-width": "80px", "max-width": "80px" },visible: true},
                { key: "scale_value", label: this.trans('scale',182), sortable: false, thStyle: { "min-width": "50px", "max-width": "50px"}, tdClass: "app-local-td-class",  visible: true }
            ],
            filters: {},
            scale_options: [
                { value: 5, name: "5" },
                { value: 10, name: "10" },
                { value: 15, name: "15" },
                { value: 20, name: "20" },
                { value: 25, name: "25" },
                { value: 30, name: "30" },
                { value: 35, name: "35" },
                { value: 40, name: "40" },
                { value: 45, name: "45" },
                { value: 50, name: "50" },
                { value: 55, name: "55" },
                { value: 60, name: "60" },
            ],
            opt: [ 
                { value: 1, text: this.trans('fp-scaling-title',172) },
                { value: 2, text: this.trans('cp-hirer-reward',192) }
            ],
            refresh:0,
            cao_error:0,
            ort_capping_options: [
                { value: 1, text: this.trans('yes-option',3) },
                { value: 0, text: this.trans('no-option',3) },
            ],
        }
    },

    methods: {
        change(){
            this.$refs.form.validate().then((result) => {
                if(result){
                    
                    this.cao_error = 0;
                    if(this.cArgs.id_cao_data_main < 0 || this.cArgs.id_cao_data_main == null){
                        this.cao_error = 1;
                        this.refresh++;
                        return false;
                    }
                    this.cArgs.organization_scale.scale_type = this.scale_type;
                    this.loading = true;
                    axios
                        .post("clients/profile/organization-scale/change", {
                            id_client_data_main: this.args.id_client_data_main,
                            organization_scale: JSON.stringify(this.cArgs.organization_scale),
                            id_cao_data_main: this.cArgs.id_cao_data_main
                        })
                        .then((res) => {
                            this.cReturn.client_data = res.data.client_data;
                            this.cReturn.organization_scale = res.data.organization_scale;
                            this.loading = false;
                            this.$emit('update:result', 1);
                            this.computedShow = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                }

            });
        },

        closeModal(){
            this.computedShow = false;
        },

        selectOne(item){
            
            if(item.selected == 1){
                item.selected = 0;
            }else{
                item.selected = 1;
            }

            if(this.cArgs.id_cao_data_main == null && this.cCaoSelectedCount > 0){
                this.cArgs.id_cao_data_main = this.cCaoSelectedItems[0].id_cao_data_main;
            }else if(this.cCaoSelectedCount == 0){
                 this.cArgs.id_cao_data_main = null;
            }else if(this.cArgs.id_cao_data_main != null && this.cCaoSelectedCount > 0 && this.cArgs.id_cao_data_main != this.cCaoSelectedItems[0].id_cao_data_main){
                this.cArgs.id_cao_data_main = this.cCaoSelectedItems[0].id_cao_data_main;
            }
            
        },

        withPopper(dropdownList, component, { width }){
            
            dropdownList.hidden = true;
            
            setTimeout(() => {
                
                var width_decimals = parseFloat(width) - Math.floor(parseFloat(width));
                var new_width = 0;
                
                if(component.$el.offsetWidth == parseInt(width)){
                    new_width = component.$el.offsetWidth + width_decimals + 'px';
                }else if(component.$el.offsetWidth > parseInt(width)){
                    new_width = component.$el.offsetWidth + '.0px';
                }else if(component.$el.offsetWidth < parseInt(width)){
                    new_width = component.$el.offsetWidth +'.5px';
                }

                dropdownList.style.width = new_width
                dropdownList.hidden = false;

                const popper = createPopper(component.$refs.toggle, dropdownList, {
                    
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -1],
                            },
                        },
                        {
                            name: 'toggleClass',
                            enabled: true,
                            phase: 'write',
                            fn({ state }) {
                            component.$el.classList.toggle(
                                'drop-up',
                                state.placement === 'top'
                            )
                            },
                        },
                    
                    ],
                })

                return () => popper.destroy()        

                    
            }, 10);

        },
    }
    
}
</script>
<style scoped>
.app-local-btn{
    margin-top: 10px;
}
.app-local-td-class {
    max-width: 60px;
    min-width: 60px;
}

.app-local-description{
    margin-left: 10px;
}
</style>