<template>
    
    <b-card class="mb-3">
        <app-view-table :title="trans('cp-locations-and-departaments-total',192,{itemstotal : cProfile.structure_locations.items.total, departmentstotal : cProfile.structure_locations.departments_total})" :isSearch="true" :isTitleTotal="false" :loading="loading_list" :filters.sync="filters" :items="cProfile.structure_locations.items" :fields="fields" :customColumns="customColumns" :filterOptions="status_list" :isFilterOptions="true" :isMenuColumn="profile.client_data.id_status_const_type > 3 && (user_role < 3 || profile.can_user_edit)" customClass="app-local-table-details">
        
            <template v-slot:menu-items="cell">
                <b-dropdown-item @click="addDepartment(cell.data)" >{{trans('cp-add-department',192)}}</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="changeLocation(cell.data)">{{trans('change',175)}}</b-dropdown-item>
                <b-dropdown-item @click="removeLocation(cell.data)" v-if="cell.data.item.deleted != 1" >{{trans('remove',175)}}</b-dropdown-item>
            </template>

            <template v-slot:collapse="cell">
                <div>
                    <b-icon v-if="cell.data.item.departments.length > 0" @click="expandRow(cell)" :icon="cell.data.detailsShowing ? 'dash-square-fill' : 'plus-square-fill'" class="text-lg app-cursor-pointer"/>
                    <b-icon v-else icon="plus-square-fill" class="text-lg cursor-pointer text-muted" />     
                 </div>                                 
            </template>
            
           <template v-slot:info="cell">
               
                <div class="app-local-title"> <b-link :style="profile.client_data.id_status_const_type > 3 ? 'cursor:pointer': 'cursor:default'" @click="profile.client_data.id_status_const_type > 3 ? getProfile(cell.data.item, 'location'): ''"> {{cell.data.item.name}} </b-link></div>
                <div class="app-local-details mr-3"> {{ getAddress(cell.data.item) }} </div>
                <div v-if="cell.data.item.departments.length == 0" class="mt-2"> 
                    <small class="text-danger">{{trans('minimum-one-department-error',191)}}</small>     
                </div>        
            </template>

            <template v-slot:departments_count="cell">
               <app-badge v-if="cell.data.item.departments.length > 0"> {{cell.data.item.departments.length}} </app-badge>
            </template>

            <template v-slot:contacts="cell">
                <div class="d-flex" @drop="onDrop($event, cell.data.item.ids_contact_data_main, 1, cell.data.item)" @dragover.prevent @dragenter.prevent>
                    <clients-profile-contacts-avatar-component :contacts="cProfile.structure_locations.contacts" :ids_contact_data_main="cell.data.item.ids_contact_data_main" @onRemove="removeLocationContact($event, cell.data.item)" :idDraggable="user_role < 3 || profile.can_user_edit" :canDisconnect="user_role < 3 || profile.can_user_edit"/> 
                </div>
            </template>

            <template v-slot:row-details="row" >       
                <div v-for="(item, index) in row.data.item.departments" :key="item.id_department_data_main" >

                    <hr class="app-search-modal-separator" v-if="index !== 0"/>
                    <b-row class="mb-1 mt-1 displa-flex align-items-center app-department" :class="{'py-1': user_role > 2 && !profile.can_user_edit}">
                        <b-col cols="auto" end>
                            <b-icon icon="arrow-return-right" class="text ml-2"/>                    
                        </b-col>
                        <b-col class="mt-1">
                            <div class="app-local-title"> <b-link :style="profile.client_data.id_status_const_type > 3 ? 'cursor:pointer': 'cursor:default'" @click="profile.client_data.id_status_const_type > 3 ? getProfile(item, 'department'): ''">{{ item.name }} <span v-if="item.costplace_number != null && item.costplace_number.length >0"  class="text-muted"> | </span> <span v-if="item.costplace_number != null && item.costplace_number.length >0" > {{item.costplace_number}} </span>   </b-link> </div>
                        </b-col>                       
                        <b-col cols="auto app-local-col-width">
                            <div @drop="onDrop($event, item.ids_contact_data_main, 2, item)" @dragover.prevent @dragenter.prevent>
                                <clients-profile-contacts-avatar-component :contacts="cProfile.structure_locations.contacts" :ids_contact_data_main="item.ids_contact_data_main" @onRemove="removeDepartmentContact($event, item)" @drop="onDrop()" :idDraggable="user_role < 3 || profile.can_user_edit" :canDisconnect="user_role < 3 || profile.can_user_edit"/>
                            </div>                                                                
                        </b-col>
                        <b-col cols="auto" end class="pl-0 pr-1 app-local-inherit">
                            <b-dropdown v-if="profile.client_data.id_status_const_type > 3 && (user_role < 3 || profile.can_user_edit)" variant="link" no-caret right boundary="window">
                                <template #button-content>
                                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                                </template>
                                <b-dropdown-item @click="changeDepartment(item)">{{trans('change',175)}}</b-dropdown-item>
                                <b-dropdown-item @click="removeDepartment(item)">{{trans('remove',175)}}</b-dropdown-item>
                            </b-dropdown>
                        </b-col>
                    </b-row>                                    
                </div>
            </template>
            
            <template v-slot:buttons>
                <app-button type="primary" @click="addLocation()" v-if="profile.client_data.id_status_const_type > 3 && (user_role < 3 || profile.can_user_edit)">{{trans('add-location',175)}}</app-button>
            </template>
        </app-view-table>
        <div class="mt-2" v-if="cProfile.ready_structure === null || cProfile.ready_structure === 0">           
            <small class="text-danger"> {{trans('structure-not-ready-yet-error',180)}} </small>   
        </div>
        
        <clients-profile-location-add-with-contacts v-if="mLocationAdd.show" :show.sync="mLocationAdd.show" :result.sync="mLocationAdd.result" :args.sync="mLocationAdd.args" :return.sync="cProfile.structure_locations"/>
        <clients-profile-location-change-with-contacts v-if="mLocationChange.show" :show.sync="mLocationChange.show" :result.sync="mLocationChange.result" :args.sync="mLocationChange.args" :return.sync="cProfile.structure_locations"/>
        <clients-profile-department-add-with-contacts v-if="mDepartmentAdd.show" :show.sync="mDepartmentAdd.show" :result.sync="mDepartmentAdd.result" :args.sync="mDepartmentAdd.args" :return.sync="cProfile.structure_locations"/>
        <clients-profile-department-change-with-contacts v-if="mDepartmentChange.show" :show.sync="mDepartmentChange.show" :result.sync="mDepartmentChange.result" :args.sync="mDepartmentChange.args" :return.sync="cProfile.structure_locations"/>
    </b-card>

</template>

<script>
import axios from "@axios";
import ClientsProfileContactsAvatarComponent from '@app/Clients/Profile/Contact/ClientsProfileContactsAvatarComponent.vue';
import ClientsProfileLocationAddWithContacts from '@app/Clients/Profile/Location/ClientsProfileLocationAddWithContacts.vue';
import ClientsProfileLocationChangeWithContacts from '@app/Clients/Profile/Location/ClientsProfileLocationChangeWithContacts.vue';

import ClientsProfileDepartmentAddWithContacts from '@app/Clients/Profile/Department/ClientsProfileDepartmentAddWithContacts.vue';
import ClientsProfileDepartmentChangeWithContacts from '@app/Clients/Profile/Department/ClientsProfileDepartmentChangeWithContacts.vue';

import AppAlerts from '@core/scripts/AppAlerts';
import AppBadge from '@core/components/AppBadge.vue';

export default {
    components: {
        ClientsProfileContactsAvatarComponent,
        ClientsProfileLocationAddWithContacts,
        ClientsProfileLocationChangeWithContacts,
        ClientsProfileDepartmentAddWithContacts,
        ClientsProfileDepartmentChangeWithContacts,
        AppBadge
    },

    props: ["profile"],
    
    mounted() {
         this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { 
                    var item_data = this.profile;
                    item_data.structure_locations.items.list.forEach(item => { 
                        item._showDetails = this.ids_location_data_expanded.includes(item.id_location_data_main);                         
                    });
                    return item_data; 
                  },
            set(value) { this.$emit('update:profile', value) }
        },

    },

    data() {
        return {
            filters: {
                page: 1,
                per_page: 10,
                sorting: {
                    field_name: null,
                    sort_direction: 0,
                    changed: 0,
                },
                changed:0,
                filtering: {},
                search: "",
                status: 1,
                list_type:1,
            },
            status_list: [
                { value: 1, name: this.trans('active',3) },
                { value: 2, name: this.trans('blocked',3) },   
                { value: 3, name: this.trans('cp-show-all',192) },             
            ],
            ids_location_data_expanded: [],
            alertClass: new AppAlerts(),         
            loading_list: false,
            
            mLocationAdd: { show: false, args: {id_client_data_main: null, contacts: null, filters:null, return_type:null}, result: 0, return: null },            
            mLocationChange: { show: false, 
                               args: {id_client_data_main: null,
                                      contacts: null,
                                      filters:null,

                                      return_type:null,
                                      location_data: {
                                                        id_location_data_main: null,
                                                        name: "",
                                                        location_short_name:"",
                                                        costplace_number: null,
                                                        address: {
                                                            post_code: "", 
                                                            house_number: "", 
                                                            house_number_additional: "",
                                                            city: "",
                                                            street: "",
                                                            latitude: null,
                                                            longitude: null
                                                        },
                                                        organization_address:null,
                                                        email: '',
                                                        phone_prefix: '+31',
                                                        phone_number: '',
                                                    }
                                    }, 
                               result: 0,
                               return: null },

            mDepartmentAdd: { show: false, args: {id_location_data_main: null, contacts: null, id_client_data_main: null, filters:null, return_type:null}, result: 0, return: null },
            mDepartmentChange: { show: false, 
                               args: {id_location_data_main: null,
                                        id_client_data_main: null,
                                        filters:null, 
                                        return_type:null,
                                        
                                        contacts: null,
                                        department_data: {
                                                        id_department_data_main: null,
                                                        name: "",
                                                        department_short_name: "",
                                                        costplace_number: null,
                                                        email: '',
                                                        name_alias:null,
                                                        phone_prefix: '+31',
                                                        phone_number: '',
                                                        external_id:null,
                                                    }
                                    }, 
                               result: 0,
                               return: null },

            customColumns: ["collapse", "info", "departments_count", "contacts"],

            fields: [
                { key: "collapse", label: "", visible: true, filtering: false},
                { key: "info", label: this.trans('cp-location-data',190), thStyle: { "min-width": "200px", width: "100%" }, visible: true, filtering: false},
                { key: "departments_count", label: this.trans('departments',3), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: false},
                { key: "contacts", label: this.trans('contacts',168), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: false, sortable: false}
            ], 
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,           
            
        };
    },

     methods: {

        getList: function() {
            this.loading_list = true;
            axios
                .post("clients/profile/structure/getLocationsAndDepartments", {
                    id_client_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.cProfile.structure_locations = res.data;
                    this.filters.page = res.data.filters.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        }, 

        onDrop(event, ids_contact_data_main, type, item) {
            var item_id = event.dataTransfer.getData('id_contact_data_main')
            if(!ids_contact_data_main.includes(item_id)) {
                this.loading_list = true;
                if (type == 1) {
                    axios
                    .post("clients/profile/contact/addToLocation", {
                        id_location_data_main: item.id_location_data_main,
                        id_contact_data_main: item_id,
                        id_client_data_main: this.cProfile.id,
                        filters: JSON.stringify(this.filters),  
                        return_type: "organization_profile", 
                    })
                    .then((res) => {
                        this.cProfile.structure_locations = res.data;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });
                } else if (type == 2) {
                    axios
                    .post("clients/profile/contact/addToDepartment", {
                        id_department_data_main: item.id_department_data_main,
                        id_contact_data_main: item_id,
                        id_client_data_main: this.cProfile.id,
                        filters: JSON.stringify(this.filters),  
                        return_type: "organization_profile", 
                    })
                    .then((res) => {
                        this.cProfile.structure_locations = res.data;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });
                }
                 
            }            
        },

         expandRow(cell) {
                         
            if(!cell.data.detailsShowing) {
                this.ids_location_data_expanded.push(cell.data.item.id_location_data_main);
             } else {
                this.ids_location_data_expanded.splice(this.ids_location_data_expanded.indexOf(cell.data.item.ids_location_data_expanded), 1);
             } 
         },

         getAddress(data) {
             if (data.post_code != undefined && data.post_code != null && data.post_code != "") {
                var l_address = data.street + ' ' + data.house_number;
                if (data.number_additional !== undefined && data.number_additional) {
                    l_address = l_address + " " + data.number_additional;
                }

                l_address = l_address + ", " + data.city;
                return l_address; 
             } else {
                 return "";
             }
            
         },
         
        onCreateThis() {  

            this.$watch('cProfile.structure_locations.items.refresh', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                   this.getList();
                }   
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loadingList) {
                        this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loadingList) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });
        },

        addLocation() {
            this.mLocationAdd.args.id_client_data_main = this.profile.id;
            this.mLocationAdd.args.filters = this.filters;
            this.mLocationAdd.args.return_type = "organization_profile";
            this.mLocationAdd.result = 0;
            this.mLocationAdd.show = true;
        },

        changeLocation(data) {

            this.mLocationChange.args.id_client_data_main = this.profile.id;
            this.mLocationChange.args.filters = this.filters;
            this.mLocationChange.args.return_type = "organization_profile";
            this.mLocationChange.args.ids_contact_data_main = data.item.ids_contact_data_main;
            this.mLocationChange.args.location_data.id_location_data_main = data.item.id_location_data_main;
            this.mLocationChange.args.location_data.name = data.item.name;
            this.mLocationChange.args.location_data.location_short_name = data.item.location_short_name;
            this.mLocationChange.args.location_data.costplace_number = data.item.costplace_number;
            this.mLocationChange.args.location_data.email = data.item.email;
            this.mLocationChange.args.location_data.phone_prefix = data.item.phone_prefix == null ? '+31' : data.item.phone_prefix;
            this.mLocationChange.args.location_data.phone_number = data.item.phone_number;
            this.mLocationChange.args.location_data.organization_address = data.item.organization_address;
            this.mLocationChange.args.location_data.address.post_code = data.item.post_code;
            this.mLocationChange.args.location_data.address.house_number =  data.item.house_number;
            this.mLocationChange.args.location_data.address.house_number_additional = data.item.number_additional;
            this.mLocationChange.args.location_data.address.city = data.item.city;
            this.mLocationChange.args.location_data.address.street = data.item.street;
            this.mLocationChange.args.location_data.address.latitude = data.item.latitude;
            this.mLocationChange.args.location_data.address.longitude = data.item.longitude;
            this.mLocationChange.result = 0;
            this.mLocationChange.show = true;
        },

        removeLocation(data) {
            if(this.cProfile.structure_locations.items.list.length == 1) {
                this.alertClass.openAlertInfo(this.trans('minimum-one-location-present-error', 191));
                return;
            }

            this.alertClass.openAlertConfirmation(this.trans('cp-delete-location-confirmation', 191, {name : data.item.name})).then(res => {
                    if(res == true){
                            
                            this.loading_list = true;

                            axios
                                .post("clients/profile/location/remove", {
                                    id_location_data_main: data.item.id_location_data_main ,
                                    id_client_data_main: this.cProfile.id,
                                    filters: JSON.stringify(this.filters),  
                                    return_type: "organization_profile",                                  
                                })
                                .then((res) => {
                                    this.cProfile.structure_locations = res.data;
                                    this.filters.page = res.data.filters.page;
                                })
                                .catch((error) => {
                                    console.error(`error during request: ${error}`);
                                })
                                .finally(() => {
                                    this.loading_list = false;
                                });
                        }

                    });

        },

        addDepartment(data) {
            this.mDepartmentAdd.args.id_location_data_main = data.item.id_location_data_main;
            this.mDepartmentAdd.args.id_client_data_main = this.profile.id;
            this.mDepartmentAdd.args.filters = this.filters;
            this.mDepartmentAdd.args.return_type = "organization_profile";
            this.mDepartmentAdd.result = 0;
            this.mDepartmentAdd.show = true;
        },

        changeDepartment(item) {
            this.mDepartmentChange.args.id_client_data_main = this.profile.id;
            this.mDepartmentChange.args.filters = this.filters;
            this.mDepartmentChange.args.return_type = "organization_profile";
            this.mDepartmentChange.args.id_location_data_main = item.id_location_data_main;
            this.mDepartmentChange.args.ids_contact_data_main = item.ids_contact_data_main;
            this.mDepartmentChange.args.department_data.id_department_data_main = item.id_department_data_main;
            this.mDepartmentChange.args.department_data.department_short_name = item.department_short_name;
            this.mDepartmentChange.args.department_data.name = item.name;
            this.mDepartmentChange.args.department_data.costplace_number = item.costplace_number;
            this.mDepartmentChange.args.department_data.email = item.email;
            this.mDepartmentChange.args.department_data.phone_prefix = item.phone_prefix == null ? '+31' : item.phone_prefix;
            this.mDepartmentChange.args.department_data.name_alias = JSON.parse(JSON.stringify(item.name_alias != null ? item.name_alias.split(',') : JSON.parse(JSON.stringify([]))));
            this.mDepartmentChange.args.department_data.phone_number = item.phone_number;
            this.mDepartmentChange.args.department_data.external_id = item.external_id;
            this.mDepartmentChange.result = 0;
            this.mDepartmentChange.show = true;
        },

        removeDepartment(item){

            this.alertClass.openAlertConfirmation(this.trans('cp-remove-department-confirmation', 191, {name : item.name})).then(res => {
                if(res == true) {
                    this.loading_list = true;
                    axios
                        .post("clients/profile/location/department/remove", {
                            id_department_data_main: item.id_department_data_main,
                            id_location_data_main: item.id_location_data_main,
                            id_client_data_main: this.cProfile.id,
                            filters: JSON.stringify(this.filters),  
                            return_type: "organization_profile", 
                        })
                        .then((res) => {
                            this.cProfile.structure_locations = res.data;
                            this.filters.page = res.data.filters.page;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });
                }
            });
        },

        removeLocationContact(event, item) {
            var name = event.first_name + " " + event.last_name
            this.alertClass.openAlertConfirmation(this.trans('unlink-contact-from-location-confirmation', 191, {name : name})).then(res => {
            if(res == true) {                        
                this.loading_list = true;
                axios
                    .post("clients/profile/contact/removeFromLocation", {
                        id_contact_data_main: event.id_contact_data_main,
                        id_location_data_main: item.id_location_data_main,
                        id_client_data_main: this.cProfile.id,
                        filters: JSON.stringify(this.filters),  
                        return_type: "organization_profile", 
                    })
                    .then((res) => {
                        this.cProfile.structure_locations = res.data;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });
                }
            });
        },

        removeDepartmentContact(event, item) {
            var name = event.first_name + " " + event.last_name
            this.alertClass.openAlertConfirmation(this.trans('cp-unlink-person-from-section-confirmation', 191,{name : name})).then(res => {
                if(res == true) {  
                    this.loading_list = true;                      
                    axios
                        .post("clients/profile/contact/removeFromDepartment", {
                            id_contact_data_main: event.id_contact_data_main,
                            id_department_data_main: item.id_department_data_main,
                            id_client_data_main: this.cProfile.id,
                            id_location_data_main: item.id_location_data_main,
                            filters: JSON.stringify(this.filters),  
                            return_type: "organization_profile", 
                        })
                        .then((res) => {
                            this.cProfile.structure_locations = res.data;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });
                }
            });
        },

        getProfile: function(item, type) {
            if(type == "location"){
                this.$router.push({ name: "clients-profile-location", params: { id_location_data_main: item.id_location_data_main } });
            }else{
                this.$router.push({ name: "clients-profile-department", params: { id_department_data_main: item.id_department_data_main } });
            }
        },
     },

    
};
</script>

<style>
.app-local-details {
    font-size: 0.8125rem;
    line-height: 1;    
}

.app-local-title {
    font-size: 0.9rem;
    font-weight: 600;
}


.app-local-col-width {
    min-width: 200px;
    max-width: 200px; 
}

.app-local-inherit{
    position: inherit !important;
}

.app-local-table-details .b-table-details td {
    padding-top: 0px;
    padding-bottom: 0px;
}

.app-department:hover{
    background: #EDF7FB;
}


</style>
