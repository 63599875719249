<template>
    <div>
        <b-card no-body class="app-card-profile">
            <app-view-table :isTitle="false" :loading="loading_list" :isTitleTotal="false" :filters.sync="filters" :items="profile.placements.items" :fields="fields" :isFilterOptions="true" :filterOptions="status_list" :isSearch="true" v-on:onRowClicked="item=>$set(item, '_showDetails', !item._showDetails)" class="table-selectable">

                <template v-slot:row-details="" >
                    
                </template>

                <template v-slot:menu-items="cell">
                    <b-dropdown-item :to="{ name: 'people-person-profile', params: { id_person_data_main: cell.data.item.id_person_data_main }}">{{trans('to-flexworker',175)}}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'planning-placements-profile', params: { id_placement_data_main: cell.data.item.id_placement_data_main }}">{{trans('to-placements',175)}}</b-dropdown-item>
                </template>

            </app-view-table>
        </b-card>               
        
    </div>
</template>

<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
    },

    data() {
        return {
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 1,
                        changed: 0
                },
                filtering: {                        
                },
                page: 1,
                per_page: 5,
                search: "",
                changed: 0,
                list_type: 1,
            },
            fields: [
                { key: "person_name", label: this.trans('flexworker',182), thStyle: { "min-width": "90px", width: "100%" }, visible: true },
                { key: "date_from", label: this.trans('date-from',182), thStyle: {  "min-width": "140px", "max-width": "140px" }, visible: true },
                { key: "date_to", label: this.trans('date-to',182), thStyle: {  "min-width": "140px", "max-width": "140px" }, visible: true },
                { key: "status_name", label: this.trans('status',3), thStyle: {  "min-width": "140px", "max-width": "140px" }, visible: true },
            ],
            loading_list: false,
            alert_class: new AppAlerts(),
            status_list: [
                { value: 1, name: this.trans('active',3) },
                { value: 2, name: this.trans('inactive',3) }
            ],
        };
    },

    props:["profile"],

     mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }, 
        },
    },

    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("clients/profile/placements/getList", {
                    id_client_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.cProfile.placements.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        }, 

         onCreateThis() { 
            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('profile.placements.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });
        },

    },
};
</script>

<style scoped>

</style>
