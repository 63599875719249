<template>
    <div>
        <b-card no-body class="app-card-profile">
               <b-row class="app-card-header">
                    <b-col >
                        <div class="app-card-profile-title">
                            {{trans('general-data',3)}}
                        </div>
                    </b-col>
                </b-row>
                        
                <b-row class="mt-3">
                    <b-col md="3" xl="3">
                        <b-form-group :label="trans('cp-primary-hn-location',192)">
                            <span style="font-weight:700; color:#174066; font-size:14px">  {{profile.client_data.office_name ? profile.client_data.office_name : '-'}} </span>                      
                        </b-form-group>
                    </b-col>
                    <b-col md="5" xl="5">
                        <b-form-group :label="trans('cp-part-of',192)">
                             <span style="font-weight:700; color:#174066; font-size:14px"> {{profile.client_data.group_name ? profile.client_data.group_name : '-'}} </span>   
                        </b-form-group>
                    </b-col>
                    <b-col md="4" xl="4">
                        <b-form-group :label="trans('cp-account-manager',192)">
                            <span style="font-weight:700; color:#174066; font-size:14px"> {{profile.client_data.user_name ? profile.client_data.user_name: '-'}} </span>
                        </b-form-group>
                    </b-col>
                </b-row>
           
        </b-card>
    </div>
</template>
<script>


export default {

    data(){
        return{
        }
    },


    props:["profile"],


};
</script>

<style scoped>

</style>