<template>
  <div v-if="profile !== null" class="app-navbar-profile-content">  
    <b-navbar sticky class="app-navbar-profile">
    <div class="app-navbar-header">
      <b-row>
          <b-col cols="8" class="app-navbar-profile-margin-4"></b-col>
          <b-col end class="app-navbar-profile-margin-30">
              <div class="d-flex justify-content-end align-items-center">
                <div class="font-weight-800 mr-4 mt-1" v-if="profile.client_data.register_status == 0"> {{trans('core-suspect-profile', 271)}} </div>
                <div class="font-weight-800 mr-4 mt-1" v-if="profile.client_data.register_status == 1"> {{trans('core-prospect-profile', 271)}} </div>
                <div class="font-weight-800 mr-4 mt-1" v-if="profile.client_data.register_status == 2"> {{trans('core-client-profile', 271)}} </div>
                <b-dropdown variant="link" right>
                    <template #button-content>
                        <b-icon icon="gear-fill" style="width: 17px; height: 17px;"></b-icon>
                    </template>
                    <b-dropdown-item :to="{ name: 'clients-profile-history', params: { id_client_data_main: id_client_data_main }}">{{trans('history',182)}}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'clients-profile-webforms', params: { id_client_data_main: id_client_data_main }}" >{{trans('web-forms-option',175)}}</b-dropdown-item>
                    <b-dropdown-item v-if="profile.client_data.register_status == 1" @click="changeStatusToSuspect()" >{{trans('change-to-suspect', 189)}}</b-dropdown-item>
                </b-dropdown>
                
              </div>
        </b-col>
      </b-row>
      <b-row class="align-items-center mb-3">
        <b-col> 
          <clients-profile-organization-status-bar-component :profile.sync ="profile" />
        </b-col>
      </b-row> 
    </div>
    </b-navbar>
    <div>
          <b-row>
              <b-col md="12" lg="8" class="pr-lg-2">
                  <clients-profile-organization-action-points-component :profile.sync="profile" />
                  <clients-profile-organization-info-component class="mb-3" :profile.sync="profile" />

                  <b-tabs class="mb-3" justified :nav-class="profile.client_data.id_status_const_type <= 5 && first_release != 0 ? 'd-none' : ''">
                    <b-tab :title="trans('locations',3)" @click="changeTab(0)" active><clients-profile-organization-structure-component v-if="profile.structure_locations !== undefined" :profile.sync="profile"/>  </b-tab>          
                    <b-tab :title="trans('contracts',3)" @click="changeTab(1)" v-if="profile.client_data.id_status_const_type > 5"> <clients-profile-organization-contract-component  :profile.sync="profile"/> </b-tab> 
                    <b-tab :title="trans('placment',5)" @click="changeTab(2)" v-if="first_release == 0 && profile.client_data.id_status_const_type > 5"> <clients-profile-organization-placements-component  :profile.sync="profile"/> </b-tab> 
                  </b-tabs>

                  <app-collapse accordion type="margin">
                        <b-card no-body class="app-card-client">
                            <app-collapse-item style="box-shadow:none !important;" :title="trans('cp-organization-details',192)" :id="'organisation-information'">
                              <clients-profile-organization-data-component :profile.sync="profile" />
                            </app-collapse-item>
                        </b-card>

                        <b-card no-body class="app-card-client">
                            <app-collapse-item style="box-shadow:none !important;" :title="trans('contacts',168)" :id="'contacts'">
                              <clients-profile-contacts-acordion-component :profile.sync="profile" />
                            </app-collapse-item>
                        </b-card>

                        <b-card no-body v-if="profile.client_data.register_status > 0" class="app-card-client">
                            <app-collapse-item  style="box-shadow:none !important;" :title="trans('financial-details',172)" :id="'financials'">
                              <clients-profile-organization-financials-component :profile.sync="profile" />
                            </app-collapse-item>
                        </b-card>

                        <b-card  v-if="profile.client_data.register_status > 0" no-body class="app-card-client">
                            <app-collapse-item style="box-shadow:none !important;" :title="trans('cp-credit-check',192)" :id="'credit-check'">
                              <client-profile-organization-financials-credit-check-component :profile.sync="profile" />
                            </app-collapse-item>
                        </b-card>

                        <b-card no-body class="app-card-client">
                            <app-collapse-item style="box-shadow:none !important;" :title="trans('cp-sales',192)" :id="'sales'">
                              <clients-profile-organization-sales-component :profile.sync="profile" />
                            </app-collapse-item>
                        </b-card>

                        <b-card no-body v-if="profile.client_data.register_status > 0 && profile.client_data.id_status_const_type >= 6" class="app-card-client">
                            <app-collapse-item  style="box-shadow:none !important;" :title="trans('cp-planning',192)" :id="'planning'">
                              <clients-profile-organization-planning-component :profile.sync="profile" />
                            </app-collapse-item>
                        </b-card>

                        <b-card  v-if="profile.client_data.register_status > 0" no-body class="app-card-client">
                            <app-collapse-item style="box-shadow:none !important;" :title="trans('cp-hirer-reward',192)" :id="'organization-scale'">
                              <!-- <clients-profile-organization-financials-component :profile.sync="profile" /> -->
                              <clients-profile-organization-scale-component :profile.sync="profile" />
                            </app-collapse-item>
                        </b-card>

                        <b-card  v-if="profile.client_data.register_status > 0" no-body class="app-card-client">
                            <app-collapse-item style="box-shadow:none !important;" :title="trans('cp-arbo-checklist',192)" :id="'checklist'">
                              <clients-profile-organization-checklist-component :profile.sync="profile" />
                            </app-collapse-item>
                        </b-card>

                        <b-card no-body class="app-card-client">
                            <app-collapse-item style="box-shadow:none !important;" :title="trans('enrollment',3)" :id="'registration'">
                              <clients-profile-organization-registration-component :profile.sync="profile" />
                            </app-collapse-item>
                        </b-card>

                        <b-card no-body class="app-card-client">
                            <app-collapse-item style="box-shadow:none !important;" :title="trans('documents',3)" :id="'documents'">
                              <clients-profile-organization-documents-component :profile.sync="profile" />
                            </app-collapse-item>
                        </b-card>

                        <b-card no-body class="app-card-client">
                          <app-collapse-item  style="box-shadow:none !important;" :title="trans('cp-excluded-flexworkers',192)" :id="'excluded-flexworkers'">
                            <clients-profile-organization-blocked-person-component :profile.sync="profile" />
                          </app-collapse-item>
                        </b-card>

                        

                    </app-collapse>
            
              </b-col>

              <b-col md="12" lg="4" class="mt-3 mt-lg-0 pl-lg-2">
                <clients-profile-organization-contacts-component class="mb-3" :profile.sync="profile"/>
                 <issues-profile-component class="mb-3" :profile.sync="profile" type="client"/>
                 <notes-component :profile.sync="profile"/>
              </b-col>
          </b-row>

        </div>
  </div>
</template>
<script>

import axios from "@axios";
import AppCollapse from "@core/components/AppCollapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/AppCollapse/AppCollapseItem.vue";

import ClientsProfileOrganizationStatusBarComponent from './Organization/ClientsProfileOrganizationStatusBarComponent.vue';
import IssuesProfileComponent from '@app/Issues/IssuesProfileComponent.vue';
import NotesComponent from '@app/Notes/NotesComponent.vue';
import ClientsProfileOrganizationInfoComponent from './Organization/ClientsProfileOrganizationInfoComponent.vue';
import ClientsProfileOrganizationActionPointsComponent from './Organization/ClientsProfileOrganizationActionPointsComponent';

//Tabs
import ClientsProfileOrganizationContractComponent from './Contract/ClientsProfileOrganizationContractComponent.vue';
import ClientsProfileOrganizationSalesComponent from './Organization/Sales/ClientsProfileOrganizationSalesComponent.vue';
import ClientsProfileOrganizationPlacementsComponent from './Organization/Placements/ClientsProfileOrganizationPlacementsComponent.vue';

//Collapse
import ClientsProfileOrganizationFinancialsComponent from './Organization/Financials/ClientsProfileOrganizationFinancialsComponent.vue';
import ClientProfileOrganizationFinancialsCreditCheckComponent from './Organization/Financials/ClientProfileOrganizationFinancialsCreditCheckComponent.vue';
import ClientsProfileOrganizationChecklistComponent from  './Organization/Checklist/ClientsProfileOrganizationChecklistComponent.vue';
import ClientsProfileOrganizationDataComponent from  './Organization/Data/ClientsProfileOrganizationDataComponent.vue';
import ClientsProfileOrganizationRegistrationComponent from  './Organization/Registration/ClientsProfileOrganizationRegistrationComponent.vue';
import ClientsProfileOrganizationContactsComponent from './Contact/ClientsProfileOrganizationContactsComponent.vue';
import ClientsProfileOrganizationStructureComponent from './Organization/ClientsProfileOrganizationStructureComponent.vue';
import ClientsProfileOrganizationScaleComponent from './Organization/Scale/ClientsProfileOrganizationScaleComponent.vue';
import ClientsProfileOrganizationPlanningComponent from './Organization/Planning/ClientsProfileOrganizationPlanningComponent.vue';
import ClientsProfileOrganizationDocumentsComponent from './Organization/Documents/ClientsProfileOrganizationDocumentsComponent.vue'
import ClientsProfileContactsAcordionComponent from './Contact/ClientsProfileContactsAcordionComponent.vue'
import ClientsProfileOrganizationBlockedPersonComponent from './Organization/BlockedPerson/ClientsProfileOrganizationBlockedPersonComponent.vue';
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
      ClientProfileOrganizationFinancialsCreditCheckComponent,
      AppCollapse,
      AppCollapseItem,
      ClientsProfileOrganizationStatusBarComponent,
      ClientsProfileOrganizationInfoComponent,
      ClientsProfileOrganizationActionPointsComponent, 
      ClientsProfileContactsAcordionComponent,

      //Right sidebar
      IssuesProfileComponent,
      NotesComponent,

      //Tabs
      ClientsProfileOrganizationContractComponent,
      ClientsProfileOrganizationSalesComponent,
      ClientsProfileOrganizationPlacementsComponent,

      //Collapse
      ClientsProfileOrganizationFinancialsComponent,
      ClientsProfileOrganizationChecklistComponent,
      ClientsProfileOrganizationDataComponent,
      ClientsProfileOrganizationRegistrationComponent,
      ClientsProfileOrganizationContactsComponent,
      ClientsProfileOrganizationStructureComponent,
      ClientsProfileOrganizationScaleComponent,
      ClientsProfileOrganizationPlanningComponent,
      ClientsProfileOrganizationDocumentsComponent,
      ClientsProfileOrganizationBlockedPersonComponent,
    },

    created() {
      this.getData();
    },

    watch: {
        "profile.client_data_refresh": {
              handler: function(val) {
                  if (val > 0) {
                      this.getPersonData();
                  }                
              },
          },
    },
    
    data(){
        return {
                id_client_data_main: this.$route.params.id_client_data_main,
                profile: null,
                activeTab: 0,
                alert_class: new AppAlerts(),
                first_release: JSON.parse(localStorage.getItem('user')).first_release,

            }

    },

    methods: {
      
      getData(){
        axios
          .get("clients/getProfile/" + this.id_client_data_main)
          .then((res) => {
                this.profile = res.data;
                this.$route.meta.client_type = this.profile.client_data.register_status;
          })
          .catch((error) => {
              console.error(`error during request: ${error}`);
          });
      },

      getPersonData(){
        
        axios
          .get("clients/getPersonData/" + this.id_client_data_main)
          .then((res) => {
              if(res.data.length > 0){
                this.profile.client_data = res.data[0];  
              } 
          })
          .catch((error) => {
              console.error(`error during request: ${error}`);
          });
      },

      changeTab(e){
        if (this.activeTab !== e) {
            this.activeTab = e;
            if(e == 0){//Locations
                this.profile.structure_locations.items.refresh ++;
            }else if(e == 1){//Contact persons
                this.profile.contract.items.refresh ++;
            }else if(e == 2){//Contact persons
                this.profile.placements.items.refresh ++;
            }
        }
      },

      //showLoading: function() {
      //  this.loading = !this.loading;
      //},

      changeStatusToSuspect(){
        this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {
                if(res == true){
                    this.loading = true;
                    axios
                        .post('clients/profile/status/changeStatusToSuspect',{
                            id_client_data_main: this.profile.id,
                        })
                        .then((res) => {
                            this.profile.client_data = res.data
                        })
                        .catch((error) => {
                            console.error(`error ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;
                        })

                }
            });
      },


      /*scrollToDocument: function(val){
        
        this.openCollapseDocument = !this.openCollapseDocument;
        this.refreshPersonalDocumentsWithType = val;

        setTimeout(() => {
            
              document.getElementById('footer').scrollIntoView({
                                                                  behavior: "smooth",
                                                                  block: "start"
                                                              });

                      }, 800);

      }, */


    },

    mounted() {
      
      this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
        
        if (collapseId == 'collapse-documents' && isJustShown == true){        
            this.profile.documents.items.refresh++;
        }
        if (collapseId == 'collapse-contacts' && isJustShown == true){       
            this.profile.contact_persons.items.refresh++;
        } 
        if (collapseId == 'collapse-excluded-flexworkers' && isJustShown == true){       
            this.profile.blocked_person.items.refresh++;
        } 

      }); 

    }
  
};
</script>
<style>
  .bg-light{
    background-color: white !important;
  }
</style>