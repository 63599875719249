<template> 
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop >
        
        <ValidationObserver ref="form" >

            <app-divider-modal :text="trans('employment-agency',190)" />

            <app-row-left-label v-if="cArgs.type == 'accordion'"  :label="trans('cp-issued-limit',192)">
                <app-input v-model="cArgs.credit_limit_uzk" :key="refresh_index" validatorName="Afgegeven UZK limiet" type="decimal_07_places_02" :validatorRules="cArgs.user_role <= 2 ? 'required' : ''" :disabled="cArgs.user_role > 2"/>
            </app-row-left-label> 

            <app-row-left-label :label="trans('cp-requested-limit',192)">
                <app-input v-model="cArgs.requested_limit_uzk" :key="refresh_index" validatorName="Aangevraagd UZK limiet" validatorRules="required" type="decimal_07_places_02" />
            </app-row-left-label>

            <app-row-left-label :label="trans('cp-expected-number-of-employees',190)">
                <app-input v-model="cArgs.expected_flex_workers_uzk" validatorName="Verwacht UZK aantal medewerkers" type="integer_03" validatorRules="required" />
            </app-row-left-label>

            <app-divider-modal :text="trans('bemiddeling',190)" :isLine="true"/>

            <app-row-left-label v-if="cArgs.type == 'accordion'"  :label="trans('cp-issued-limit',192)">
                <app-input v-model="cArgs.credit_limit_zzp" :key="refresh_index" validatorName="Afgegeven ZZP limiet" type="decimal_07_places_02" :validatorRules="cArgs.user_role <= 2 ? 'required' : ''" :disabled="cArgs.user_role > 2"/>
            </app-row-left-label> 

            <app-row-left-label :label="trans('cp-requested-limit',192)">
                <app-input v-model="cArgs.requested_limit_zzp" :key="refresh_index" validatorName="Aangevraagd ZZP limiet" validatorRules="required" type="decimal_07_places_02" />
            </app-row-left-label>

            <app-row-left-label :label="trans('cp-expected-number-of-employees',190)">
                <app-input v-model="cArgs.expected_flex_workers_zzp" validatorName="Verwacht ZZP aantal medewerkers" type="integer_03" validatorRules="required" />
            </app-row-left-label>

        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="financialsChange" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>

        <template #modal-title>
            <div class="d-flex align-items-center">{{trans('cp-credit-check',192)}} <img tabindex="0" src="@/assets/images/icons/info-button.svg" id="popover-credit-change-additional" height="18px" style="vertical-align: text-top;" class="app-cursor-pointer ml-2" @click="showPopover" /></div>
        </template>

        <b-popover v-if="popover_show" :show="popover_show" :target="'popover-credit-change-additional'" triggers="focus" no-fade custom-class="app-local-popover-width">
            <template #title>
                
            </template>
            <span v-html="trans('cp-credit-check-additional-text',192)"></span>
        </b-popover>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";


export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "return","args"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }, 
        },
         cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }, 
        },
    },

    data() {
        return {
            loading: false,
            refresh_index: 0,
            popover_show: false
        };
    },

    methods: {

        financialsChange() {
            this.$refs.form.validate().then((result) => {
                this.refresh_index++;
                if (!result) {
                    return false;
                }else{
                     this.loading = true;

                     if(this.cArgs.type == 'accordion' && this.cArgs.credit_limit_uzk != null && this.cArgs.credit_limit_zzp != null){
                        this.cArgs.credit_limit_uzk = this.cArgs.credit_limit_uzk.toString().replaceAll('.','');
                        this.cArgs.credit_limit_zzp = this.cArgs.credit_limit_zzp.toString().replaceAll('.','');

                        this.cArgs.credit_limit_uzk = this.cArgs.credit_limit_uzk.toString().replaceAll(',','.');
                        this.cArgs.credit_limit_zzp = this.cArgs.credit_limit_zzp.toString().replaceAll(',','.');
                     }

                     this.cArgs.requested_limit_uzk = this.cArgs.requested_limit_uzk.toString().replaceAll('.','');
                     this.cArgs.requested_limit_zzp = this.cArgs.requested_limit_zzp.toString().replaceAll('.','');

                     this.cArgs.requested_limit_uzk = this.cArgs.requested_limit_uzk.toString().replaceAll(',','.');
                     this.cArgs.requested_limit_zzp = this.cArgs.requested_limit_zzp.toString().replaceAll(',','.');

                axios 
                    .post("clients/profile/financials/creditCheckChange", {
                        id_client_data_main: this.cArgs.id_client_data_main,
                        client_credit_data: JSON.stringify(this.cArgs),
                    })
                    .then((res) => {
                        this.cReturn.client_data = res.data[0];
                        this.$emit("update:show", false);
                        this.$emit("update:result", 1);
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                        this.loading = false;
                    }); 
                    }
            })
                
        },

        showPopover(){
            this.popover_show = true;
        },

        closeModal() {
            this.$emit("update:show", false);
        },
    },
};
</script>
<style>
.app-local-popover-width{
    max-width: 400px !important;
}
</style>

