<template>
    <div>
        <b-overlay :show="loading_list" :opacity="0.0">

            <app-view-table :isTitle="false" :loading="loading_list" :isTitleTotal="false" v-on:onRowClicked=" item=>$set(item, item.id_document_const_type == 38 &&  item.token_status_id != 0 ? '_showDetails': '', !item._showDetails)" :filters.sync="filters" :customColumns="custom_columns" :items="cProfile.documents.items" :fields="fields" :loadingInAccordion="true">
                
                <template v-slot:menu-items="cell">
                        <!-- <b-dropdown-item v-if="cell.data.item.id_document_const_type == 38" @click="chooseContact(cell.data.item)">Stuur om te ondertekenen</b-dropdown-item> -->
                        <b-dropdown-item @click="openDocument(cell.data.item)">{{trans('show-document',3)}}</b-dropdown-item>
                        <b-dropdown-item v-if="cell.data.item.portal_publish == '0' && cell.data.item.id_document_const_type != 38 && (user_role < 3 || profile.can_user_edit)" @click="publishDocument(cell.data.item, 1)" >Publiceren</b-dropdown-item>
                        <b-dropdown-item v-if="cell.data.item.portal_publish == '1' && cell.data.item.id_document_const_type != 38 && (user_role < 3 || profile.can_user_edit)" @click="publishDocument(cell.data.item, 0)" >Niet publiceren</b-dropdown-item>
                        <b-dropdown-item v-if="cell.data.item.token_status_id == 0 && cell.data.item.id_document_const_type == 38 && (user_role < 3 || profile.can_user_edit)" @click="submitForm(cell.data.item)" >Verzenden ter ondertekening</b-dropdown-item>
                        <b-dropdown-item v-if="cell.data.item.id_document_const_type == 38 && user_role < 3 || profile.can_user_edit" @click="deleteDocument(cell.data.item)" >{{trans('remove',175)}}</b-dropdown-item>
                        <b-dropdown-item v-if="cell.data.item.id_document_const_type == 38 && (cell.data.item.token_status_id == 27 || cell.data.item.token_status == 28) && (user_role < 3 || profile.can_user_edit)" @click="resendDocument(cell.data.item)" >{{trans('resend-form',175)}}</b-dropdown-item>
                </template>


                 <template v-slot:row-details="row">
                        <b-row class="app-card-header">
                            
                            <b-col start>
                                <p v-if="row.data.item.contact_name != null && row.data.item.contact_name != ''" class="col-list-label">{{trans('contacts',168)}}</p>
                                <p v-if="row.data.item.sign_information != undefined" class="col-list-label">Datum ondertekening</p>
                            </b-col>
                            <b-col>
                                <p v-if="row.data.item.contact_name != null && row.data.item.contact_name != ''" class="col-form-value"> {{row.data.item.contact_name}} </p>
                                <p v-if="row.data.item.sign_information != undefined" class="col-form-value"> {{JSON.parse(row.data.item.sign_information).sign_date}} </p>
                            </b-col>                                    
                        </b-row>
                    </template>

                 <template v-slot:buttons v-if="profile.client_data.id_status_const_type >= 6 && (user_role < 3 || profile.can_user_edit)"><!--Contract versturen-->
                        <app-button type="primary"   @click="addItemStep1()">
                            <span>Document toevoegen</span>
                        </app-button>
                </template>

                
                <template v-slot:status="cell">
                    <div   style="" class="">
                        <button v-if="cell.data.item.token_status != null && cell.data.item.id_document_const_type == 38" :id="'popover-id-errors'+cell.data.item.id" tabindex="0" class="app-local-popover-icon"><b-icon icon="exclamation-circle-fill" class="app-local-info" ></b-icon></button>
                        <b-popover v-if="cell.data.item.token_status != null && cell.data.item.id_document_const_type == 38" :target="'popover-id-errors'+cell.data.item.id" triggers="focus" no-fade custom-class="app-local-popover">
                            <template #title>
                                Document status
                            </template>
                            {{cell.data.item.token_status}}
                        </b-popover>
                    </div>
                </template>

            </app-view-table>
            
            <template #overlay>
                    <div></div>
            </template>
        </b-overlay>

        <client-profile-organization-documents-add-step1 v-if="mStep1.show" :show.sync="mStep1.show" :result.sync="mStep1.result" :args.sync="mStep1.args" :return.sync="mStep1.return" />
        <client-profile-organization-documents-add-step2 v-if="mStep2.show" :show.sync="mStep2.show" :result.sync="mStep2.result" :args.sync="mStep2.args" :return.sync="profile" />
        <clients-profile-organization-contact-choose v-if="mContactChoose.show" :show.sync="mContactChoose.show" singleSelect :result.sync="mContactChoose.result" :args.sync="mContactChoose.args" :return.sync="id_contact_data_main" @change="sendToSign" /> 
    </div>
</template>
<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import ClientProfileOrganizationDocumentsAddStep1 from "./ClientProfileOrganizationDocumentsAddStep1.vue";
import ClientProfileOrganizationDocumentsAddStep2 from "./ClientProfileOrganizationDocumentsAddStep2.vue";
import ClientsProfileOrganizationContactChoose from '../../Contact/ClientsProfileOrganizationContactChoose.vue';

export default {

    components: {
        ClientProfileOrganizationDocumentsAddStep1,
        ClientProfileOrganizationDocumentsAddStep2,
        ClientsProfileOrganizationContactChoose,
    },
    props: ["profile"],

    mounted(){
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    data(){
        return {
            alert_class: new AppAlerts(),
            loading_list: false,
            filters: {
                sorting: {
                        field_name: '',
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {                        
                    types: {type: 'list', changed: 0, values: [], function: "getCurrentDocumentTypes", parameters: {id_client_data_main: this.profile.id}}
                },
                page: 1,
                per_page: 5,
                search: "",
                changed: 0
            },


            mStep1: {show: false, result: 0, return: null},
            mStep2: {show: false, args: {id_document_const_type: 0, id_client_data_main: 0 , filters: null, return_type: null}, result: 0, return: null},
            mChooseContact: {show: false, args: {id_document_const_type: null, id_client_data_main: null, button_name: 'Send' }, result: 0, return: null},

            fields: [
                { key: "document_type_name", label: this.trans('document-type',182), thStyle: { "width": "100%" },visible: true, filtering: true, filtering_name: "types" },
                { key: "entry_date", label: this.trans('entry-date-on',182), thStyle: { "min-width": "190px", "max-width": "190px" },visible: true },
                { key: "portal_publish_text", label: this.trans('portal',182), thStyle: {  "min-width": "100px", "max-width": "100px" }, tdClass:"text-truncate",visible: true },
                { key: "status", label: "", thStyle: { "min-width": "38px", "max-width": "38px" },visible:true, filtering: false },
            ],
            custom_columns: ['status'],
            id_contact_data_main: null,

            mContactChoose: {show: false, return: this.profile, args: {
                    contacts: null,
                    id_client_data_main:null,
                    id_document_data_main: null,
                    filters: null
                }
            },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods: {

        getList(){

            this.loading_list = true;

            axios
                .post('clients/profile/documents/getList', {
                    id_client_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters)
                })
                .then((res) => {
                    this.cProfile.documents.items = res.data.items;
                })
                .catch((error) => { 
                    console.error(`error: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                })

        },

        submitForm(data){
                
                this.mContactChoose.args.contacts = this.profile.contact;
                this.mContactChoose.args.id_client_data_main = this.profile.id;
                this.mContactChoose.args.id_document_data_main = data.id;
                this.mContactChoose.args.filters = this.filters;
                this.mContactChoose.show = true;

        },

        openDocument(data){
            this.loading_list = true;
            axios
                .post("clients/profile/documents/downloadFile", {
                    id_document_data_main: data.id,
                    id_client_data_main: this.profile.id,
                }, 
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {

                    const url = window.URL.createObjectURL(new Blob([res.data], {type : data.mine}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target= "_blank";
                     data.mine == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                     data.mine == 'application/msword' ||
                     data.mine == 'text/plain'
                                     ? link.setAttribute('download', data.document_name):link.setAttribute('open', data.document_name); 
                    link.setAttribute('open', data.document_name); 
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    //console.error(error);
                    this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                })
                .finally(() => {
                   this.loading_list = false;
                });
        },

        publishDocument(data, portal_publish){

            this.alert_class.openAlertConfirmation(
                portal_publish == 1 ? "Weet u zeker dat u het document <b>" + data.document_type_name + "</b> wilt publiceren?" : 
                "Weet u zeker dat u het document <b>" + data.document_type_name + "</b> niet wilt publiceren?"
                ).then(res => {
                if(res == true) {

                this.loading_list = true;
                
                axios
                    .post("clients/profile/documents/publish", {
                        
                        id_document_data_main: data.id,
                        portal_publish: portal_publish,
                        id_client_data_main: this.profile.id,
                        filters: JSON.stringify(this.filters),
                    })
                    .then((res) => {
                        this.cProfile.documents.items = res.data.items;
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.loading_list = false;
                    });
            }});
        },

        deleteDocument(item){
  
                this.alert_class.openAlertConfirmation("Weet u zeker dat u dit document wilt verwijderen?").then(res => {
                    if(res == true){
                        this.loading_list = true;
                        axios
                            .post("clients/profile/documents/delete", {
                                filters: JSON.stringify(this.filters),
                                id_document_data_main: item.id,
                                id_client_data_main: this.profile.id
                            })
                            .then((res) => {
                                this.cProfile.documents.items = res.data.items;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            }).finally(() => {
                                this.loading_list = false;
                            });;
                    }     
                }); 
        },

        
        resendDocument(item){
  
                this.alert_class.openAlertConfirmation("Weet u zeker dat u het document opnieuw wilt verzenden naar <b>"+  item.contact_name +"</b> ?").then(res => {
                    if(res == true){
                        this.loading_list = true;
                        axios
                            .post("clients/profile/documents/resend", {
                                filters: JSON.stringify(this.filters),
                                id_document_data_main: item.id,
                                id_client_data_main: this.profile.id,
                                id_contact_data_main: item.id_contact_data_main
                            })
                            .then((res) => {
                                this.cProfile.documents.items = res.data.items;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            }).finally(() => {
                                this.loading_list = false;
                            });;
                    }     
                }); 
        },

        chooseContact(item){
            this.mChooseContact.args.id_document_data_main = item.id;
            this.mChooseContact.args.id_client_data_main = this.profile.id;

            this.mChooseContact.show = true;
        },



        sendToSign(){
             this.loading_list = true;
             axios 
                .post("clients/profile/documents/sendDocumentToSign", {
                    id_document_data_main: this.mContactChoose.args.id_document_data_main,
                    id_client_data_main: this.mContactChoose.args.id_client_data_main,
                    id_contact_data_main: this.id_contact_data_main,
                    filters : JSON.stringify(this.mContactChoose.args.filters)
                })
                .then((res) => {
                       this.cProfile.documents.items = res.data.items;
                       this.mContactChoose.show = false;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                    this.$emit("update:show", false);
                });

            
        },

        onCreateThis(){

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('profile.documents.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('mStep1.return', function(newVal, oldVal) {
                
                if (newVal > 0) {
                        this.addItemStep2(newVal);
                }  
            });

        },
        
        addItemStep1() {
            this.mStep1.return = 0;
            this.mStep1.show = true;
        },

        addItemStep2(id_document_const_type) {
            this.mStep2.args.id_document_const_type = id_document_const_type;
            this.mStep2.args.id_client_data_main = this.profile.id;
            this.mStep2.args.filters = this.filters; 
            this.mStep2.args.return_type = "documents";            
            this.mStep2.show = true;

        },
    }


    
}
</script>

<style scoped>

.app-local-popover-icon{
    padding: 0px;
    margin: 0px;
    background-color: inherit;
    border: none;
}

.app-local-info{
    color: #066791;
}
</style>