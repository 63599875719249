<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('register',175)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                <app-row-left-label :label="trans('portal-role',182)" >
                    <app-radio-group v-model="account_type" :options="opt" validatorName="role" validatorRules="required" :validatorCustomMessage="{ required: trans('required-message',180) }"/>
                </app-row-left-label>


            </b-form>
        </ValidationObserver>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="register()" :loading="loading">
               {{trans('register',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppRadioGroup from '@core/components/AppRadioGroup.vue';
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    components: {
        ValidationObserver,
        AppRadioGroup,
    },

    props: ["show", "args", "return", "result"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },

        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }
        },
        
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },


    data() {
        return {
            loading: false,
            account_type: null,
            opt: [ { value: 5, text: this.trans('admin',3) }, { value: 6, text: this.trans('fp-user',172) }],
            alert_class: new AppAlerts(),
        };
    },

    methods: {

        register() {
            
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }else{
                    
                        this.loading = true;
                        axios
                            .post("settings/users/registerContactPortal", {
                                id_contact_data_main: this.cArgs.id_contact_data_main,
                                id_client_data_main: this.cArgs.id_client_data_main,
                                filters: JSON.stringify(this.cArgs.filters),
                                register_status: this.args.register_status,
                                role: this.account_type
                            })
                            .then((res) => {
                                if(res.data != -1){
                                    this.cReturn = res.data.items;
                                    
                                } else {
                                    this.alert_class.openAlertWarning(this.trans('fp-email-exists', 177)).then(() =>{});
                                }
                                this.$emit('update:show', false)
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            }).finally(() => {
                                this.loading = false;
                        });
                    }
            });
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>


