<template>
    <div>
        <b-row v-if="profile.client_data.id_status_const_type > 3 && (user_role < 3 || profile.can_user_edit)" class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right>
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                 <b-dropdown-item @click="change()">{{trans('change',175)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

            <b-row>
                <b-col cols="6" md="8" start>
                    <p class="col-list-label">{{trans('possession-of-valid-big-registration',195)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value text-nowrap">{{profile.client_data.question_1 != null ? (profile.client_data.question_1 == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" md="8" start>
                    <p class="col-list-label">{{trans('waadi-point-two-additional',195)}}</p>
                    <li class= "col-list-label ml-4" v-if="profile.client_data.question_2.q1.value == 1"> {{trans('waadi-point-two-additional-a1',195)}} </li>
                    <li class= "col-list-label ml-4" v-if="profile.client_data.question_2.q2.value == 1"> {{trans('waadi-point-two-additional-a2',195)}} </li>
                    <li class= "col-list-label ml-4" v-if="profile.client_data.question_2.q3.value == 1"> {{trans('waadi-point-two-additional-a3',195)}} </li>
                    <li class= "col-list-label ml-4" v-if="profile.client_data.question_2.q4.value == 1"> {{trans('waadi-point-two-additional-a4',195)}} </li>
                    <li class= "col-list-label ml-4 mb-2" v-if="profile.client_data.question_2.q5.value == 1"> {{trans('waadi-point-two-additional-a5',195)}} <br /><b style="margin-left: 17px;">{{profile.client_data.question_2.q5.text}} </b></li> 
                    <p class="col-form-value ml-3 mt-1 mb-2">{{trans('waadi-point-two-additional-a6',195)}}<br /> <b class="ml-2">{{profile.client_data.question_2.q6.text}}</b></p> 
                </b-col>
                
            </b-row>


            <!-- <b-row>
                <b-col cols="6" md="8" start>
                    <p class="col-list-label">{{trans('waadi-point-3',195)}}</p>
                    <ul class="my-0">
                    <li class= "col-list-label ml-4" v-if="profile.client_data.question_3.q1.value == 1">
                        <b-row> 
                            <b-col cols="6" md="7" start>
                                <p class="col-list-label">{{trans('waadi-point-3-gloves',195)}}</p>
                            </b-col>
                            <b-col cols="6" md="5">
                                <p class="col-form-value text-nowrap">{{trans('waadi-point-3-client',195)}} {{profile.client_data.question_3.q1.value ? (profile.client_data.question_3.q1.option == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                            </b-col>
                        </b-row>
                    </li>

                    <li class= "col-list-label ml-4" v-if="profile.client_data.question_3.q2.value == 1">
                        <b-row> 
                            <b-col cols="6" md="7" start>
                                <p class="col-list-label">{{trans('waadi-point-3-mask',195)}}</p>
                            </b-col>
                            <b-col cols="6" md="5">
                                <p class="col-form-value text-nowrap">{{trans('waadi-point-3-client',195)}} {{profile.client_data.question_3.q2.value ? (profile.client_data.question_3.q2.option == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                            </b-col>
                        </b-row>
                    </li>

                    <li class= "col-list-label ml-4" v-if="profile.client_data.question_3.q3.value == 1">
                        <b-row> 
                            <b-col cols="6" md="7" start>
                                <p class="col-list-label">{{trans('waadi-point-3-shoes',195)}}</p>
                            </b-col>
                            <b-col cols="6" md="5">
                                <p class="col-form-value text-nowrap">{{trans('waadi-point-3-client',195)}} {{profile.client_data.question_3.q3.value ?(profile.client_data.question_3.q3.option == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                            </b-col>
                        </b-row>
                    </li>

                    <li class= "col-list-label ml-4" v-if="profile.client_data.question_3.q4.value == 1">
                        <b-row> 
                           <b-col cols="6" md="7" start>
                                <p class="col-list-label">{{trans('waadi-point-3-protect',195)}}</p>
                            </b-col>
                            <b-col cols="6" md="5">
                                <p class="col-form-value text-nowrap">{{trans('waadi-point-3-client',195)}} {{profile.client_data.question_3.q4.value ? (profile.client_data.question_3.q4.option == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                            </b-col>
                        </b-row>
                    </li>

                    <li class= "col-list-label ml-4" v-if="profile.client_data.question_3.q5.value == 1">
                        <b-row> 
                            <b-col cols="6" md="7" start>
                                <p class="col-list-label">{{trans('waadi-point-3-other',195)}} <br /><b>{{profile.client_data.question_3.q5.text }}</b></p>
                            </b-col>
                            <b-col cols="6" md="5">
                                <p class="col-form-value text-nowrap">{{trans('waadi-point-3-client',195)}} {{profile.client_data.question_3.q5.value ? (profile.client_data.question_3.q5.option == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                            </b-col>
                        </b-row>
                    </li>
                    </ul>
                </b-col>
                
            </b-row> -->

            
            <b-row >
                <b-col cols="6" md="8" start>
                    <p class="col-list-label">{{trans('waadi-point-3',195)}}</p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" md="8" start>
                    <ul class="my-0 mb-1 pl-3">
                        <li class="col-list-label ml-4" v-if="profile.client_data.question_3.q1.value == 1"><div class="d-flex justify-content-between"><span>{{trans('waadi-point-3-gloves',195)}}</span><span>{{trans('waadi-point-3-client',195)}}</span></div></li>
                        <li class="col-list-label ml-4" v-if="profile.client_data.question_3.q2.value == 1"><div class="d-flex justify-content-between"><span>{{trans('waadi-point-3-mask',195)}}</span><span>{{trans('waadi-point-3-client',195)}}</span></div></li>
                        <li class="col-list-label ml-4" v-if="profile.client_data.question_3.q3.value == 1"><div class="d-flex justify-content-between"><span>{{trans('waadi-point-3-shoes',195)}}</span><span>{{trans('waadi-point-3-client',195)}}</span></div></li>
                        <li class="col-list-label ml-4" v-if="profile.client_data.question_3.q4.value == 1"><div class="d-flex justify-content-between"><span>{{trans('waadi-point-3-protect',195)}}</span><span>{{trans('waadi-point-3-client',195)}}</span></div></li>
                        <li class="col-list-label ml-4 pb-0" v-if="profile.client_data.question_3.q5.value == 1"><div class="d-flex justify-content-between"><span>{{trans('waadi-point-3-other',195)}}</span><span>{{trans('waadi-point-3-client',195)}}</span></div></li>
                    </ul>
                    <p v-if="profile.client_data.question_3.q5.value == 1" class="col-form-value pt-0 mb-2" style="margin-left: 2.5rem;"><b>{{profile.client_data.question_3.q5.text }}</b></p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value text-nowrap" v-if="profile.client_data.question_3.q1.value == 1">{{profile.client_data.question_3.q1.value ? (profile.client_data.question_3.q2.option == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                    <p class="col-form-value text-nowrap" v-if="profile.client_data.question_3.q2.value == 1">{{profile.client_data.question_3.q2.value ? (profile.client_data.question_3.q2.option == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                    <p class="col-form-value text-nowrap" v-if="profile.client_data.question_3.q3.value == 1">{{profile.client_data.question_3.q3.value ? (profile.client_data.question_3.q2.option == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                    <p class="col-form-value text-nowrap" v-if="profile.client_data.question_3.q4.value == 1">{{profile.client_data.question_3.q4.value ? (profile.client_data.question_3.q2.option == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                    <p class="col-form-value text-nowrap" v-if="profile.client_data.question_3.q5.value == 1">{{profile.client_data.question_3.q5.value ? (profile.client_data.question_3.q2.option == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" md="8" start>
                    <p class="col-list-label">{{trans('exposed-to-biological-agents-info',191)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value text-nowrap">{{profile.client_data.question_4 != null ? (profile.client_data.question_4 == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" md="8" start>
                    <p class="col-list-label">{{trans('vaccination-against-hepatitis-b-info',191)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value text-nowrap">{{profile.client_data.question_5 != null ? (profile.client_data.question_5 == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" md="8" start>
                    <p class="col-list-label">{{trans('required-vaccination-from-client',191)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value text-nowrap">{{profile.client_data.question_6 != null ? (profile.client_data.question_6 == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" md="8" start>
                    <p class="col-list-label">{{trans('exposed-to-cytostatics-or-anesthetic-gases',191)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value text-nowrap">{{profile.client_data.question_7 != null ? (profile.client_data.question_7 == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                </b-col>
            </b-row>


            <b-row>
                <b-col cols="6" md="8" start>
                    <p class="col-list-label">{{trans('waadi-point-5-additional',195)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value text-nowrap">{{profile.client_data.question_8 != null ? (profile.client_data.question_8 == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" md="8" start>
                    <p class="col-list-label">{{trans('deal-with-moments',191)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value text-nowrap">{{profile.client_data.question_9 != null ? (profile.client_data.question_9 == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" md="8" start>
                    <p class="col-list-label">{{trans('other-risks-that-havent-mentioned',191)}}</p>
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value text-nowrap">{{profile.client_data.question_10 != null ? (profile.client_data.question_10 == 1 ) ?  trans('yes-option',3) : trans('no-option',3) : '-'}}</p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" md="8" start>
                    <p class="col-list-label">{{trans('description-of-risks',190)}}</p>
                    <p class="col-form-value pt-0 ml-2"><b>{{profile.client_data.question_11  ? profile.client_data.question_11: '-'}}</b></p>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="6" md="8" start>
                    
                </b-col>
            </b-row>

            <hr class="app-table-hr-separator" />

            <b-row class="app-card-header">
                <b-col cols="6" md="8" start>
                    
                    <p class="col-list-label">{{trans('cp-form-completed-by',190)}}</p>
                    <p class="col-list-label">{{trans('function',3)}}</p>
                    <p class="col-list-label">{{trans('cp-checklist-completed-on',192)}}</p>
                    
                </b-col>
                <b-col cols="6" md="4">
                    <p class="col-form-value text-nowrap">{{profile.client_data.completed ? profile.client_data.completed : '-'}}</p>
                    <p class="col-form-value text-nowrap">{{profile.client_data.functions ? profile.client_data.functions : '-'}}</p>
                    <p class="col-form-value text-nowrap">{{profile.client_data.check_list_date ? profile.client_data.check_list_date : '-'}}</p>
                </b-col>
            </b-row>
                             
        <clients-profile-organization-checklist-change v-if="mCheckListChange.show" :show.sync="mCheckListChange.show" :result.sync="mCheckListChange.result" :args.sync="mCheckListChange.args" :return.sync="profile"/>
    </div>
</template>
<script>

import ClientsProfileOrganizationChecklistChange from './ClientsProfileOrganizationChecklistChange.vue';

export default {
    components: {
        ClientsProfileOrganizationChecklistChange
    },

    props:["profile"],

    data(){
        return{
              mCheckListChange: { show: false, args: {
                idClientDataMain: null,
                question_1: null,
                question_2: null,
                question_3: null,
                question_4: null,
                question_5: null,
                question_5: null,
                question_6: null,
                question_7: null,
                question_8: null,
                question_9: null,
                question_10: null,
                question_11: null,
                completed: null,
                functions: null
            }, result: 0, return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods:{       
         change(){
            this.mCheckListChange.args.idClientDataMain = this.profile.id;
            this.mCheckListChange.args.question_1 = this.profile.client_data.question_1;
            this.mCheckListChange.args.question_2 = JSON.parse(JSON.stringify(this.profile.client_data.question_2));
            this.mCheckListChange.args.question_3 = JSON.parse(JSON.stringify(this.profile.client_data.question_3));
            this.mCheckListChange.args.question_4 = this.profile.client_data.question_4;
            this.mCheckListChange.args.question_5 = this.profile.client_data.question_5;
            this.mCheckListChange.args.question_6 = this.profile.client_data.question_6;
            this.mCheckListChange.args.question_7 = this.profile.client_data.question_7;
            this.mCheckListChange.args.question_8 = this.profile.client_data.question_8;
            this.mCheckListChange.args.question_9 = this.profile.client_data.question_9;
            this.mCheckListChange.args.question_10 = this.profile.client_data.question_10;
            this.mCheckListChange.args.question_11 = this.profile.client_data.question_11;
            this.mCheckListChange.args.completed = this.profile.client_data.completed;
            this.mCheckListChange.args.functions = this.profile.client_data.functions;
            this.mCheckListChange.show = true;
        }
    }
 
};
</script>