<template>
    <div>
            <b-row  v-if="(profile.client_data.id_status_const_type > 3 || profile.client_data.register_status == 0) && (user_role < 3 || profile.can_user_edit)" class="app-component-icon-edit">
                <b-dropdown variant="link"  no-caret right>
                    <template #button-content>
                        <b-icon icon="three-dots-vertical" class="text-body "></b-icon>
                    </template>
                    <b-dropdown-item @click="change()">{{trans('change',175)}}</b-dropdown-item>                    
                </b-dropdown>
            </b-row>
            <b-row>
                 <b-col start>
                    <p class="col-list-label">{{trans('cp-pnil-budget',192)}}</p>
                </b-col>
                <b-col class="mr-2">
                    <p v-for="(item,id) in budget_pnil" :key="id" class="col-form-value">{{item.year}} - {{item.value}}</p>
                    <p  v-if="budget_pnil != null && budget_pnil.length == 0" class="col-form-value"> - </p>
                </b-col>

            </b-row>

            <b-row class="app-card-header">
                <b-col start>
                    <p class="col-list-label">{{trans('cp-winst-kans',192)}}</p>
                    <p class="col-list-label">{{trans('cp-label',192)}}</p>
                    <p class="col-list-label">{{trans('cp-contract-expiration-date',192)}}</p>
                    <p class="col-list-label">{{trans('cp-collaboration-form',192)}}</p>
                    <p class="col-list-label">{{trans('cp-number-of-suppliers',192)}}</p>
                    <p class="col-list-label">{{trans('cp-competitors',192)}}</p>
                </b-col>

                <b-col class="mr-2">
                    <p class="col-form-value">{{profile.client_data.chance_winning ? profile.client_data.chance_winning + '%'  : '-'}}</p>
                    <p class="col-form-value">
                        <span v-if="profile.client_data.label_zzp == '1' || profile.client_data.label_uzk == '1' || profile.client_data.label_hms == '1'">
                            <span v-if="profile.client_data.label_zzp == '1'">ZZP</span>
                            <span v-if="profile.client_data.label_zzp == '1' && profile.client_data.label_uzk == '1'">, </span>
                            <span v-if="profile.client_data.label_uzk == '1'">UZK</span>
                            <span v-if="(profile.client_data.label_uzk == '1' && profile.client_data.label_hms == '1') || (profile.client_data.label_zzp == '1' && profile.client_data.label_hms == '1')">, </span>
                            <span v-if="profile.client_data.label_hms == '1'">HMS</span>
                        </span>
                        <span v-else>-</span>
                    </p>
                    <p class="col-form-value">{{profile.client_data.end_contract_date ? profile.client_data.end_contract_date : '-'}}</p>
                    <p class="col-form-value">{{profile.client_data.form_cooperation != null ? profile.client_data.form_cooperation_name : '-'}}</p>
                    <p class="col-form-value">{{profile.client_data.suppliers_number ? profile.client_data.suppliers_number : '-'}}</p>
                    <p class="col-form-value">{{profile.client_data.competitors ? profile.client_data.competitors : '-'}}</p>
                </b-col>

            </b-row>
        <clients-profile-organization-sales-change v-if="mSalesChange.show" :show.sync="mSalesChange.show" :args.sync="mSalesChange.args" :result.sync="mSalesChange.result" :return.sync="profile" />
    </div>
</template>
<script>

import ClientsProfileOrganizationSalesChange from './ClientsProfileOrganizationSalesChange.vue';

export default {
    props: ["profile"],
    components: {
        ClientsProfileOrganizationSalesChange,
    },

    created(){
        this.user_role = JSON.parse(localStorage.getItem('user')).id_user_const_role;
    },

    watch: {
      "profile.client_data.budget_pnil": {
            handler: function(val) {
                    this.budget_pnil = JSON.parse(this.profile.client_data.budget_pnil)               
            },
        },
  },
    
    data(){
        return{
            user_role: null,
            budget_pnil : JSON.parse(this.profile.client_data.budget_pnil),
            mSalesChange: { show: false, args: {
                id_client_data_main: null,
                budget_pnil: null,
                chance_winning: null,
                label_zzp: null,
                label_uzk: null,
                label_hms: null,
                end_contract_date: null,
                form_cooperation: null,
                suppliers_number: null,
                competitors: null
            }, result: 0, return: null},
        }
    },

    methods: {
        change(){
            this.mSalesChange.args.id_client_data_main = this.profile.client_data.id_client_data_main;
            this.mSalesChange.args.budget_pnil = JSON.parse(JSON.stringify(this.budget_pnil));
            this.mSalesChange.args.chance_winning = this.profile.client_data.chance_winning;
            this.mSalesChange.args.label_zzp = this.profile.client_data.label_zzp;
            this.mSalesChange.args.label_uzk = this.profile.client_data.label_uzk;
            this.mSalesChange.args.label_hms = this.profile.client_data.label_hms;
            this.mSalesChange.args.end_contract_date = this.profile.client_data.end_contract_date;
            this.mSalesChange.args.form_cooperation = this.profile.client_data.form_cooperation;
            this.mSalesChange.args.suppliers_number = this.profile.client_data.suppliers_number;
            this.mSalesChange.args.competitors = this.profile.client_data.competitors ? this.profile.client_data.competitors : '';
            this.mSalesChange.show = true;
        }
    }

}
</script>
<style>

</style>