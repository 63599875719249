<template>
    <div v-if="showActionPoints">
        <!-- Suspect -->
        <b-card class="mb-3" v-if="profile.client_data.register_status == 0 && profile.client_data.id_status_const_type == 1">
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3" class="mr-4"></b-icon>                        
                        </div>

                        <div class="w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('cp-action-suspect',198)}} </span>  <br/>  
                            <span class="text-small">{{trans('cp-quotation-negotiations',198)}}</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="nextStep()" :loading="loading" v-if="(userRole < 3 || profile.can_user_edit)">{{trans('cp-start-quotation-phase',189)}}</app-button>                        
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <!-- Zbieranie danhch -->
        <b-card class="mb-3" v-if="profile.client_data.id_status_const_type == 2">
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="person-lines-fill" font-scale="3" class="mr-4"></b-icon>                        
                        </div>

                        <div class="w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('cp-action-collect-data',198)}} </span>  <br/>  
                            <span class="text-small">{{trans('cp-enter-prospect-details',198)}}</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" v-if="profile.client_data.is_webform == 0 && (userRole < 3 || profile.can_user_edit)" @click="sendWebForm()" :loading="loading"> {{trans('cp-submit-registration-form',189)}} </app-button>
                        <app-button type="primary" :url="{ name: 'clients-prospect-wizard', params: { id_client_data_main: idClientDataMain }}" v-if="userRole < 3 || profile.can_user_edit">{{trans('cp-open-registration-form',189)}}</app-button>                        
                    </div>
                </div>
            </b-alert>           
        </b-card>

        <!-- Ofertie -->
        <b-card class="mb-3" v-if="profile.client_data.id_status_const_type == 3">
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="file-earmark-check-fill" font-scale="3"></b-icon>                      
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('trans-promotion-offer',198)}}</span>  <br/>  
                            <span class="text-small">{{trans('cp-conduct-quotation-and-send-registration-form',198)}}</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="sendWebForm()" :loading="loading" v-if="userRole < 3 || profile.can_user_edit"> {{trans('cp-submit-registration-form',189)}} </app-button>
                        <app-button type="primary" @click="nextStep()" v-if="userRole < 3 || profile.can_user_edit">{{trans('cp-no-registration-form',189)}}</app-button>
                    </div>
                </div>
            </b-alert> 
        </b-card>

        <!-- Credit check aanvragen-->
        <b-card class="mb-3" v-if="profile.client_data.id_status_const_type == 4">
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="file-earmark-check-fill" font-scale="3"></b-icon>                      
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('action',3)}} </span>  <br/>  
                            <span class="text-small">{{trans('cp-request-credit-check',198)}}</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="creditChange()" :loading="loading" v-if="userRole < 3 || profile.can_user_edit">{{trans('request-credit-check',175)}}</app-button>                  
                    </div>
                </div>
            </b-alert> 
        </b-card>

        <!-- Credit check -->
        <b-card class="mb-3" v-if="profile.client_data.id_status_const_type == 5">
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div class="d-flex align-items-center">
                        <div>
                            <b-icon icon="file-earmark-check-fill" font-scale="3"></b-icon>                      
                        </div>

                        <div class=" ml-3 w-100 align-items-center">           
                            <span class="align-middle font-weight-600 text-base">{{trans('action',3)}}</span>  <br/>  
                            <span class="text-small">{{trans('cp-enter-issued-credit-limit',175)}}</span>                                   
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" @click="creditLimitChange()" :loading="loading" v-if="(userRole == 2 || userRole == 1)">
                                   {{trans('cp-enter-credit-limit',198)}}
                        </app-button>                   
                    </div>
                </div>
            </b-alert> 
        </b-card>

        <!-- Generowanie umowy -->
         <b-card class="mb-3" v-if="profile.client_data.id_status_const_type == 6 && profile.client_data.register_status == 1">
            <b-alert variant="light" show class="mb-0">
                <div class="d-flex w-100 justify-content-between">
                    <div>
                        <div class="d-flex w-100 align-items-center">
                            <b-icon icon="file-earmark-check-fill" font-scale="3" class="mr-4"></b-icon>                                  
                            <span class="align-middle font-weight-600 text-base" v-if="profile.client_data.cancel_contract == 0">{{trans('create-a-contract',3)}}</span>      
                            <span class="align-middle font-weight-600 text-base" v-if="profile.client_data.cancel_contract == 1">{{trans('client-rejected-contract-proposal',180)}}</span>                                 
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <app-button type="primary" :url="{ name: 'clients-profile-contract-wizard', params: { id_client_data_main: idClientDataMain }}" v-if="userRole < 3 || profile.can_user_edit">{{trans('create-contract',175)}}</app-button>                            
                    </div>
                </div>
            </b-alert> 
        </b-card>
        
        <clients-profile-organization-financials-credit-check-change v-if="mActionPoints.show" :args="mActionPoints.args" :show.sync="mActionPoints.show" :return.sync="cProfile"/>
        <clients-profile-organization-contact-choose v-if="mContactChoose.show" :args="mContactChoose.args" :show.sync="mContactChoose.show" :return.sync="ids_contact" @change="changeStatus" />
        <clients-profile-organization-registration-change v-if="mRegistrationChange.show" :show.sync="mRegistrationChange.show" :result.sync="mRegistrationChange.result" :args.sync="mRegistrationChange.args" :return.sync="profile"/>

    </div>
</template>
<script>
import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import ClientsProfileOrganizationFinancialsCreditCheckChange from './Financials/ClientsProfileOrganizationFinancialsCreditCheckChange.vue'
import ClientsProfileOrganizationContactChoose from '../Contact/ClientsProfileOrganizationContactChoose.vue';
import ClientsProfileOrganizationRegistrationChange from "./Registration/ClientsProfileOrganizationRegistrationChange.vue";

export default {
    components: {
        ClientsProfileOrganizationFinancialsCreditCheckChange,
        ClientsProfileOrganizationContactChoose,
        ClientsProfileOrganizationRegistrationChange,
        
    },

    props:["profile"],

    created(){
        this.userRole = JSON.parse(localStorage.getItem('user')).id_user_const_role;
    },
    
    watch: {
        "mContractAddStep1.result": function(val) {
            if (val === 1) {
                this.mContractAddStep2.args.idDocumentDataVersion = this.mContractAddStep1.return.idDocumentDataVersion;
                this.mContractAddStep2.args.idDocumentConstType = this.mContractAddStep1.return.idDocumentConstType;
                this.mContractAddStep2.args.idPersonDataMain = this.profile.id;
                this.mContractAddStep2.show = true;
                this.mContractAddStep1.result = 0;
            }
        }
    },

    computed: {
        showActionPoints () {
            if (this.profile.client_data.id_status_const_type == 1 || this.profile.client_data.id_status_const_type == 2 || this.profile.client_data.id_status_const_type == 3 || this.profile.client_data.id_status_const_type == 4 || this.profile.client_data.id_status_const_type == 5 || this.profile.client_data.id_status_const_type == 6) {
                return true;
            } else {
                return false;
            }
        },
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }
        } 
    
    },

    data() {
        return {
            idClientDataMain: this.$route.params.id_client_data_main,
            alertClass: new AppAlerts(),
            loading: false,
            ids_contact: [],
            mActionPoints: {show: false, return: this.profile, args: {
                    expected_flex_workers: null,
                    requested_limit: null,
                    id_client_data_main:null,
                    credit_limit: null,
                    type: 'action_points'
                
                }
            },
            mContactChoose: {show: false, return: this.profile, args: {
                    contacts: null,
                    id_client_data_main:null,
                },
            },
            mRegistrationChange: { show: false, args: {
                id_client_data_main:null,
                account_manager:null,
                contact_person: null,
                location:null,
                only_branch: false,
            }, result: 0, return: null },
            userRole: null
        };
    },

    methods: {
        sendWebForm(){

            if(this.profile.contact.list.length > 0){//send webform
                
                this.mContactChoose.args.contacts = this.profile.contact;
                this.mContactChoose.args.id_client_data_main = this.profile.id;
                this.mContactChoose.show = true;

            }else{

                this.alertClass.openAlertWarning(this.trans('make-contract-first-error',191));

            }

        },

        creditChange(){
            this.mActionPoints.args.requested_limit_uzk = this.profile.client_data.requested_limit_uzk;
            this.mActionPoints.args.requested_limit_zzp = this.profile.client_data.requested_limit_zzp;
            this.mActionPoints.args.expected_flex_workers_uzk = this.profile.client_data.expected_flex_workers_uzk;
            this.mActionPoints.args.expected_flex_workers_zzp = this.profile.client_data.expected_flex_workers_zzp;
            this.mActionPoints.args.id_client_data_main = this.profile.id;
            this.mActionPoints.args.type = 'action_points';
            this.mActionPoints.show = true;
        },

        creditLimitChange(){
            this.mActionPoints.args.requested_limit_uzk = this.profile.client_data.requested_limit_uzk;
            this.mActionPoints.args.requested_limit_zzp = this.profile.client_data.requested_limit_zzp;
            this.mActionPoints.args.expected_flex_workers_uzk = this.profile.client_data.expected_flex_workers_uzk;
            this.mActionPoints.args.expected_flex_workers_zzp = this.profile.client_data.expected_flex_workers_zzp;
            this.mActionPoints.args.id_client_data_main = this.profile.id;
            this.mActionPoints.args.credit_limit_zzp = this.profile.client_data.credit_limit_zzp;
            this.mActionPoints.args.credit_limit_uzk = this.profile.client_data.credit_limit_uzk;
            this.mActionPoints.args.type = "accordion";
            this.mActionPoints.show = true;
        },

        changeStatus() {
             axios 
                .post("clients/profile/action-points/sendWebForm", {
                    id_client_data_main: this.mContactChoose.args.id_client_data_main,
                    contacts_ids: JSON.stringify(this.ids_contact)
                })
                .then((res) => {
                    if(res.data.length > 0){
                        this.cProfile.client_data = res.data[0];
                    }
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.mContactChoose.show = false;
                    this.$emit("update:show", false);
                });
        },

        change(){
            this.mRegistrationChange.args.id_client_data_main = this.profile.id;
            this.mRegistrationChange.args.account_manager = {name: this.profile.client_data.user_name, value: this.profile.client_data.id_user_manager};
            this.mRegistrationChange.args.contact_person = {name: this.profile.client_data.user_contact, value: this.profile.client_data.id_user_contact};
            this.mRegistrationChange.args.location = this.profile.client_data.id_office_data_location ? {name: this.profile.client_data.office_name, value: this.profile.client_data.id_office_data_location} : null;
            this.mRegistrationChange.show = true;
        },

        nextStep(){
            if(this.profile.client_data.id_office_data_location === null){
                this.change();
                return;
            }

            this.loading = true;

            axios 
                        .post("clients/profile/action-points/nextStep", {
                            id_client_data_main: this.idClientDataMain
                        })
                        .then((res) => {
                            if(res.data.length > 0){
                                this.cProfile.client_data = res.data[0];
                            }
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                            this.loading = false;
                        }); 
        }
       
    }
 
};
</script>

<style>

.dotActive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #12B76A;
  border-radius: 50%;
  display: inline-block;
}

.dotInactive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #D92D20;
  border-radius: 50%;
  display: inline-block;
}

.dotProgress {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #FEC84B;
  border-radius: 50%;
  display: inline-block;
}

.checkbox-green .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #12B76A !important;
}

.app-action-status-red-text{
    font-size: 12px;
    font-weight: 600;
    color: #F04438 !important;
}


</style>