import { render, staticRenderFns } from "./ClientsProfileOrganizationBlockedPersonAdd.vue?vue&type=template&id=375fa2d2&scoped=true&"
import script from "./ClientsProfileOrganizationBlockedPersonAdd.vue?vue&type=script&lang=js&"
export * from "./ClientsProfileOrganizationBlockedPersonAdd.vue?vue&type=script&lang=js&"
import style0 from "./ClientsProfileOrganizationBlockedPersonAdd.vue?vue&type=style&index=0&id=375fa2d2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "375fa2d2",
  null
  
)

export default component.exports