<template> 
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('cp-change-the-organization-data',192)">
        
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">

                <app-row-left-label :label="trans('full-name',3)">
                    <app-input v-model="cArgs.client_name" validatorName="Naam" validatorRules="required" mask="lettersE9-40"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('short-name',182)">
                    <app-input v-model="cArgs.client_short_name" validatorName="Korte naam" validatorRules="max:15" mask="lettersE6-15"/>
                </app-row-left-label>  

                <app-row-left-label :label="trans('cp-part-of',192)">
                    <app-input v-model="cArgs.group_name" mask="lettersE4-60"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('cp-client-type',190)">
                   <!-- < v-model="cArgs.client_type.value" :options="client_type_options" :reduce="(status) => status.value" label="text" :clearable="false" :class="{'app-select-filled': cArgs.client_type.value != null}" />-->
                    <app-select v-model="cArgs.client_type.value" :options="client_type_options" reduceValue :clearable="false" :disable="userRole == 3" />
                </app-row-left-label>

                <app-divider-modal :text="trans('contact-details',182)" :isLine="true"/>

                 <app-row-left-label :label="trans('phone-number',182)">
                    <app-phone :prefix.sync="cArgs.phone_prefix" :phone.sync="cArgs.phone_number" :isRequired="false"/>
                </app-row-left-label> 

                 <app-row-left-label :label="trans('email',182)">
                    <app-email :email.sync="cArgs.email" :changeMode="true" type="person" />
                </app-row-left-label>
                
                <app-divider-modal :text="trans('address-details',3)" :isLine="true"/>
                <app-address-nl ref="address_control" :address.sync="cArgs.address" :required="false" />
            </b-form>
        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="organizationChange" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import AppAddressNl from '@core/components/Address/AppAddressNl.vue';
import AppPhone from '@core/components/AppPhone.vue';
import AppEmail from '@core/components/AppEmail.vue';
import {  ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver,
        AppAddressNl,
        AppPhone,
        AppEmail
    },

    props: ["show", "result", "args", "return"],

    created(){
        this.userRole = JSON.parse(localStorage.getItem('user')).id_user_const_role;
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show', value) }, 
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }, 
        },
    },


    data() {
        return {
            loading: false,
            userRole: null,
            client_type_options:[
                { value: 0, name: this.trans('cp-large-opt',190) },
                { value: 2, name: this.trans('cp-regular-opt',190) }
            ]
        };
    },

    methods: {

        organizationChange() {
            this.$refs.form.validate().then((result) => {

                if (!result) {
                    return false;
                }
               else{
                    if(!this.$refs.address_control.address.street && this.cArgs.address.post_code != null){
                        return false;
                    }else{
                        this.loading = true;
                        axios.post("clients/profile/organization/change", {
                                id_client_data_main: this.args.id_client_data_main,
                                organization_data: JSON.stringify(this.args),
                            })
                            .then((res) => {
                                this.$emit("update:show", false);
                                this.$emit("update:result", 1);
                                this.cReturn.client_data = res.data.client_data;
                                this.cReturn.structure_locations = res.data.structure_locations; 
                                this.loading = false;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            });
                    }
                }
            })
                
        },



        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

