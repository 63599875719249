<template> 
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('cp-change-indexation-information',192)">
        
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <app-row-left-label :label="trans('cp-indexing',192)">
                    <app-select v-model="cArgs.indexation" :clearable="true" :options="indexation_options" validatorName="Indexatie" />
                </app-row-left-label>   

                

                <app-row-left-label :label="trans('cp-first-indexation-per',192)" >
                    <app-date v-model="cArgs.indexation_date" validatorName="Eerste indexatie" validatorRules="date-check|date-more-check" :clearable="true" :disabledDatesPass="1" />
                </app-row-left-label>
            </b-form>
        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="indexationChange" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import moment from "moment";

export default {
    components: {
        ValidationObserver,
    },

    props: ["show", "result", "args", "return"],

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }, 
        },
    },

    data() {
        return {
            loading: false,
            indexation_options: [
                { value: 3, name: this.trans('cp-cbs-conumer-price-index',192) },
                { value: 1, name: this.trans('cp-nza-personnel-index',192) },
                { value: 2, name: this.trans('cp-social-employer-costs',192) },
                { value: 4, name: this.trans('cp-full-indexation-without-reservation',192) }
            ],
            disabled_dates: {
                to: moment()
                    .subtract(1, "days")
                    .toDate(),
                dates: [
                    moment()
                        .subtract(1, "days")
                        .toDate(),
                ],
            },
        };
    },
    
    methods: {

        indexationChange() {

            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }
                else{
                    this.loading = true;
                    axios
                        .post("clients/profile/financials/indexationChange", {
                            id_client_data_main: this.args.id_client_data_main,
                            indexation: JSON.stringify(this.cArgs.indexation),
                            indexation_date: this.cArgs.indexation_date
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn.client_data = res.data;
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                }
            })
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

