<template>
    <div v-bind:class="{'app-local-calendar-icon': value && clearable, 'app-local-clear-icon': value && clearable}">
            <label v-if="label" class="app-input-top-label">{{label}}</label><br v-if="label"/>
            <br v-if="additionalInfo" /><small v-if="additionalInfo" class="text-muted">{{additionalInfo}}</small>
            <date-picker v-model="yearField"
                         :type="type"
                         :format="format"
                         :value-type="valueType"
                         :placeholder="placeholder"
                         :disabled-date="disableDates"
                         :disabled="disabled"
                         @close='closed()'
                         :class="{'app-datepicker': true, 'app-datepicker-filled': yearField, 'app-datepicker-disabled': disabled, 'app-datepicker-error': cError, [customClass]: true}"
                         :min="new Date().toISOString().substr(0, 10)"
                         @change="updateValue"
                         @blur="onBlur" 
                         :clearable="clearable"
                        />
        <validation-provider v-slot="{ errors}" ref="provider" :rules="validatorRules" :name="validatorName" :custom-messages="validatorCustomMessage">
            <input type="hidden" name="date" v-model="yearField">
            <small v-if="errors.length > 0 && errors[0] != ''" class="ml-1 text-error app-input-error-ellips" data-toggle="tooltip" :title="errors[0]">{{ errors[0] }}</small>        
        </validation-provider>
    </div>
</template>
<script>
import { defaultFormat } from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/nl';
import moment from "moment";

export default {
    
    components: {
        DatePicker ,
    },

    name: 'AppYear',

    props: {
        id: {type: String},
        value: {},
        name: { type: String },
        placeholder: { type: String, default: "Year" },
        disabled: { type: Boolean, default: false },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        label: {type: String},
        additionalInfo: {type: String},
        format: {type: String, default: "YYYY"},
        valueType: {type: String, default: "YYYY"},
        type: {type: String, default: "year"},
        clearable: {type: Boolean, default: false},
        customClass: {type: String, default: ''},
        disableYearFuture:  {type: Boolean, default: false },
    },

    created() {
        if(this.value != null){
            this.yearField = this.value;
        }
    },

    mounted(){ 
        this.is_mounted = true;
    },

    computed: {
        cError: {
            get(){
                if(this.is_mounted){
                    return this.$refs.provider.errors.length > 0 && this.$refs.provider.errors[0] != '';
                } else {
                    return false;
                }
            }
        }
    },
    
    data() {
        return {
            yearField: null,
            is_mounted: false,
            disabledDates: [1900, 2200]
        };
    },


    methods: {
        onBlur(event) {
            this.$emit('blur', event.target.value);
        },

        disableDates(date) {
            const today = new Date();
            if(this.disableYearFuture && today < date) {
                return true
            }
            return false;

        },

        async closed(){

            await this.$nextTick();
            this.$refs.provider.validate();
        },
        
        updateValue: function(value) {
            if(this.yearField != null){
                this.$emit("input", this.yearField);
            }else{
                this.$emit("input", this.yearField);
            }
        },
    },
};
</script>

<style>
.app-local-calendar-icon .mx-icon-calendar{
    display:none;
}

.app-local-clear-icon .mx-icon-clear{
    display:initial;
}
</style>