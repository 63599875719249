<template>
    <div>
        <b-row v-if="profile.client_data.id_status_const_type > 3 && (user_role < 3 || profile.can_user_edit)" class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right>
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>          
                <b-dropdown-item @click="change()">{{trans('change',175)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header">
            <b-col></b-col>
            <b-col>
                <p class="col-list-label app-local-title"><b>{{trans('employment-agency',190)}}</b></p>
            </b-col>
            <b-col>
                <p class="col-list-label app-local-title"><b>{{trans('bemiddeling',190)}}</b></p>
            </b-col>
        </b-row>

        <b-row class="app-card-header">
            <b-col>
                <p class="col-list-label">{{trans('cp-issued-limit',192)}}</p>
                <p class="col-list-label">{{trans('cp-requested-limit',192)}}</p>
                <p class="col-list-label mb-3">{{trans('cp-expected-number-of-flexworkers',192)}}</p>
                <p class="col-list-label">{{trans('cp-credit-limit-edit-date',192)}}</p>
                <p class="col-list-label">{{trans('cp-credit-limit-edited-by',192)}}</p>
            </b-col>
           
            <b-col>
                <p class="col-form-value">{{profile.client_data.credit_limit_uzk ? profile.client_data.credit_limit_uzk.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.requested_limit_uzk ? profile.client_data.requested_limit_uzk.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-'}}</p>
                <p class="col-form-value mb-3">{{profile.client_data.expected_flex_workers_uzk ? profile.client_data.expected_flex_workers_uzk : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.creditlimit_last_update_uzk ? profile.client_data.creditlimit_last_update_uzk : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.user_creditlimit_uzk ? profile.client_data.user_creditlimit_uzk : '-'}}</p>
            </b-col>

            <b-col>
                <p class="col-form-value">{{profile.client_data.credit_limit_zzp ? profile.client_data.credit_limit_zzp.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.requested_limit_zzp ? profile.client_data.requested_limit_zzp.replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '-'}}</p>
                <p class="col-form-value mb-3">{{profile.client_data.expected_flex_workers_zzp ? profile.client_data.expected_flex_workers_zzp : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.creditlimit_last_update_zzp ? profile.client_data.creditlimit_last_update_zzp : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.user_creditlimit_zzp ? profile.client_data.user_creditlimit_zzp : '-'}}</p>
            </b-col>
        </b-row>
        
          
        <clients-profile-organization-financials-credit-check-change v-if="mCreditCheckChange.show" :show.sync="mCreditCheckChange.show" :result.sync="mCreditCheckChange.result" :args.sync="mCreditCheckChange.args" :return.sync="profile"/> 
    </div>
</template>
<script>

import ClientsProfileOrganizationFinancialsCreditCheckChange from "./ClientsProfileOrganizationFinancialsCreditCheckChange.vue";


export default {
    components: {
        ClientsProfileOrganizationFinancialsCreditCheckChange
    },

    props:["profile"],

    created(){
        this.user_role = JSON.parse(localStorage.getItem('user')).id_user_const_role;
    },

    data(){
        return{
                user_role:null,
                mCreditCheckChange: { 
                                        show: false, args: {
                                                            credit_limit_uzk: null,
                                                            requested_limit_uzk: null,
                                                            expected_flex_workers_uzk: null,
                                                            credit_limit_zzp: null,
                                                            requested_limit_zzp: null,
                                                            expected_flex_workers_zzp: null,
                                                            id_client_data_main:null,
                                                            type: 'accordion',
                                                            user_role: null,
                                                        }, 
                                        result: 0, return: null },
        }
    },


    methods:{       
        change(){
            this.mCreditCheckChange.args.id_client_data_main = this.profile.id;
            this.mCreditCheckChange.args.credit_limit_uzk = this.profile.client_data.credit_limit_uzk;
            this.mCreditCheckChange.args.requested_limit_uzk = this.profile.client_data.requested_limit_uzk;
            this.mCreditCheckChange.args.expected_flex_workers_uzk = this.profile.client_data.expected_flex_workers_uzk;
            this.mCreditCheckChange.args.credit_limit_zzp = this.profile.client_data.credit_limit_zzp;
            this.mCreditCheckChange.args.requested_limit_zzp = this.profile.client_data.requested_limit_zzp;
            this.mCreditCheckChange.args.expected_flex_workers_zzp = this.profile.client_data.expected_flex_workers_zzp;
            this.mCreditCheckChange.args.user_role = this.user_role;
            this.mCreditCheckChange.show = true;
        },
    }
    
 
};
</script>

<style scoped>
.app-local-title{
    font-size:14px
}
</Style>
