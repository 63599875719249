<template>
    <div>
        <b-row v-if="profile.client_data.id_status_const_type > 3 && (user_role < 3 || profile.can_user_edit)" class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right>
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change()">{{trans('change',175)}}</b-dropdown-item>                    
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header" v-if="profile.client_data.organization_scale">
            <b-col cols="6" start>
                <p class="col-form-value">{{trans('main-cao',190)}}</p>
                <p class="col-form-value">
                    {{trans('cp-hourly-wage-base-on',192)}}                         
                    <b-icon icon="info-circle-fill" id="popover-target-hourly-wage-base-on-accordion" class="ml-1 app-cursor-pointer" style="width: 17px; height: 17px;" />
                </p>
                <p class="col-form-value">{{trans('cp-fixed-organizational-increase-percentage',192)}}</p>
                <p class="col-list-label mb-3">{{trans('cp-ort-caps',190)}}</p>
            </b-col>
            <b-col cols="6">
                <p class="col-form-value">{{profile.client_data.cao_name ? profile.client_data.cao_name : '-'}}</p>
                <p class="col-form-value">{{organization_scale.scale_type ? organization_scale.scale_type == 1 ? this.trans('fp-scaling-title',172) : this.trans('cp-hirer-reward',192) : '-'}}</p>
                <p class="col-form-value">{{organization_scale.organizational_increase_percentage ? organization_scale.organizational_increase_percentage : '-'}}</p>
                <p class="col-form-value mb-3">{{organization_scale.ort_capping == 1 ? trans('yes-option', 3) : trans('no-option', 3)}}</p>
            </b-col> 
        </b-row>

        <b-row class="app-card-header" v-if="profile.client_data.organization_scale">
            <b-col>
                <div v-for="item, index in organization_scale.cao" :key="index">
                    <b-row class="app-card-header">
                        <b-col cols="12">
                            <div class="col-list-label mt-2"><b>{{item.cao_name}}</b></div>
                        </b-col>
                    </b-row>

                    <b-row v-for="data, index2 in item.functions.list" :key="index2" class="app-card-header" >
                        <b-col cols="6" start>
                            <p class="col-form-value">{{data.function_name}}</p>
                        </b-col>
                        <b-col cols="6">
                            <p class="col-form-value">{{data.scale_value}}</p>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>

        <hr class="app-table-hr-separator" v-if="profile.client_data.organization_scale">

        <b-row class="app-card-header" v-if="profile.client_data.organization_scale">
            <b-col start>
                <p class="col-list-label">{{trans('cp-form-completed-by',190)}}</p>
                <p class="col-list-label">{{trans('function',3)}}</p>
                <p class="col-list-label">{{trans('cp-has-been-completed-approved-on',190)}}</p>

            </b-col>
            <b-col>
                <p class="col-form-value">{{organization_scale.completed_name ? organization_scale.completed_name : '-'}}</p>
                <p class="col-form-value">{{organization_scale.completed_function ? organization_scale.completed_function : '-'}}</p>
                <p class="col-form-value">{{organization_scale.completed_date ? organization_scale.completed_date : '-'}}</p>
            </b-col>
        </b-row>
        <b-row class="app-card-header" v-else>
            <b-col start>
                <p class="col-list-label">{{trans('cp-collective-labor-agreement-not-completed',190)}}</p>
            </b-col>
        </b-row>

        <b-popover target="popover-target-hourly-wage-base-on-accordion" triggers="hover focus" no-fade>
            <template #title>
                {{trans('information',182)}}
            </template>
            <p class="app-popover-text" v-html="trans('cp-hourly-wage-source-tooltip', 192)"></p>
        </b-popover>

        <clients-profile-organization-scale-change v-if="mOrganizationScaleChange.show" :args.sync="mOrganizationScaleChange.args" :show.sync="mOrganizationScaleChange.show" :result.sync="mOrganizationScaleChange.result" :return.sync="profile"  />

    </div>    
</template>
<script>

import ClientsProfileOrganizationScaleChange from './ClientsProfileOrganizationScaleChange.vue';

export default {

    components: {
        ClientsProfileOrganizationScaleChange,
    },
    
    props: ["profile"],

    watch: {
        "mOrganizationScaleChange.result": {
            handler: function(val){
                if(val > 0){
                    this.organization_scale = JSON.parse(this.profile.client_data.organization_scale);
                    this.mOrganizationScaleChange.result = 0;
                }
            },
            deep: true
        }
    },

    data(){ 
        return {
            organization_scale: JSON.parse(this.profile.client_data.organization_scale),
            mOrganizationScaleChange: { args: {
                id_client_data_main: this.profile.client_data.id_client_data_main,
                id_cao_data_main:null,
                organization_scale: {
                    completed_name: null,
                    completed_function: null,
                    functions:{
                        list: null
                    },
                    completed_date: null,
                    organizational_increase_percentage:null,
                    scale_type:null,
                    ort_capping: null
                },
            }, show: false, result: 0, return: null },
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        }
    },

    methods: {
        change(){
            this.mOrganizationScaleChange.args.organization_scale = JSON.parse(JSON.stringify(this.profile.organization_scale.organization_scale));
            this.mOrganizationScaleChange.args.organization_scale.completed_name = this.organization_scale ? this.organization_scale.completed_name : null;
            this.mOrganizationScaleChange.args.organization_scale.completed_function = this.organization_scale ? this.organization_scale.completed_function : null;
            this.mOrganizationScaleChange.args.organization_scale.completed_date = this.organization_scale ? this.organization_scale.completed_date : null;
            this.mOrganizationScaleChange.args.organization_scale.organizational_increase_percentage = this.organization_scale ? this.organization_scale.organizational_increase_percentage : null;
            this.mOrganizationScaleChange.args.organization_scale.scale_type = this.organization_scale && this.organization_scale.scale_type ? this.organization_scale.scale_type : 1;
            this.mOrganizationScaleChange.args.id_cao_data_main = this.organization_scale ? this.profile.client_data.id_cao_data_main : null;
            this.mOrganizationScaleChange.args.organization_scale.ort_capping = this.organization_scale ? this.organization_scale.ort_capping : null;
            this.mOrganizationScaleChange.show = true;
        },
    }
    
}
</script>
<style>
</style>