<template>
    <div>
        <b-row v-if="(profile.client_data.id_status_const_type > 3 || profile.client_data.register_status == 0) && (user_role < 3 || profile.can_user_edit)" class="app-component-icon-edit">
            <b-dropdown variant="link"  no-caret right>
                <template #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change()">{{trans('cp-change-organization-data',192)}}</b-dropdown-item>                    
                <b-dropdown-item @click="changeKVK()">{{trans('change-kvk',8)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>

        <b-row class="app-card-header">
            <b-col start>
                <p class="col-list-label">{{trans('full-name',3)}}</p>
                <p class="col-list-label">{{trans('short-name',182)}}</p>
                <p class="col-list-label">{{trans('cp-part-of',192)}}</p>
                <p class="col-list-label mb-3">{{trans('cp-client-type',190)}}</p>

                <p class="col-list-label">{{trans('street',182)}}</p>
                <p class="col-list-label">{{trans('house-number',182)}}</p>
                <p class="col-list-label">{{trans('cw-house-number-addition',194)}}</p>
                <p class="col-list-label">{{trans('postcode',182)}}</p>
                <p class="col-list-label mb-3">{{trans('town',192)}}</p>

                <p class="col-list-label">{{trans('cp-email-address',190)}}</p>
                <p class="col-list-label mb-3">{{trans('phone-number',182)}}</p>

                <p class="col-list-label">{{trans('kvk_number',169)}}</p>
                
            </b-col>
            <b-col>
                <p class="col-form-value">{{profile.client_data.client_name ? profile.client_data.client_name : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.client_short_name ? profile.client_data.client_short_name : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.group_name ? profile.client_data.group_name : '-'}}</p>
                <p class="col-form-value mb-3">{{profile.client_data.client_type ? profile.client_data.client_type : '-'}}</p>

                <p class="col-form-value">{{profile.client_data.street  ? profile.client_data.street : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.house_number ? profile.client_data.house_number : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.number_additional ? profile.client_data.number_additional : '-'}}</p>
                <p class="col-form-value">{{profile.client_data.post_code ? profile.client_data.post_code : '-'}}</p>
                <p class="col-form-value mb-3">{{profile.client_data.city ? profile.client_data.city : '-'}}</p>

                <p class="col-form-value">
                    <b-link v-if="profile.client_data.email" :href="'mailto:' + profile.client_data.email">{{profile.client_data.email}}</b-link>
                    <span v-if="!profile.client_data.email">-</span>
                </p>
                 <p class="col-form-value mb-3">
                    <span v-if="profile.client_data.phone_number">{{phone}}</span>
                    <span v-if="!profile.client_data.phone_number">-</span>
                </p>

                <p class="col-form-value">{{profile.client_data.kvk_number ? profile.client_data.kvk_number : '-'}}</p>
         
            </b-col>
        </b-row>

        <b-row class="mt-3" v-if="isAddress && !isCoordinates">
            <b-col cols="12">
                <b-alert variant="warning" show class="mb-0 d-flex align-items-center justify-content-between">
                    <div class="font-weight-600 text-base">{{trans('fp-address-coordinates-missing', 172)}}</div>
                    <app-button type="change" :loading="loading" @click="getCoordinates">{{trans('download', 175)}}</app-button>
                </b-alert> 
            </b-col>
        </b-row>
          
        <clients-profile-organization-data-change v-if="mOrganizationDataChange.show" :show.sync="mOrganizationDataChange.show" :result.sync="mOrganizationDataChange.result" :args.sync="mOrganizationDataChange.args" :return.sync="profile"/>  
        <clients-profile-organization-data-change-kvk v-if="mChangeKvk.show" :show.sync="mChangeKvk.show" :result.sync="mChangeKvk.result" :args.sync="mChangeKvk.args" :return.sync="profile"/>   
        
    </div>
</template>
<script>

import ClientsProfileOrganizationDataChange from "./ClientsProfileOrganizationDataChange.vue";
import ClientsProfileOrganizationDataChangeKvk from "./ClientsProfileOrganizationDataChangeKvk.vue";
import axios from "@axios";

export default {
    components: {
        ClientsProfileOrganizationDataChange,
        ClientsProfileOrganizationDataChangeKvk
    },

    props:["profile"],

    computed: {
        phone: function () {
            if (this.profile.client_data.phone_number !== null && this.profile.client_data.phone_prefix == '+31') {
                return 0 + this.profile.client_data.phone_number.replace(/^(.{1})(.{3})(.{3})(.*)$/, "$1 $2 $3 $4");
                //return this.profile.client_data.phone_prefix + " " + this.profile.client_data.phone_number;
            } else {
                return this.profile.client_data.phone_prefix + " " + this.profile.client_data.phone_number;
            }
        },

        isAddress: {
            get() {
                return this.profile.client_data.post_code != null 
                    && this.profile.client_data.house_number != null 
                    && this.profile.client_data.street != null 
                    && this.profile.client_data.city != null
                    && this.profile.client_data.post_code != '' 
                    && this.profile.client_data.house_number != '' 
                    && this.profile.client_data.street != '' 
                    && this.profile.client_data.city != '';
            }
        },

        isCoordinates: {
            get() {
                return this.profile.client_data.address_latitude != null
                    && this.profile.client_data.address_longitude != null
                    &&this.profile.client_data.address_latitude != ''
                    && this.profile.client_data.address_longitude != '';
            }
        },

        cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value)}
        }
    },

    data(){
        return{
            mOrganizationDataChange: { show: false, args: {
                address: {
                                post_code: "", 
                                house_number: "", 
                                house_number_additional:"",
                                city:"",
                                street:"",
                                latitude: null,
                                longitude: null,
                                address_data_main:null,

                            },
                id_client_data_main:null,
                email:null,
                phone_prefix:null,
                phone_number:null,
                client_name: null,
                client_short_name: null,
                group_name: null,
                client_type: null,
            }, result: 0, return: null },            

            mChangeKvk: { show: false, args: {
                id_client_data_main:null,
                kvk_data_main: null, 
                kvk_check_date: null, 
                kvk_number: null,
            }, result: 0, return: null },
            loading: false,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,

            
        }
    },  

    methods:{       
        change(){
            this.mOrganizationDataChange.args.id_client_data_main = this.profile.id;
            this.mOrganizationDataChange.args.address.street = this.profile.client_data.street;
            this.mOrganizationDataChange.args.address.house_number = this.profile.client_data.house_number;
            this.mOrganizationDataChange.args.address.house_number_additional = this.profile.client_data.number_additional;
            this.mOrganizationDataChange.args.address.post_code = this.profile.client_data.post_code;
            this.mOrganizationDataChange.args.address.city = this.profile.client_data.city;
            this.mOrganizationDataChange.args.email = this.profile.client_data.email;
            this.mOrganizationDataChange.args.phone_prefix = this.profile.client_data.phone_prefix ? this.profile.client_data.phone_prefix: '+31';
            this.mOrganizationDataChange.args.phone_number = this.profile.client_data.phone_number;
            this.mOrganizationDataChange.args.address.address_data_main = this.profile.client_data.address_data_main;
            this.mOrganizationDataChange.args.client_name = this.profile.client_data.client_name;
            this.mOrganizationDataChange.args.client_short_name = this.profile.client_data.client_short_name;
            this.mOrganizationDataChange.args.group_name = this.profile.client_data.group_name;
            this.mOrganizationDataChange.args.client_type = {text: this.profile.client_data.client_type, value: this.profile.client_data.id_client_type}
          
            this.mOrganizationDataChange.show = true;
        },
        
        changeKVK() {
            this.mChangeKvk.args.id_client_data_main = this.profile.id;
            this.mChangeKvk.args.kvk_number = this.profile.client_data.kvk_number;
            this.mChangeKvk.args.kvk_data_main = this.profile.client_data.kvk_data_main;
            this.mChangeKvk.args.kvk_check_date = this.profile.client_data.kvk_check_date;
            this.mChangeKvk.show = true;
        },

        getCoordinates(){
            this.loading = true;
            axios
                .post("core/coordinates/downloadCoordinatesClient", {
                    id: this.profile.client_data.id,
                    type: 1
                })
                .then((res) => {
                    this.cProfile.client_data = res.data;
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });
        },
    }
 
};
</script>
