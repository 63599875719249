<template>
    <div>
        <b-card no-body class="app-card-profile">
            <app-view-table :isTitle="false" :loading="loading_list" :isTitleTotal="false" :filters.sync="filters" :customColumns="custom_columns" :items="profile.contract.items" :fields="fields" :isFilterOptions="false" v-on:onRowClicked="item=>$set(item, '_showDetails', !item._showDetails)" class="table-selectable" :isMenuColumn="user_role < 3 || profile.can_user_edit">

                <template v-slot:row-details="row" >
                    <clients-profile-organization-contract-details-component :itemData.sync="row.data.item" :functions="functions" :return.sync="profile.contract" :filters="filters" :loading.sync="loading_list"/>
                </template>

                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="openDocument(cell.data.item)" v-if="[1,3].includes(cell.data.item.status)">{{trans('show-document',3)}}</b-dropdown-item>
                    <b-dropdown-item @click="deleteItem(cell.data.item)" v-if="cell.data.item.status != 1">{{trans('cancel',175)}}</b-dropdown-item>
                    <b-dropdown-item :to="{ name: 'clients-profile-contract-indexation', params: { id_contract_data_main: cell.data.item.id_contract_data_main, id_client_data_main: profile.id }}" v-if="[1,3].includes(cell.data.item.status) &&  (!cell.data.item.name || (cell.data.item.parameters[0].id == cell.data.item.name.id && cell.data.item.name.placement_recalculation == 1))"> 
                        {{trans('cp-indexing',192)}}
                    </b-dropdown-item>
                </template>

                <template v-slot:status="cell">
                    <div v-if="cell.data.item.status == 2" @click="openPopover" style="" class="">
                        <button :id="'popover-id-errors'+cell.data.item.id_contract_data_main" tabindex="0" class="app-local-popover-icon"><b-icon icon="exclamation-circle-fill" class="app-icon-warning"></b-icon></button>
                        <b-popover :target="'popover-id-errors'+cell.data.item.id_contract_data_main" triggers="focus" no-fade custom-class="app-local-popover">
                            <template #title>
                                {{trans('rejected-by-client',180)}}
                            </template>
                            Reden: {{cell.data.item.description}}
                        </b-popover>
                    </div>
                </template>


                <template v-slot:buttons v-if="user_role < 3 || profile.can_user_edit">
                    <app-button type="primary" :url="{ name: 'clients-profile-contract-wizard', params: { id_client_data_main: profile.id }}" >{{trans('create-contract',175)}}</app-button>
                </template>
            </app-view-table>
        </b-card>               
        
    </div>
</template>

<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import ClientsProfileOrganizationContractDetailsComponent from './ClientsProfileOrganizationContractDetailsComponent.vue';

export default {
    components: {
        ClientsProfileOrganizationContractDetailsComponent
    },

    data() {
        return {
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 1,
                        changed: 0
                },
                filtering: {                        
                        statuses: { type: 'list', changed: 0, values: [], function: "getClientContactStatus", parameters: null, widthClass:'app-width-min-250'},
                },
                page: 1,
                per_page: 5,
                search: "",
                changed: 0
            },
            fields: [
                { key: "document_type", label: this.trans('spieces',182), thStyle: { "min-width": "90px", width: "100%" }, visible: true },
                { key: "date_from", label: this.trans('date-from',182), thStyle: {  "min-width": "140px", "max-width": "140px" }, visible: true },
                { key: "date_to", label: this.trans('date-to',182), thStyle: {  "min-width": "140px", "max-width": "140px" }, visible: true },
                { key: "function_name", label: this.trans('cao',182), thStyle: {  "min-width": "120px", "max-width": "120px" }, visible: true },
                { key: "status_name", label: this.trans('status',3), thStyle: {  "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "statuses" },
                { key: "status", label: "", thStyle: { "min-width": "38px", "max-width": "38px" },visible:true, filtering: false },
            ],
            custom_columns: ['status'],
            loading_list: false,
            alert_class: new AppAlerts(),
            functions: null,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,

        };
    },

    props:["profile"],

     mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', false) }, 
        },
    },

    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("clients/profile/contract/getList", {
                    id_client_data_main: this.profile.id,
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.cProfile.contract = res.data;
                    this.filters.page = res.data.page;
                    this.functions = res.data.functions;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        }, 

        deleteItem(item) {
            this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post("clients/profile/contract/remove", {
                            id_client_data_main: this.profile.id,
                            id_contract_data_main: item.id_contract_data_main,
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                            this.cProfile.contract = res.data;
                            this.filters.page = res.data.page;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });
                    };
                });  
        },

        openDocument(data){
            this.loading_list = true;
            axios
                .post("clients/profile/contract/openDocument", {
                    id_document_data_main: data.id_document_data_main,
                    id_client_data_main: this.profile.id,
                },
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {
                    if(res.data.size > 2){
                        var mine_type = "application/pdf";
                        const url = window.URL.createObjectURL(new Blob([res.data], {type : mine_type}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.target= "_blank";
                        mine_type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                        mine_type == 'application/msword' ||
                        mine_type == 'text/plain'
                                        ? link.setAttribute('download', data.type_name):link.setAttribute('open', data.type_name); 
                        link.setAttribute('open', data.type_name); 
                        document.body.appendChild(link);
                        link.click();
                    }
                    else{
                        this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {});
                    }

                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                    this.alert_class.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        openPopover(){
            
        },

         onCreateThis() { 

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('profile.contract.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        },

    },
};
</script>

<style scoped>
.vs__selected-options {
    font-size:9px;
}

.app-icon-warning{
    color: #f04438;
}

.app-local-popover{
    color: red;
}

.app-local-popover-icon{
    padding: 0px;
    margin: 0px;
    background-color: inherit;
    border: none;
}

</style>
