<template>
    <div>
        <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('select-document-type',3)">
            <b-card class="app-local-card-size">
                <div class="app-fixed-search d-flex align-items-center">
                    <div class="d-flex align-content-center justify-content-between w-100">
                        <b-input-group class="input-group-merge" >
                        
                            <b-input-group-prepend is-text class="app-local-icon">
                                <b-icon icon="search" class="text-muted" v-if="!loading"></b-icon>      
                                <b-spinner class="align-middle" small v-if="loading"></b-spinner>  
                            </b-input-group-prepend >

                            <b-form-input class="pl-2 mt-1 border-0"  :placeholder="trans('search',3)" v-model="filters.search" :lazy="true"/>

                            <b-input-group-append is-text class="app-local-dropdown">
                                <b-dropdown variant="link" no-caret right >
                                    <template #button-content >
                                            <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                                    </template>
                                        <b-dropdown-item @click="filters.sort_desc = false"> {{trans('sort-az',260)}} </b-dropdown-item>
                                        <b-dropdown-item @click="filters.sort_desc = true"> {{trans('sort-za',260)}} </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </div>
                <hr class="app-search-modal-separator">
                    <b-list-group flush class="app-local-list-group-custom-style">
                        <b-list-group-item class="d-flex  align-items-center app-local-list-group-item-custom-style" action v-for="item in items" :key="item.value" tabindex="0" @click.prevent="selectItem(item)" >
                        <b-form-checkbox  v-model="id_document_const_type" :value="item.id" unchecked-value="0" size="sm" :class="id_document_const_type == item.id ? 'custom-checkbox-checked' : ''">
                            <span class="app-documents-item">{{item.document_type_name}}</span>
                        </b-form-checkbox>                    
                        </b-list-group-item>
                    </b-list-group>
            </b-card>
            <template #modal-footer>
                <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
                <app-button type="primary" @click="nextStep" :disabled="checkId">
                    {{trans('next-step',175)}}
                </app-button>
            </template>
        </b-modal>
        
    </div> 
</template>       
            

<script>
import axios from "@axios";

export default {

    props: ["show", "result",  "profile"],

    created() {
        this.getDocumentsTypes();
    },

    watch: {
        filters: {
            handler: function(val) {
                if (this.filters.sort_by != "") {
                    this.loading = true;
                    this.getDocumentsTypes();
                }
            },
            deep: true,
        }        
    },

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        },

        checkId: {
            get() {
                if(this.id_document_const_type === undefined) {
                    return true;
                } else if (this.id_document_const_type === false){
                    return true;
                } else {
                    return false;
                }

            }
        }
    },

    data() {
        return {
            loading: 0,
            id_document_const_type: false,
            filters: {
                sort_by: "document_type_name",
                sort_desc: false,
                search: "",
            },
            items:[],
            
        };
    },

    methods: {

        selectItem(item) {          
           var index = this.items.indexOf(item);
           if (item.selected === '1') {
                item.selected = '0';
            } else {
                item.selected = '1';             
            }

            this.id_document_const_type = item.id;
            this.$set(this.items, index, item);
        },

        getDocumentsTypes(){
            this.loading = 1;
            axios
                .post("clients/profile/documents/getTypes", {
                    filters: JSON.stringify(this.filters)
                })
                .then((res) => {
                   this.items = res.data;
                   this.loading = 0;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });
        },

        closeModal() {
            this.$emit("update:show", false);
        },

        nextStep() {
            this.$emit("update:show", false);
            this.$emit("update:return", this.id_document_const_type);            
        }

    },
};
</script>

<style scoped>
.input-group-text {
    border: none;
}
.ps{
    height: 350px;
}
.card-body {
    padding-top: 0.5rem;
}
.app-local-card-size{
    min-height:300px !important; 
    max-height:600px !important
}
.app-local-dropdown{
    width:65px
}
.app-local-icon{
    width:40px !important;
}
.app-local-list-group-custom-style{
    max-height:225px !important;
    overflow-y: auto;
}

.app-local-list-group-item-custom-style{
    cursor:pointer; 
    padding:4px 4px;
}
</style>
