<template> 
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('cp-change-financial-data',192)">
        
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
               
                <app-row-left-label :label="trans('cp-payment-term',192)">
                    <app-select v-model="cArgs.settlement_period" :clearable="false"  :disable=" (user_role == 3 && (cArgs.id_client_type != 2 || cArgs.id_client_type == null))"  :options="settlement_period_options" reduceValue validatorName="Betaaltermijn" validatorRules="required"/>
                </app-row-left-label>   

                <app-row-left-label :label="trans('cp-invoice-email-address',192)" >
                    <app-email :email.sync="cArgs.invoice_email" ref="email_control" :changeMode="true" validatorName="Email" validatorRules="required" type="client" />
                </app-row-left-label>

                <app-row-left-label :slots="[12,12]" customLabel>
                    <template v-slot:custom-label>
                        <span class="col-form-label app-input-label">{{trans('invoice-frequency',182)}}</span>
                        <b-icon icon="info-circle-fill" id="popover-frequency-change" class="ml-2 app-cursor-pointer" style="width: 15px; height: 15px;"/>
                    </template>
                    <template v-slot:col-2>
                        <app-select v-model="cArgs.frequency" class="ml-1" :clearable="true" type="getInvoiceFrequency"/>
                    </template>
                </app-row-left-label>

                <app-row-left-label :label="trans('cp-cost-center',192)">
                    <app-input v-model="cArgs.costplace_number" validatorName="Kostenplaats" mask="lettersE6-20" />
                </app-row-left-label> 
                
                <app-row-left-label :customError="true" :isMarginBetween="false" :label="trans('cp-invoince-per',192)">

                    <app-check-box v-model="cArgs.costplace" value="1" unchecked-value="0" :rightLabel="trans('cp-cost-center',192)" />
                    <app-check-box v-model="cArgs.flexworker" value="1" unchecked-value="0" :rightLabel="trans('flexworker',182)" />
                    <app-check-box v-model="cArgs.week" value="1" unchecked-value="0" :rightLabel="trans('week',3)" />

                    <template #custom-error>
                        <small class="text-danger" v-if="facture_error_message == 1">{{trans('field-required-error',180)}}</small>
                    </template>

                </app-row-left-label> 

                <app-row-left-label :label="trans('hours-on-invoice',168)">
                    <app-select v-model="cArgs.hours_on_invoice" reduceValue :options="invoice_hours_options" :clearable="false" />
                </app-row-left-label>

                <app-divider-modal text=" " :isLine="true"/>

                <app-row-left-label :label="trans('cp-g-account-precentage',192)">
                    <app-input v-model="cArgs.g_account" type="integer_max_100" />
                </app-row-left-label>

                <app-row-left-label :label="trans('cp-about-invoice-amount',192)" >
                    <app-radio-group v-model="cArgs.over_btw" :options="over_btw_options"/>
                </app-row-left-label>

                <app-divider-modal text=" " :isLine="true"/>
                
                <app-row-left-label :label="trans('cp-invoice-attention',192)">
                    <app-input v-model="cArgs.invoice_attention" mask="lettersE6-40" />
                </app-row-left-label>

                <app-row-left-label :label="trans('cp-invoice-shipping-method',192)" :slots="[6,6]" :customError="true">
                    <template v-slot:col-1>
                        <app-check-box v-model="cArgs.invoice_shipping_pdf" rightLabel="PDF"/>
                    </template>
                    <template v-slot:col-2>
                         <app-check-box v-model="cArgs.invoice_shipping_xml" rightLabel="XML" />
                    </template>

                    <template #custom-error>
                        <small class="text-danger" v-if="shipping_error_message == 1">{{trans('shipping-method-error',191)}}</small>
                    </template>
                </app-row-left-label>

                <app-row-left-label :label="trans('cp-extra-invoice-mention',190)">
                    <app-input v-model="cArgs.invoice_mention" mask="lettersE6-40" />
                </app-row-left-label>

                <app-divider-modal text=" " :isLine="true"/>

                <app-row-left-label :label="trans('cp-invoice-organization-address', 192)">
                    <app-radio-group v-model="cArgs.invoice_organization_address" :options="invoice_organization_address_options" validatorRules="required" :validatorName="trans('cp-invoice-organization-address', 192)"/>
                </app-row-left-label>

                <app-address-nl :address.sync="cArgs.invoice_address" :required="cArgs.invoice_organization_address ? true : false" :disabled="cArgs.invoice_organization_address == 0 || cArgs.invoice_organization_address == 2 || cArgs.invoice_organization_address == null" v-if="cArgs.invoice_organization_address < 2" :key="refresh_address"/>

                <app-row-left-label :label="trans('cp-post-office-box', 192)" v-if="cArgs.invoice_organization_address == 2">
                    <app-input v-model="cArgs.post_office_box" type="integer_05" :disabled="cArgs.invoice_organization_address == 0 || cArgs.invoice_organization_address == 1 || cArgs.invoice_organization_address == null" :key="refresh_post_office_box" validatorRules="required" :validatorName="trans('cp-post-office-box', 192)"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('postbus-postcode', 192)" v-if="cArgs.invoice_organization_address == 2">
                    <app-input v-model="cArgs.post_office_box_postcode" :disabled="cArgs.invoice_organization_address == 0 || cArgs.invoice_organization_address == 1 || cArgs.invoice_organization_address == null" :key="refresh_post_office_box" mask="####AA" validatorRules="required" :validatorName="trans('postbus-postcode', 192)"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('postbus-city', 192)" v-if="cArgs.invoice_organization_address == 2">
                    <app-input v-model="cArgs.post_office_box_city" :disabled="cArgs.invoice_organization_address == 0 || cArgs.invoice_organization_address == 1 || cArgs.invoice_organization_address == null" :key="refresh_post_office_box" mask="lettersE5-25" validatorRules="required" :validatorName="trans('postbus-city', 192)"/>
                </app-row-left-label>

            </b-form>

            <b-popover target="popover-frequency-change" triggers="focus" no-fade>               
                 <template #title>
                    
                </template>
                <p class="app-popover-text">{{ trans('invoice-frequency-alert',191) }}</p>
            </b-popover>
        </ValidationObserver> 
        
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="financialsChange" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppEmail from '@core/components/AppEmail.vue';
import { correctprice } from "@validations";
import AppAddressNl from '@core/components/Address/AppAddressNl.vue';


export default {
    components: {
        ValidationObserver,
        AppEmail,
        AppAddressNl,
    },

    props: ["show", "result", "args", "return"],

    created(){
        this.user_role = JSON.parse(localStorage.getItem('user')).id_user_const_role;

        if(this.cArgs.invoice_organization_address == 0){
            this.cArgs.invoice_address = JSON.parse(JSON.stringify(this.cArgs.client_address));
        }
    },


     watch: {
        "cArgs.costplace": {
            handler: function(val) {
                  this.validateFacture(val);
            },
        },
        "cArgs.flexworker": {
            handler: function(val) {
                  this.validateFacture(val);
            },
        },
        "cArgs.week": {
            handler: function(val) {
                  this.validateFacture(val);
            },
        },
        "cArgs.invoice_shipping_pdf": {
            handler: function(val) {
                this.validateShippingMethod(val);
            },
            deep: true
        },
        "cArgs.invoice_shipping_xml": {
            handler: function(val) {
                this.validateShippingMethod(val);
            },
            deep: true
        },

        "cArgs.invoice_organization_address": {
            handler: function(val){
                if(val == '0'){
                    this.cArgs.invoice_address = JSON.parse(JSON.stringify(this.cArgs.client_address));
                    this.refresh_address++;
                    this.cArgs.post_office_box = null;
                } else {
                    this.cArgs.invoice_address.city = '';
                    this.cArgs.invoice_address.street = '';
                    this.cArgs.invoice_address.post_code = '';
                    this.cArgs.invoice_address.house_number = '';
                    this.cArgs.invoice_address.house_number_additional = '';
                    this.refresh_address++;
                    this.cArgs.post_office_box = null;
                    this.cArgs.post_office_box_postcode = null;
                    this.cArgs.post_office_box_city = null;
                    this.refresh_post_office_box++;
                }
                
            }
        },

        "cArgs.post_office_box": {
            handler: function(val){
                if(val != null && val != ''){
                    this.cArgs.invoice_address.city = '';
                    this.cArgs.invoice_address.street = '';
                    this.cArgs.invoice_address.post_code = '';
                    this.cArgs.invoice_address.house_number = '';
                    this.cArgs.invoice_address.house_number_additional = '';
                }
            }
        }
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set() { this.$emit('update:args', false) }, 
        },
    },

    data() {
        return {
            loading: false,
            parameters:{
                search:"",
                sort_by: "id",
                sort_desc: false,
                args:[],
            },
            settlement_period_options: [
                { value: 7, name: '7 ' + this.trans('cp-days',192)},
                { value: 14, name: '14 ' + this.trans('cp-days',192)},
                { value: 21, name: '21 ' + this.trans('cp-days',192)},
                { value: 30, name: '30 ' + this.trans('cp-days',192)},
                { value: 45, name: '45 ' + this.trans('cp-days',192)},
            ],
            invoice_hours_options: [
                { value: 0, name: this.trans('day-part-day',182) },
                { value: 1, name: this.trans('declaration',3) },
                { value: 2, name: this.trans('week',3) }
            ],
             invoice_organization_address_options: [
                { value: 0, text: "Organisatie" },
                { value: 1, text:  "Andere"},
                { value: 2, text: "Postbus" }
            ],
            over_btw_options: [
                { value: 0, text: 'Incl BTW' },
                { value: 1, text: 'Excl BTW' }
            ],
            facture_error:0,
            facture_error_message: 0,
            start: true,
            shipping_error: 0,
            shipping_error_message: 0,
            user_role: null,
            refresh_address: 0,
            refresh_post_office_box: 0,
        };
    },
    
    methods: {

        financialsChange() {
            
            this.$refs.form.validate().then((result) => {
                if(!this.validateFacture(1) || !this.validateShippingMethod(1)){
                    return false;
                }
                if (!result || this.loadingEmailCheck == 1) {
                    return false;
                }else{
                     this.loading = true;
                axios
                    .post("clients/profile/financials/change", {
                        id_client_data_main: this.args.id_client_data_main,
                        client_person_data: JSON.stringify(this.args),
                    })
                    .then((res) => {
                        this.$emit("update:show", false);
                        this.$emit("update:result", 1);
                        this.cReturn.client_data = res.data;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    });
                }
            })
                
        },

        validateFacture(val){
            if(val != undefined){
                if(this.cArgs.costplace == '0' && this.cArgs.flexworker == '0' && this.cArgs.week == '0'){
                     this.facture_error_message = 1;
                     return false;
                }else{
                    this.facture_error_message = 0;
                    return true;
                }
            }
        },

        validateShippingMethod(val){
            if(val != undefined){
                if(this.cArgs.invoice_shipping_pdf == '0' && this.cArgs.invoice_shipping_xml == '0'){
                    this.shipping_error_message = 1;
                    return false;
                    
                }else{
                    this.shipping_error_message = 0;
                    return true;
                }
            }
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

